<template>
  <el-dialog
          :title="show ? '产品导入预览' : '产品导入详情'"
          :close-on-click-modal="false"
          width="600px"
          :visible.sync="visible">
    <el-form :model="dataForm"  size="small" ref="dataForm" label-width="120px"
             v-loading="dataLoading" style="width:100%;margin-top:-10px;"  element-loading-text=""  element-loading-spinner="el-icon-loading"
    >
     <div style="padding:0 20px;margin-bottom:20px;">
       <div style="margin-bottom:20px;">导入模板匹配：<span style="color:#20a082">通过</span></div>
       <div style="margin-bottom:10px;display:flex;justify-content: space-between;">
         <div>即将新增产品数：<span>{{productUploadInfo.productAdd}}</span></div>
         <div style="margin-right:10%;">即将更新产品数：<span>{{productUploadInfo.productUpdate}}</span></div>
       </div>
     </div>
     <el-row>
        <el-col :span="24"><div class="grid-content borderLeft">更新产品详情</div></el-col>
      </el-row>
      <el-row>
        <el-col :span="6"><div class="grid-content borderLeft">属性填充</div></el-col>
        <el-col :span="6"><div class="grid-content">属性修改</div></el-col>
        <el-col :span="6"><div class="grid-content">属性删除</div></el-col>
        <el-col :span="6"><div class="grid-content">不匹配</div></el-col>
      </el-row>
      <el-row>
        <el-col :span="6"><div class="grid-content borderLeft borderBottom">{{productUploadInfo.attrAdd}}</div></el-col>
        <el-col :span="6"><div class="grid-content borderBottom">{{productUploadInfo.attrUpdate}}</div></el-col>
        <el-col :span="6"><div class="grid-content borderBottom">{{productUploadInfo.attrDel}}</div></el-col>
        <el-col :span="6"><div class="grid-content borderBottom">{{productUploadInfo.attrMisMatch}}</div></el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer flex" v-if="show">
      <el-button type="primary" @click="dataFormSubmit()" size="small"  :disabled="dataLoading">确认导入</el-button>
      <el-button @click="updateImport()" size="small" :disabled="dataLoading">取消更新</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import {info,update ,saveOrUpdateProductAndValue} from '@/api/modules/productImportRecord'

  export default {
    data () {
      return {
        visible: false,
        dataLoading: false,
        dataForm: {
          id: 0,
        },
        productUploadInfo:{
          productAdd:'-',
          productUpdate:'-',
          attrAdd:'-',
          attrUpdate:'-',
          attrDel:'-',
          attrMisMatch:'-',
        },
        show:'',
      }
    },
    methods: {
      init(id,show) {
        console.log(id,show)
        this.dataForm.id = id;
        this.show = show
        this.visible = true
        this.$nextTick(() => {
          if (this.dataForm.id) {
            this.dataLoading = true
            var params = {
              uploadInfoId: this.dataForm.id,
            };
            info(params).then(({data}) => {
              if (data && data.code === 0) {
                this.productUploadInfo = data.productUploadInfo ? data.productUploadInfo : this.productUploadInfo
                this.dataLoading = false
              }
            })
          }
        })
      },
      updateImport(){
        this.dataLoading = true;
        var params = {
          id:this.dataForm.id,
          uploadState:'4',
          productAdd:this.productUploadInfo.productAdd,
          productUpdate:this.productUploadInfo.productUpdate,
          attrAdd:this.productUploadInfo.attrAdd,
          attrUpdate:this.productUploadInfo.attrUpdate,
          attrDel:this.productUploadInfo.attrDel,
          attrMisMatch:this.productUploadInfo.attrMisMatch,
        }
        update(params).then(({data}) => {
          if (data && data.code === 0) {
            this.$message({
              message: '取消成功',
              type: 'success',
              duration: 1500,
              onClose: () => {
                this.visible = false;
                this.dataLoading = false;
                this.$emit('refreshDataList')
              }
            })
          } else {
            this.$message.error(data.msg)
            this.dataLoading = false;
          }
        })
      },
      // 表单提交
      dataFormSubmit () {
        this.dataLoading = true;
        var params = {
          productAdd:this.productUploadInfo.productAdd,
          productUpdate:this.productUploadInfo.productUpdate,
          attrAdd:this.productUploadInfo.attrAdd,
          attrUpdate:this.productUploadInfo.attrUpdate,
          attrDel:this.productUploadInfo.attrDel,
          attrMisMatch:this.productUploadInfo.attrMisMatch,
          id:this.dataForm.id,
        }
        console.log(params)
        saveOrUpdateProductAndValue(params).then(({data}) => {
          if (data && data.code === 0) {
            this.$message({
              message: '导入成功',
              type: 'success',
              duration: 1500,
              onClose: () => {
                this.visible = false;
                this.dataLoading = false;
                this.$emit('refreshDataList')
              }
            })
          } else {
            this.$message.error(data.msg)
            this.dataLoading = false;
          }
        })
      }
    }
  }
</script>
<style lang="scss" scoped>
.flex {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  .grid-content{
    width: 100%;
    text-align: center;
    padding: 15px 20px;
    border-right:1px solid #ebebeb;
    border-top:1px solid #ebebeb;
  }
  .borderLeft{
    border-left:1px solid #ebebeb;
  }
  .borderBottom{
    border-bottom:1px solid #ebebeb;
  }
</style>
