<template>
  <div class="app-container">
    <el-card class="box-pro">
      <div slot="header" class="clearfix">
        <span>产品筛选</span>
        <!-- <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button> -->
      </div>
      <el-row :gutter="20">
        <el-col :span="collapseState?19:24">
          <el-form :inline="true" :model="dataForm" ref="dataForm" @keyup.enter.native="restPage()">
            <el-form-item label="批次号码" prop="productOrder">
              <el-input v-model="dataForm.productOrder" placeholder="批次号码" size="small" clearable maxlength="30"></el-input>
            </el-form-item>
            <el-form-item label="产品编号" prop="productNo">
              <el-input v-model="dataForm.productNo" placeholder="产品编号" size="small" maxlength="30"></el-input>
            </el-form-item>
            <el-form-item label="产品名称" prop="productName">
              <el-input v-model="dataForm.productName" placeholder="产品名称" size="small" clearable maxlength="30"></el-input>
            </el-form-item>
            <el-form-item label="L*a*b*" class="lab">
              <el-input v-model="dataForm.lAve" placeholder="L*" class="w100 mr2" size="small" clearable maxlength="30"></el-input>
              <el-input v-model="dataForm.aAve" placeholder="a*" class="w100 mr2" size="small" clearable maxlength="30"></el-input>
              <el-input v-model="dataForm.bAve" placeholder="b*" class="w100 mr2" size="small" clearable maxlength="30"></el-input>
              <el-button size="small" type="primary" @click="syncDevice()" :loading="loading">数据同步</el-button>
            </el-form-item>
            <el-form-item label="产品品牌" v-if="brandState">
              <el-select v-model="dataForm.brandId" size="small" placeholder="请选择品牌" @change="brandOnchange()" >
                <el-option label="全部" value=""></el-option>
                <el-option v-for="item in  brandList" :key="item.id" :label="item.brandName" :value="item.id" size="small"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="产品工厂" v-if="factoryState">
              <el-select v-model="dataForm.factoryId" size="small" placeholder="请选择工厂">
                <el-option label="全部" value="-1"></el-option>
                <el-option v-for="item in factoryList" :key="item.id" :label="item.factoryName" :value="item.id" size="small"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="是否底粉">
              <el-select v-model="dataForm.isBase" size="small" placeholder="请选择状态">
                <el-option label="全部" value="-1"></el-option>
                <el-option v-for="item in testList" :key="item.value" :label="item.label" :value="item.value" size="small"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="库存">
              <el-select v-model="dataForm.hasProductNum" size="small" placeholder="请选择库存">
                <el-option label="全部" value="-1"></el-option>
                <el-option label="有" value="0"></el-option>
                <el-option label="无" value="1"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="是否看积分球">
              <el-select v-model="dataForm.integral" size="small" placeholder="是否看积分球">
                <el-option label="全部" value="0"></el-option>
                <el-option label="积分球" value="1"></el-option>
              </el-select>
            </el-form-item>
            <br v-if="brandState && factoryState"/>
            <el-form-item label="创建时间">
              <div class="block">
                <el-date-picker
                  v-model="createDates"
                  value-format="yyyy-MM-dd"
                  type="daterange"
                  range-separator="-"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期">
                </el-date-picker>
              </div>
            </el-form-item>            
            <div class="ces-main">
                <search-form
                  ref="form"
                  size='mini'
                  labelWidth = '50px'
                  :searchData = "searchData"
                  :searchForm = "searchForm"
                  :notIncluded = 'notIncluded'
                  ></search-form>
            </div>
            <template v-if="!collapseState">
              <div style="text-align: right;min-width: 205px;right: 0;bottom: 20px;">
                <el-button @click="restPage()" size="small">筛选</el-button>
                <el-button @click="reset('dataForm')" size="small">全部清空</el-button>
                <el-button @click="handleCollapse" type="text">{{collapseState?'展开':'收起'}}<i :class="[collapseState ? 'el-icon-arrow-down' : 'el-icon-arrow-up', 'el-icon--right']"></i></el-button>
              </div>
            </template>
          </el-form>
        </el-col>
        <template v-if="collapseState">
          <el-col :span="5">
            <div style="text-align: right;min-width: 205px;">
              <el-button @click="restPage()" size="small">筛选</el-button>
              <el-button @click="reset('dataForm')" size="small">全部清空</el-button>
              <el-button @click="handleCollapse" type="text">{{collapseState?'展开':'收起'}}<i :class="[collapseState ? 'el-icon-arrow-down' : 'el-icon-arrow-up', 'el-icon--right']"></i></el-button>
            </div>
          </el-col>
        </template>
      </el-row>
    </el-card>
    <el-card style="margin-top:30px">
      <div slot="header" class="clearfix">
        <span>产品列表</span>
        <div style="float: right; padding: 3px 0">
          <el-dropdown @command="handleCommand" trigger="click" v-if="isAuth('product:product:upload') || isAuth('product:product:download') || isAuth('product:product:import') || isAuth('product:producttall:downloadimport') || isAuth('product:producttall:batchimport')">
            <span class="el-dropdown-link" style="margin-left: 10px;cursor: pointer;">
              管理<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="a_1">
                <el-button v-if="isAuth('product:product:upload')" type="text" size="small" :loading="exportloading">导出产品数据
                </el-button>
              </el-dropdown-item>
              <el-dropdown-item command="a">
                <el-button v-if="isAuth('product:product:download')" type="text" size="small">库存导入
                </el-button>
              </el-dropdown-item>
              <el-dropdown-item command="b">
                <el-button v-if="isAuth('product:product:download')" type="text" size="small">下载积分球LAB导入模板
                </el-button>
              </el-dropdown-item>
              <el-dropdown-item command="c" :disabled="dataList.length < 0">
                <el-button v-if="isAuth('product:product:import')" type="text" size="small">积分球LAB导入</el-button>
              </el-dropdown-item>
              <el-dropdown-item command="e">
                <el-button v-if="isAuth('product:producttall:downloadimport')" type="text" size="small">下载导入模板
                </el-button>
              </el-dropdown-item>
              <el-dropdown-item command="d">
                <el-button v-if="isAuth('product:producttall:batchimport')" type="text" size="small">批量导入产品</el-button>
              </el-dropdown-item>
              <el-dropdown-item>
                <!-- <el-button type="text" @click="syncProduct()" :loading="cloudLoading">同步至云平台</el-button> -->
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <!-- <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button> -->
      </div>
      <el-table :data="dataList" border size="small" v-loading="dataListLoading" @selection-change="selectionChangeHandle" style="width: 100%;">
        <el-table-column prop="productOrder" header-align="center" align="center" label="批次号码">
        </el-table-column>
        <el-table-column prop="productNo" header-align="center" align="center" label="产品编号" width="120">
          <template slot-scope="scope">
            <el-popover trigger="hover" placement="top">
              <img :src="scope.row.qrUrl" min-width="100" height="100" />
              <div slot="reference">
                <span style="font-weight: 600;color: green;">{{ scope.row.productNo }}</span>
              </div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="productName" header-align="center" align="center" label="产品名称">
        </el-table-column>
        <el-table-column prop="picUrl" header-align="center" align="center" label="产品样图">
          <!-- 图片的显示 -->
          <template slot-scope="scope">
            <img v-if="scope.row.picUrl" :src="scope.row.picUrl" min-width="70" height="70" />
            <div v-else :style="{backgroundColor:(scope.row.hex || scope.row.Hex),height:'70px',minWidth:'70px'}"></div>
          </template>
        </el-table-column>
        <el-table-column prop="labAve" header-align="center" align="center" label="L*a*b*" width="200">
          <template slot-scope="scope">
            <span>25°L*:{{scope.row.l1|numFilter}}</span>
            <span>|a*:{{scope.row.a1|numFilter}}|</span>
            <span>b*:{{scope.row.b1|numFilter}}</span>
            <p></p>
            <span>45°L*:{{scope.row.l2|numFilter}}</span>
            <span>|a*:{{scope.row.a2|numFilter}}|</span>
            <span>b*:{{scope.row.b2|numFilter}}</span>
            <p></p>
            <span>75°L*:{{scope.row.l3|numFilter}}</span>
            <span>|a*:{{scope.row.a3|numFilter}}|</span>
            <span>b*:{{scope.row.b3|numFilter}}</span>
            <p></p>
            <div v-if="scope.row.L &&  scope.row.A && scope.row.B">
              <span>D8 L*:{{scope.row.L|numFilter}} </span>
              <span>|A*:{{scope.row.A|numFilter}}|</span>
              <span>B*: {{scope.row.B|numFilter}}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="glossValue" header-align="center" align="center" label="光泽值">
        </el-table-column>
        <el-table-column prop="productNum" header-align="center" align="center" label="库存">
        </el-table-column>
        <template v-for="item in tableCols">
            <el-table-column 
              :prop="item.label" 
              :label="item.label" 
              :key="item.label"
              header-align="center" align="center" >
            </el-table-column>
        </template>
        <el-table-column v-if="is_query" prop="e00Value" header-align="center" align="center" label="色差值">
        </el-table-column>
        <el-table-column prop="createDate" header-align="center" align="center" label="创建时间">
        </el-table-column>
        <el-table-column prop="updateDate" header-align="center" align="center" label="更新时间">
        </el-table-column>
        <el-table-column prop="name" header-align="center" align="center" label="创建用户">
        </el-table-column>
        <el-table-column fixed="right" header-align="center" align="center" width="150" label="操作">
          <template slot-scope="scope">
            <el-button v-if="isAuth('product:producttall:view')" type="text" size="small" @click="addOrUpdateHandle(scope.row.id)">查看</el-button>
            <!--点击配方，跳转到展示产品配方的页面，显示当前产品的配方，应该不可以做修改，只是展示 ，传递过去的值应该是产品ID,根据产品ID查询配方，调涂春明的接口和页面-->
            <el-dropdown trigger="click" v-if="isAuth('product:producttall:formula') || isAuth('product:producttall:print')">
              <span class="el-dropdown-link" style="font-size: 12px;margin-left: 10px;cursor: pointer;">
                更多<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>
                  <el-button v-if="isAuth('product:producttall:formula')" type="text" size="small" style="color: green" @click="addRecipeHandle(scope.row.id)">配方</el-button>
                </el-dropdown-item>
                <el-dropdown-item>
                  <el-button v-if="isAuth('product:producttall:print')" type="text" size="small" style="color: green" @click="printPreviewHandle(scope.row.id)">打印</el-button>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <!--<el-button v-if="isAuth('product:producttall:delete')" type="text" size="small" style="color: red" @click="deleteHandle(scope.row.id)">删除</el-button>-->
          </template>
        </el-table-column>
      </el-table>
      <el-pagination size="small" @size-change="sizeChangeHandle" @current-change="currentChangeHandle" :current-page="pageIndex" :page-sizes="[10, 20, 50, 100]" :page-size="pageSize" :total="totalPage" layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </el-card>
    <el-dialog
      title=""
      class="loading_dialog"
      :visible.sync="exportloading"
      :close-on-click-modal="false"
      :show-close="true"
      @close="closeimport"
      width="500px"
      center>
      <div>
        <div style="color:#000000;margin-bottom:30px;margin-top:-30px;width:100%;text-align:center;font-size:16px;font-font-weight:blod;">{{importTitle}}</div>
        <div style="color:#999999;margin-bottom:30px;width:100%;text-align:center;" v-if="progress != 100 && importTitle != '导出失败！' && hideProgress">关闭弹窗后导出任务将会终止！</div>
        <el-progress v-if="hideProgress" :text-inside="true" :percentage="progress" :stroke-width="20" :indeterminate="true" :color="importTitle == '导出失败！' ? 'red' : '#5A5FFF'"></el-progress>
        <div style="margin-top:30px;margin-bottom:30px;width:100%;text-align:center;color:#337ab7;" v-if="progress == 100"><span style="color:#000000;">点击链接下载：</span><a :href="exportUrl">{{exportUrl}}</a></div>
      </div>
    </el-dialog>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList2"></add-or-update>
    <add-recipe v-if="addRecipeVisible" ref="addRecipe" @refreshDataList="getDataList2"></add-recipe>
    <print-preview v-if="printPreviewVisible" ref="printPreview" @refreshDataList="getDataList2"></print-preview>
    <upload v-if="uploadVisible" ref="upload" @refreshDataList="getDataList2"></upload>
    <upload-product v-if="uploadProductVisible" ref="uploadProduct" @refreshDataList="getDataList2"></upload-product>
    <uploadDataColor v-if="uploadDataColorVisible" ref="uploadDataColor" @refreshDataList="getDataList2"></uploadDataColor>
    <device-boot v-if="deviceBootShow" ref="deviceBoot" />
    <!-- <loading v-if="exportloading" ref="loading"></loading> -->
  </div>
</template>

<script>
  import {
    syncDevice,
    list, del, getDict,list2,
    syncProduct,
    selectDevice,
    exportExcelProduct,
    getSpeedOfProgress,
  } from '@/api/modules/product'
  import {
    getAttrAndValueListInfo,getAttrTableCols
  }from '@/api/modules/productattr'
  import {
    getProductAttrValue,selectChildAttr
  } from '@/api/modules/productattrvalue'
  import AddOrUpdate from './add-or-update'
  import AddRecipe from './recipe'
  import PrintPreview from './printingPreview'
  import Upload from "./oss-upload";
  import UploadProduct from "./oss-upload-product";
  import UploadDataColor from "./oss-uploadDataColor";
  import { getToken } from "@/utils/auth";
  import SearchForm from '@/components/form.vue';
  import axios from '@/api/modules/axiosExport';
  import { getDataFactory } from '@/api/modules/factory';
  import { brandList } from '@/api/modules/brand';
  //import cesTable from '@/components/table.vue';

  export default {
    data() {
      return {
        hideProgress:true,//控制导出进度条是否展示
        gettime:'',
        progress:0,
        exportUrl:'',
        importTitle:'正在导出中...',
        websock: null,
        deviceBootShow: false,
        collapseState: true,
        cloudLoading: false,
        loading: false,
        testList: [
          {
            value: "0",
            label: "否",
          },
          { 
            value: "1", 
            label: "是" 
          }
        ],
        device: {
          deviceId: ''
        },
        deviceList: [],
        timer: null, //首先我在data函数里面进行定义定时器名称：
        timer_: null, //首先我在data函数里面进行定义定时器名称：
        timerNum: 0, // 设置定时器时间
        is_query: false,
        exportloading: false,
        dataForm: {
          productSeries: '',
          productName: '',
          id: '',
          productNo: '',
          picUrl: '',
          qrUrl: '',
          ralColorNo: '',
          gloss: '',
          glossValue: '',
          surface: '',
          colorSeries: '',
          productPerformance: '',
          otherPerformance: '',
          productCategory: '',
          productHierarchy: '',
          industryId: '',
          industryId_child: '',
          factoryId: '-1',
          brandId: '',
          description: '',
          labAve: '',
          l1: '',
          a1: '',
          b1: '',
          l2: '',
          a2: '',
          b2: '',
          l3: '',
          a3: '',
          b3: '',
          lAve: '',
          aAve: '',
          bAve: '',
          isOpen: '',
          productOrder: '',
          isBase: '-1',
          hasProductNum: '-1',
          integral: '0',
          isIntegral: '0'
        },
        createDates:[],
        colorSeriesList: [],//色系
        productCategoryList: [],//产品分类
        productHierarchyList: [],//产品主题
        productSeriesList: [],//产品系列集合
        industryList: [],
        industryList_child: [],
        dataList: [],
        productPerformanceList: [],
        productSurfaceList: [],
        productGlossList: [],
        otherPerformanceList: [],
        pageIndex: 1,
        pageSize: 10,
        totalPage: 0,
        dataListLoading: false,
        loading2: false,
        dataListSelections: [],
        addOrUpdateVisible: false,
        uploadVisible: false,
        uploadProductVisible:false,
        uploadDataColorVisible: false,
        addRecipeVisible: false,
        printPreviewVisible: false,
        searchForm:[ //这里是渲染查询表单的表头和类型的数据
          // {type:'Input',label:'姓名',prop:'name', width:'180px',placeholder:'请输入姓名...'},
          // {type:'Select',label:'性别',prop:'sex',width:'180px',options:sexs,props:sexProps,change:row=>'',placeholder:'请选择性别...'},
        ],
        notIncluded:[],
        searchData:{ //查询表单的对应的值
          // name:'',
          // sex:null,
        },
        tableCols:[],
        pagination:{
          //分页数据
          pageSize:10,
          pageNum:1,
          total:0
        },
        resultList:[],
        brandList:[],
        factoryList: [],
        brandState: false,
        factoryState: false,
      }
    },
    components: {
      AddOrUpdate,
      Upload,
      UploadDataColor,
      PrintPreview,
      AddRecipe,
      SearchForm,
      UploadProduct,
      axios,

      DeviceBoot: () => import('@/components/DeviceBoot')
    },
    created() {
      this.device.deviceId = this.$store.getters.userDevice.id;
      this.getAttrAndValueListInfo();
      this.getAttrTableCols();
      this.getBrandList();
      this.getselectDevice();
    },
    beforeDestroy() {
      window.clearInterval(this.timer)
      this.timer = null
    },
    //写个过滤器，保留小数点后两位
    filters: {
      numFilter(value) {
        let realVal = ''
        if (!isNaN(value) && value !== '' && value !== null) {
          // 截取当前数据到小数点后两位
          realVal = parseFloat(value).toFixed(2)
        } else {
          realVal = '--'
        }
        return realVal
      }
    },

    methods: {
      handleCommand(command) {
        switch (command) {
          case 'a_1':
            this.exportExcelProduct()
            break;
          case 'a':
            this.uploadHandle()
            break;
          case 'b':
            this.exportExcelHandle()
            break;
          case 'c':
            this.uploadDataColorHandle()
            break; 
          case 'd':
            this.importProduct()
            break; 
          case 'e':
            this.exportProductExcelHandle()
            break;
          default:
            break;
        }
      },
      //获取品牌列表
      getBrandList() {
        let params = {};
        brandList(params).then(({ data }) => {
          if (data && data.code === 0) {
            this.brandList = data.list;
            this.brandState = data.isSys;
            if(!data.isSys){
              this.dataForm.brandId = this.brandList[0].id
              this.getFactoryList();
            }
            this.factoryState = true;
          } else {
            this.brandList = [];
          }
        });
      },
      brandOnchange() {
        this.dataForm.factoryId = "";
        this.getFactoryList();
      },
      
    //导出excel表格模板
    exportProductExcelHandle() {
      var url = `${process.env.VUE_APP_BASE_API}/product/product/exportProductExcel` + `?token=${getToken()}`
      // 模拟创建元素实现
      var alink = document.createElement('a');
      alink.href = url;
      alink.style = 'display:none';
      alink.click();
    },
      getFactoryList() {
        if (this.dataForm.brandId != null || this.dataForm.brandId != "" || this.dataForm.brandId != "-1") {
          let params = {
            brandId: this.dataForm.brandId,
          };
          getDataFactory(params).then(({ data }) => {
            if (data && data.code === 0) {
              this.factoryList = data.factory;
              this.dataForm.factoryId = '-1';
              if(data.factory.length == 1){
                this.dataForm.factoryId = data.factory[0].id;
                if(!this.brandState){
                  this.factoryState = false;
                }
              }
            } else {
              this.factoryList = [];
            }
          });
        } else {
          this.dataForm.factoryId = '-1';
        }
      },
      handleCollapse() {
        const $dom = document.getElementsByClassName('el-card__body')[0];
        // const $domEl = document.getElementsByClassName('el')[0];
        // console.log("11")
        if (this.collapseState) {
          $dom.style.height = "auto"
          $dom.style.overflow = ""
          this.collapseState = false
          // $domEl.style.bottom = '20px'
          // $domEl.style.top = ''
        } else {
          $dom.style.height = "70px"
          $dom.style.overflow = "hidden"
          this.collapseState = true
          // $domEl.style.top = '0'
        }
      },
      getProductAttrValue() {
        let params = {};
        getProductAttrValue(params).then(({ data }) => {
          if (data && data.code === 0) {
            for (var i = 0; i < data.list.length; i++) {
              if (data.list[i].productAttrType === '1') {//表面
                this.productSurfaceList = data.list[i].productAttrValue;
              } else if (data.list[i].productAttrType === '3') {//系列
                this.productSeriesList = data.list[i].productAttrValue;
              } else if (data.list[i].productAttrType === '6') {//主题
                this.productHierarchyList = data.list[i].productAttrValue;
              } else if (data.list[i].productAttrType === '2') {//色系
                this.colorSeriesList = data.list[i].productAttrValue;
              } else if (data.list[i].productAttrType === '7') {//性能
                this.productPerformanceList = data.list[i].productAttrValue;
              } else if (data.list[i].productAttrType === '4') {//应用
                this.industryList = data.list[i].productAttrValue;
              } else if (data.list[i].productAttrType === '8') {//功能
                this.otherPerformanceList = data.list[i].productAttrValue;
              } else if (data.list[i].productAttrType === '5') {//分类
                this.productCategoryList = data.list[i].productAttrValue;
              } else if (data.list[i].productAttrType === '9') {//光泽
                this.productGlossList = data.list[i].productAttrValue;
              }

            }
          }
        })
      },
      //重新查询
      restPage() {
        this.pageIndex = 1;
        this.getAttrTableCols();
      },
      reset(dataForm) {
        this.$refs.dataForm.resetFields();
        this.dataForm.productPerformance = '';
        this.dataForm.otherPerformance = '';
        this.dataForm.productName = '';
        this.dataForm.surface = '';
        this.dataForm.gloss = '';
        this.dataForm.industryId_child = '';
        this.industryList_child = [];
        this.dataForm.lAve = '';
        this.dataForm.aAve = '';
        this.dataForm.bAve = '';
        this.dataForm.hasProductNum = '-1';
        this.dataForm.factoryId = '-1';
        this.dataForm.brandId = '';
        this.dataForm.isBase = '-1';
        this.is_query = false;
        this.createDates = [];
        if(this.searchData){
          for(var i in this.searchData){
            this.$set( this.searchData, i, {})
          }
          this.searchData = {};
        }
        this.notIncluded = []
        this.restPage();
      },
      // 获取数据列表
      getDataList() {
        this.dataListLoading = true
        this.dataList = []
        var params = {
          page: this.pageIndex,
          limit: this.pageSize,
          productCategory: this.dataForm.productCategory,
          productHierarchy: this.dataForm.productHierarchy,
          industryId: this.dataForm.industryId,
          industryChildId: this.dataForm.industryId_child,
          colorSeries: this.dataForm.colorSeries,
          productSeries: this.dataForm.productSeries,
          productName: this.dataForm.productName,
          productNo: this.dataForm.productNo,
          productPerformance: this.dataForm.productPerformance,
          otherPerformance: this.dataForm.otherPerformance,
          surface: this.dataForm.surface,
          gloss: this.dataForm.gloss,
          lAve: this.dataForm.lAve,
          aAve: this.dataForm.aAve,
          bAve: this.dataForm.bAve,
          l1: this.dataForm.l1,
          a1: this.dataForm.a1,
          b1: this.dataForm.b1,
          l2: this.dataForm.l2,
          a2: this.dataForm.a2,
          b2: this.dataForm.b2,
          l3: this.dataForm.l3,
          a3: this.dataForm.a3,
          b3: this.dataForm.b3,
          productOrder: this.dataForm.productOrder,
          createDates: this.createDates
        }
        list(params).then(({ data }) => {
          if (data && data.code === 0) {
            this.dataList = data.page.list
            this.totalPage = data.page.totalCount
          } else {
            this.dataList = []
            this.totalPage = 0
          }
          this.dataListLoading = false
        })
        if (this.dataForm.lAve && this.dataForm.aAve && this.dataForm.bAve) {
          this.is_query = true
        }
      },
      setSearchData(){
      var searchData = JSON.parse(JSON.stringify(this.searchData))
      Object.keys(searchData).forEach((key) => {
        if (Array.isArray(searchData[key])) {
            for(var i = 0; i < searchData[key].length; i++){
              if(searchData[key][i] === '0'){
                  searchData[key].splice(i,1);
              }
            }
        }else{
          if (searchData[key] === '0') {
            searchData[key] = ''
          } 
        }
      })
      return searchData
    },
      getDataList2() {
        this.loading2 = true
        this.dataListLoading = true
        this.dataList = []
		    if ((this.dataForm.lAve || this.dataForm.aAve  || this.dataForm.bAve) &&
                !(this.dataForm.lAve && this.dataForm.aAve  && this.dataForm.bAve)) {
          this.$message.error("请输完整的LAB值");
          return
        }
        var params = {
          notIncluded:this.notIncluded,
          searchData: this.setSearchData(),
          page: this.pageIndex,
          limit: this.pageSize,
          productCategory: this.dataForm.productCategory,
          productHierarchy: this.dataForm.productHierarchy,
          industryId: this.dataForm.industryId,
          industryChildId: this.dataForm.industryId_child,
          colorSeries: this.dataForm.colorSeries,
          productSeries: this.dataForm.productSeries,
          productName: this.dataForm.productName,
          productNo: this.dataForm.productNo,
          productPerformance: this.dataForm.productPerformance,
          otherPerformance: this.dataForm.otherPerformance,
          surface: this.dataForm.surface,
          gloss: this.dataForm.gloss,
          lAve: this.dataForm.lAve,
          aAve: this.dataForm.aAve,
          bAve: this.dataForm.bAve,
          l1: this.dataForm.l1,
          a1: this.dataForm.a1,
          b1: this.dataForm.b1,
          l2: this.dataForm.l2,
          a2: this.dataForm.a2,
          b2: this.dataForm.b2,
          l3: this.dataForm.l3,
          a3: this.dataForm.a3,
          b3: this.dataForm.b3,
          isBase: this.dataForm.isBase,
          hasProductNum: this.dataForm.hasProductNum,
          productOrder: this.dataForm.productOrder,
          createDates: this.createDates,
          factoryId: this.dataForm.factoryId,
          brandId: this.dataForm.brandId,
          integral: this.dataForm.integral,
        }
        list2(params).then(({ data }) => {
          if (data && data.code === 0) {
            this.dataList = data.resultList
            // this.tableData = []
            // this.tableCols = []
            this.totalPage = data.page.totalCount
            this.pagination.pageSize = data.page.pageSize
            this.pagination.total = data.page.totalCount
          } else {
            this.dataList = []
            this.totalPage = 0
          }
          this.dataListLoading = false
          this.loading2 = true
        })
        if (this.dataForm.lAve && this.dataForm.aAve && this.dataForm.bAve) {
          this.is_query = true
        }
      },

      getAttrAndValueListInfo: function () {
        this.searchData = {}
        this.searchForm = []
        this.notIncluded = []
        getAttrAndValueListInfo({isUniversal:'0'}).then(({data}) => {
          if (data && data.code === 0) {
            data.data.forEach(e => {
              var obj = {type:'',label:'', width:'130px'}
              if(e.type == "1"){
                // 如果是单选
                obj.type = "Select"
                obj.options = e.options
                obj.prop = e.type+"-"+e.productAttrId
                obj.label = e.value
                obj.notIncluded = true
              }
              if(e.type == "2"){
                obj.type = "SelectMultiple"
                obj.options = e.options
                obj.prop = e.type+"-"+e.productAttrId
                obj.value = e.value
                obj.notIncluded = true
              }
              if(e.type == "3"){
                obj.type = "Cascader"
                obj.options = e.options
                obj.prop = e.type+"-"+e.productAttrId
                obj.value = e.value
                obj.notIncluded = true
              }
              obj.label = e.label
              var obj_ = {
                children: [],
                label: "未设置",
                value: '0',
                valueProductAttrId: '0'
              }
              obj.options.push(obj_)
              this.$set(this.searchData,e.productAttrId,e.value)
              this.searchForm.push(obj);
            })
          } else {
            this.$message.error(data.msg);
            this.loading2 = false;
          }
        })
      },

     getAttrTableCols: function () {
        this.tableCols = [];
        getAttrTableCols({isUniversal:'0'}).then(({data}) => {
          if (data && data.code === 0) {
            this.tableCols =  data.data
            this.getDataList2();
          }
        })
      },

      //获取已授权设备
      getselectDevice() {
        let params = {}
        selectDevice(params).then(({ data }) => {
          if (data && data.code === 0) {
            this.deviceList = data.deviceList;
            this.device.deviceId = data.deviceList.length > 0 ? data.deviceList[0].deviceId : [];
          } else {
            this.deviceList = []
          }
        })
      },

      //设备引导
      handleDeviceBoot() {
        this.deviceBootShow = true
        this.$nextTick(() => {
          setTimeout(() => {
            this.$refs.deviceBoot.init()
          },0)
        })
      },
       
      //同步数据至云平台
      syncProduct() {
        this.cloudLoading = true;
        let params = {};
        syncProduct(params).then(({ data }) => {
          if (data && data.code === 0) {
            this.cloudLoading = false;
          } else {
            this.$message.error(data.msg);
            this.cloudLoading = false;
          }
        })
      },
      //批量导入产品
      importProduct(){
        this.uploadProductVisible = true;
        this.$nextTick(() => {
          this.$refs.uploadProduct.init();
        });
      },
      //设备同步
      syncDevice() {
        this.getselectDevice()
        this.loading = true;
        var id =  this.$store.getters.userDevice.id;
        if(null == id || '' == id){
          this.loading = false;
          this.handleDeviceBoot();
          return;
        }
        let params = {
          deviceId: this.$store.getters.userDevice.id
        }
        syncDevice(params).then(({ data }) => {
          if (data && data.code === 0) {
            this.initWebSocket();
            this.timer = setInterval(() => {
              //如果已经同步就关闭定时任务
              this.$message.warning("数据同步中，请确认设备是否有待同步数据")
              this.timerNum = this.timerNum + 1;
              if(this.timerNum == 5){
                this.clearTimer(); // 关闭定时器
                this.loading = false;
              }
            }, 5000);
          } else {
            this.$message.error(data.msg);
            this.loading = false;
          }
        })
      },
      clearTimer() {//清除定时器
        window.clearInterval(this.timer);
        this.timer = null;
        this.timerNum = 0;
      },
      //获取其他性能
      getDictList() {
        let params = {
          type: '1'
        }
        getDict(params).then(({ data }) => {
          if (data && data.code === 0) {
            this.otherPerformanceList = data.list
          } else {
            this.otherPerformanceList = []
          }
        })
      },
      handleChange(val) {
        console.log(val);
      },
      //获取产品表面选项
      getProductSurfaceList() {
        let params = {
          type: '2'
        }
        getDict(params).then(({ data }) => {
          if (data && data.code === 0) {
            this.productSurfaceList = data.list
          } else {
            this.productSurfaceList = []
          }
        })
      },
      getprogress(){
        var params_ = {
          taskNo: this.gettime
        }
        getSpeedOfProgress(params_).then(({data}) => {
          if (data && data.code === 0) {
            if (data.data.state != 0) {
              this.progress = data.data.speedOofProgress
              if (data.data.speedOofProgress == 100) {
                this.exportUrl = data.data.url.replace('http','https')
                this.importTitle = '导出成功！'
                window.clearInterval(this.timer_);
                this.timer_ = null
              }
            }else{
              this.importTitle = '导出失败！'
              window.clearInterval(this.timer_);
              this.timer_ = null
            }
          }else if (data && data.code === 10400) {
              this.importTitle = '没有可导出的产品数据！'
              window.clearInterval(this.timer_);
              this.timer_ = null
              this.hideProgress = false
          }else{
            this.importTitle = '导出失败！'
            window.clearInterval(this.timer_);
            this.timer_ = null
            this.gettime = ''
          }
        })
      },
      closeimport(){
        window.clearInterval(this.timer_);
        this.timer_ = null
        this.exportloading = false;
        this.progress = 0,
        setTimeout(() => {
          this.importTitle = '正在导出中...'
        }, 500);
      },
       //导出产品数据到excle
      exportExcelProduct() {
       this.hideProgress = true
            this.gettime = "task" + new Date().getTime();
            var params = {
              notIncluded:this.notIncluded,
              searchData: this.setSearchData(),
              productOrder: this.dataForm.productOrder,
              productNo: this.dataForm.productNo,
              productName: this.dataForm.productName,
              productCategory: this.dataForm.productCategory,
              productHierarchy: this.dataForm.productHierarchy,
              industryId: this.dataForm.industryId,
              industryChildId: this.dataForm.industryId_child,
              colorSeries: this.dataForm.colorSeries,
              productSeries: this.dataForm.productSeries,
              productPerformance: this.dataForm.productPerformance,
              otherPerformance: this.dataForm.otherPerformance,
              surface: this.dataForm.surface,
              gloss: this.dataForm.gloss,
              lAve: this.dataForm.lAve,
              aAve: this.dataForm.aAve,
              bAve: this.dataForm.bAve,
              l1: this.dataForm.l1,
              a1: this.dataForm.a1,
              b1: this.dataForm.b1,
              l2: this.dataForm.l2,
              a2: this.dataForm.a2,
              b2: this.dataForm.b2,
              l3: this.dataForm.l3,
              a3: this.dataForm.a3,
              b3: this.dataForm.b3,
              isBase: this.dataForm.isBase,
              hasProductNum: this.dataForm.hasProductNum,
              createDates: this.createDates,
              factoryId: this.dataForm.factoryId,
              brandId: this.dataForm.brandId,
              integral: this.dataForm.integral,
              taskNo: this.gettime,
              totalCount: this.totalPage
            }
            exportExcelProduct(params).then(({data}) => { // 处理返回的文件流
            // console.log(data);
            if (data && data.code === 0) {
              // console.log(111);
            this.progress = 0
            this.exportloading = true;
            this.timer_ = setInterval(this.getprogress, 3000);
            }else{
              // console.log(222);
              this.$message.warning(data.msg);
            }
            })
      },

      //导出excel表格模板
      exportExcelHandle() {
        var params = {}
        var url = `${process.env.VUE_APP_BASE_API}/product/product/exportDataColorExcel` + `?token=${getToken()}`
        // 模拟创建元素实现
        var alink = document.createElement('a');
        alink.href = url;
        alink.style = 'display:none';
        alink.click();
      },
      // 上传文件
      uploadHandle() {
        this.uploadVisible = true;
        this.$nextTick(() => {
          this.$refs.upload.init();
        });
      },
      // 上传文件
      uploadDataColorHandle() {
        this.uploadDataColorVisible = true;
        this.$nextTick(() => {
          this.$refs.uploadDataColor.init();
        });
      },
      // 每页数
      sizeChangeHandle(val) {
        this.pageSize = val
        this.pageIndex = 1
        this.getAttrTableCols()
        //this.getDataList2()
      },
      // 当前页
      currentChangeHandle(val) {
        this.pageIndex = val
        this.getAttrTableCols()
        //this.getDataList2()
      },
      // 多选
      selectionChangeHandle(val) {
        this.dataListSelections = val
      },
      // 新增 / 修改
      addOrUpdateHandle(id) {
        this.addOrUpdateVisible = true
        this.$nextTick(() => {
          this.$refs.addOrUpdate.init(id)
        })
      },
      // 打印
      printPreviewHandle(id) {
        this.printPreviewVisible = true
        this.$nextTick(() => {
          this.$refs.printPreview.init(id)
        })
      },
      //配方详情
      addRecipeHandle(id) {
        this.addRecipeVisible = true
        this.$nextTick(() => {
          this.$refs.addRecipe.init(id)
        })
      },
      selectChildAttr(){
        let params = {
          attrValueId: this.dataForm.industryId
        }
        selectChildAttr(params).then(({data}) => {
          if (data && data.code === 0) {
            this.industryList_child = data.list;
          } else {
            this.industryList_child = []
          }
          this.dataForm.industryId_child = '';
        })
      },
      // 删除
      deleteHandle(id) {
        var ids = id ? [id] : this.dataListSelections.map(item => {
          return item.id
        })
        this.$confirm(`是否删除产品?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          del(ids).then(({ data }) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
                onClose: () => {
                  this.getAttrTableCols();
                  //this.getDataList2()
                }
              })
            } else {
              this.$message.error(data.msg)
            }
          })
        })
      },

      initWebSocket(){ //初始化weosocket
        //ws地址
        var id = this.$store.getters.userDevice.id;
        var wsuri = process.env.VUE_APP_WS_API+'/websocket/search_product_' + id;
        this.websock = new WebSocket(wsuri);
        this.websock.onmessage = this.websocketonmessage;
        this.websock.onerror = this.websocketonerror
        this.websock.onopen = this.websocketonopen
        //this.websock.onclose = this.websocketclose
      },
      websocketonmessage(e){ //数据接收
        console.log(e);
        //code:0.搜索产品,1.状态变更,2.连接成功
        //msg:
        //data:
        var retData = JSON.parse(e.data);
        var code = retData.code;
        console.log(code);
        var msg = retData.msg;
        console.log(msg);
        var data = retData.data;
        console.log(data);
        if(code == 0){
          this.Lvaule = data.lAve;
          this.Avaule = data.aAve;
          this.Bvaule = data.bAve;
          this.dataForm.lAve = data.lAve;
          this.dataForm.aAve = data.aAve;
          this.dataForm.bAve = data.bAve;
          this.dataForm.l1 = data.l1;
          this.dataForm.a1 = data.a1;
          this.dataForm.b1 = data.b1;
          this.dataForm.l2 = data.l2;
          this.dataForm.a2 = data.a2;
          this.dataForm.b2 = data.b2;
          this.dataForm.l3 = data.l3;
          this.dataForm.a3 = data.a3;
          this.dataForm.b3 = data.b3;
          this.$message.success("同步成功");
          this.clearTimer(); // 关闭定时器
          this.loading = false;
          this.websock.close();
        }
      },
      websocketonopen () { // 连接建立之后执行send方法发送数据
        // let data = {
        //   code: 0,
        //   msg: '这是client：初次连接'
        // }
        // this.websocketsend(JSON.stringify(data))
      },
      websocketsend(agentData){//数据发送
        this.websock.send(agentData);
      },
      websocketonerror () {
        return this.$message.error("WebSocket连接失败");
        //console.log( 'WebSocket连接失败')
      },
      websocketclose(e){  //关闭
        this.websock.close();
        console.log("connection closed (" + e.code + ")");
      }
    }
  }
</script>
<style lang="scss" scoped>
  .app-container {
    background: #eaf1fc;
  }
  .w100 {
    width: 100px;
  }
  .mr10 {
    margin-right: 10px;
  }
  .mr2 {
    margin-right: 2px;
  }
  .ml10 {
    margin-left: 10px;
  }
  .el-date-editor--daterange.el-input__inner {
      width: 250px;
  }
  .loading_dialog{
    margin-top: 200px;
    // a, a:focus, a:hover {
    //   color: rgb(65, 106, 240);
    // }
  }
</style>
<style lang="scss">
  .box-pro {
  .el-card__body {
    position: relative;
    height: 70px;
    overflow: hidden;
  }
  .el-form-item__content {
    width: 130px;
  }
  .el-form-item.lab {
  .el-form-item__content {
    width: auto !important;
  }
  }
  .el-form-item__label {
    font-weight: 400 !important;
  }
  .el-form--inline .el-form-item {
    margin-right: 10px;
  }
  }
</style>

