<template>
  <el-dialog
          :title="!dataForm.id ? '修改邮箱' : '修改邮箱'"
          :close-on-click-modal="false"
          :visible.sync="visible">
    <el-form :model="dataForm" :rules="dataRule"  size="small" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="120px"
             v-loading="dataLoading" style="width:100%;"  element-loading-text=""  element-loading-spinner="el-icon-loading"
    >
      <el-form-item label="用户名" prop="userName">
        <el-input v-model="dataForm.userName" placeholder="登录帐号" disabled></el-input>
      </el-form-item>
      <el-form-item label="输入新邮箱" prop="email" :class="{ 'is-required': !dataForm.id }" >
        <el-input v-model="dataForm.email" type="input" placeholder="输入新邮箱"></el-input>
        <button type="button" class="code" @click="getCode" :disabled="value">
          {{ typeof text == "number" ? text + second : text }}
        </button>
      </el-form-item>
      <el-form-item label="验证码" prop="code" :class="{ 'is-required': !dataForm.id }">
        <el-input v-model="dataForm.code" type="input" placeholder="验证码"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false" size="small">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()" size="small"  :disabled="dataLoading">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import { userInfo, passwordById ,updateEmail,setSendEmail} from "@/api/sys";
  import { isEmail, isMobile } from "@/utils/validate";
  export default {
    data () {
      var validatePassword = (rule, value, callback) => {
        if (!this.dataForm.id && !/\S/.test(value)) {
          callback(new Error("密码不能为空"));
        } else {
          callback();
        }
      };

      var validateNewPassword = (rule, value, callback) => {
        if (!this.dataForm.id && !/\S/.test(value)) {
          callback(new Error("新密码不能为空"));
        } else {
          callback();
        }
      };
      var validateComfirmPassword = (rule, value, callback) => {
        if (!this.dataForm.id && !/\S/.test(value)) {
          callback(new Error("确认密码不能为空"));
        } else if (this.dataForm.newPassword !== value) {
          callback(new Error("确认密码与密码输入不一致"));
        } else {
          callback();
        }
      };
      var validateEmail = (rule, value, callback) => {
        if (!isEmail(value)) {
          callback(new Error("邮箱格式错误"));
        } else {
          callback();
        }
      };
      return {
        text: "获取验证码",
        value: false,
        second: "S后重试",
        visible: false,
        dataLoading: false,
        email :'',
        dataForm: {
          id: 0,
          userName: "",
          email: "",
          code: "",
        },
        dataRule: {
          password: [{ validator: validatePassword, trigger: "blur" }],
          newPassword: [
            { validator: validateNewPassword, trigger: "blur" }
          ],
          comfirmPassword: [
            { validator: validateComfirmPassword, trigger: "blur" }
          ],
          email: [
            { required: true, message: "邮箱不能为空", trigger: "blur" },
            { validator: validateEmail, trigger: "blur" }
          ]
        }
      }
    },
    methods: {
      //根据用户邮箱发送验证码
      getCode() {
        console.log("发送验证码")
        localStorage.setItem("email", this.dataForm.email);
        var params = {
          email : this.dataForm.email
         }
        setSendEmail(params).then(({ data }) => {
          console.log(data);
          let codeData = data;
          if (codeData.code === 0) {
            this.$message({
              showClose: true,
              message: "获取验证码成功",
              type: "success",
            });
          } else {
            this.$message.error(data.msg)
            this.dataLoading = false;
          }
        });
      },
      init(id) {
        this.dataForm.id = id;
        this.visible = true
        this.$nextTick(() => {
          this.dataForm.userName = '';
          if (this.dataForm.id) {
            var params = {
              userId: this.dataForm.id,
            };
            userInfo(params).then(({data}) => {
              if (data && data.code === 0) {
                this.dataForm.userName = data.user.userName;
                this.dataForm.email = '';
                this.dataForm.code = '';
                this.value = false;
                this.text = "获取验证码";
              }
            })
          }
        })
      },
      // 表单提交
      dataFormSubmit () {
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
            this.dataLoading = true;
            var params = {
              userId: this.dataForm.id || undefined,
              email: this.dataForm.email,
              code: this.dataForm.code,
            }
            updateEmail(params).then(({data}) => {
              if (data && data.code === 0) {
                this.$message({
                  message: '操作成功',
                  type: 'success',
                  duration: 1500,
                  onClose: () => {
                    this.visible = false;
                    this.dataLoading = false;
                    this.$emit('refreshDataList')
                  }
                })
              } else {
                this.$message.error(data.msg)
                this.dataLoading = false;
              }
            })
          }
        })
      }
    }
  }
</script>
