<template>
  <div class="app-container">
    <el-form :inline="true"  size="small" :model="dataForm" ref="dataForm"  @keyup.enter.native="restPage()">
      <el-form-item prop="colorCompreName">
        <el-input v-model="dataForm.colorCompreName" placeholder="比色名称" size="small" clearable></el-input>
      </el-form-item>
      <el-form-item prop="daterange">
        <el-date-picker type="daterange" v-model="dataForm.daterange" range-separator="~" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
          :editable="false" start-placeholder="创建时间从" end-placeholder="创建时间到"></el-date-picker>
      </el-form-item>
      <el-form-item prop="name">
        <el-input v-model="dataForm.name" placeholder="操作员" size="small" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-button @click="restPage()" size="small" type="primary">筛选</el-button>
        <el-button @click="reset('dataForm')"  size="small" type="primary">重置</el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="dataList"
      border
      size="small"
      v-loading="dataListLoading"
      style="width: 100%;">
      <el-table-column
        prop="colorCompreName"
        header-align="center"
        align="center"
        label="比色名称">
      </el-table-column>
      <el-table-column
        prop="itemLab"
        header-align="center"
        align="center"
        width="210"
        label="标样">
        <template slot-scope="scope">
          <div v-for="(item,index) in setsplit(scope.row.itemLab)" :key="index">{{item}}</div>
        </template>
      </el-table-column>
      <el-table-column
        prop="itemOneLab"
        header-align="center"
        align="center"
        width="210"
        label="试样一">
        <template slot-scope="scope">
          <div v-for="(item,index) in setsplit(scope.row.itemOneLab)" :key="index">{{item}}</div>
        </template>
      </el-table-column>
      <el-table-column
        prop="itemOneDee"
        header-align="center"
        align="center"
        :render-header="renderHeader"
        :label="'试样一综合色差'">
      </el-table-column>
      <el-table-column
        prop="itemTwoLab"
        header-align="center"
        width="210"
        align="center"
        label="试样二">
        <template slot-scope="scope">
          <div v-for="(item,index) in setsplit(scope.row.itemTwoLab)" :key="index">{{item}}</div>
        </template>
      </el-table-column>
      <el-table-column
        prop="itemTwoDee"
        header-align="center"
        align="center"
        :render-header="renderHeader_"
        label="试样二综合色差">
      </el-table-column>
      <el-table-column
        prop="createBy"
        header-align="center"
        align="center"
        label="操作员">
      </el-table-column>
      <el-table-column
        prop="createDate"
        header-align="center"
        width="90"
        align="center"
        label="创建时间">
      </el-table-column>
      <el-table-column
        fixed="right"
        header-align="center"
        align="center"
        width="150"
        label="操作">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="addOrUpdateHandle(scope.row.id)">详情</el-button>
          <el-button type="text" size="small" @click="deleteHandle(scope.row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      size="small"
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper">
    </el-pagination>
  </div>
</template>

<script>
  import {list,del} from '@/api/modules/colorcomprehistory'
  export default {
    data () {
      return {
        dataForm: {
          colorCompreName: '',
          daterange:[],
          name:'',
        },
        dataList: [],
        pageIndex: 1,
        pageSize: 10,
        totalPage: 0,
        dataListLoading: false,
        dataListSelections: [],
        addOrUpdateVisible: false
      }
    },
    activated () {
      this.getDataList()
    },
    methods: {
      renderHeader (h,{column}) { // h即为cerateElement的简写，具体可看vue官方文档
        return (
          <div>
            <span>试样一</span><br/><span>综合色差</span>
          </div>
        );
      },
      renderHeader_ (h,{column}) { // h即为cerateElement的简写，具体可看vue官方文档
        return (
          <div>
            <span>试样二</span><br/><span>综合色差</span>
          </div>
        );
      },
      //重新查询
      restPage() {
        this.pageIndex = 1;
        this.getDataList();
      },
      reset(dataForm) {
       	this.$refs.dataForm.resetFields();
        this.restPage();
      },
      // 获取数据列表
      getDataList () {
        this.dataListLoading = true
        var endDate = ''
        var startDate = ''
        if (this.dataForm.daterange.length == 2) {
          startDate = this.dataForm.daterange[0] + ' 00:00:00'
          endDate = this.dataForm.daterange[1] + ' 23:59:59'
        }
        var params = {
          page: this.pageIndex,
          limit: this.pageSize,
          colorCompreName: this.dataForm.colorCompreName,
          name: this.dataForm.name,
          startDate:startDate,
          endDate:endDate,
        }
        list(params).then(({data}) => {
          if (data && data.code === 0) {
            this.dataList = data.data.records
            this.totalPage = data.data.total
          } else {
            this.dataList = []
            this.totalPage = 0
          }
          this.dataListLoading = false
        })
      },
      // 每页数
      sizeChangeHandle (val) {
        this.pageSize = val
        this.pageIndex = 1
        this.getDataList()
      },
      // 当前页
      currentChangeHandle (val) {
        this.pageIndex = val
        this.getDataList()
      },
      // 详情
      addOrUpdateHandle (id) {
        this.$router.push({path:'/colorcompre',query:{'id': id}});
      },
      setsplit(val){
        if (val) {
          let arr = val.split(';')
          return arr
        }else{
          return ''
        }
      },
      // 删除
      deleteHandle (id) {
        var params = {
          id:id
        }
        this.$confirm(`确定删除该条记录?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          del(params).then(({data}) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
                onClose: () => {
                  this.getDataList()
                }
              })
            } else {
              this.$message.error(data.msg)
            }
          })
        })
      }
    }
  }
</script>
