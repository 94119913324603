<template>
  <div class="app-container">
    <el-form :inline="true"  size="small" :model="dataForm" ref="dataForm"  @keyup.enter.native="restPage()">
      <el-form-item prop="username">
        <el-input v-model="dataForm.username" placeholder="人员名称" size="small" clearable></el-input>
      </el-form-item>
      <el-form-item prop="remarks">
        <el-input v-model="dataForm.remarks" placeholder="搜索备注" size="small" clearable></el-input>
      </el-form-item>
      <el-form-item prop="operation">
        <el-select v-model="dataForm.operation" placeholder="匹配结果">
          <el-option label="全部" value=""></el-option>
          <el-option
                  v-for="item in stateArry"
                  :key="item.stateKey"
                  :label="item.stateValue"
                  :value="item.stateKey"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="deNum">
        <el-input v-model="dataForm.deNum" placeholder="DE<1的数量" size="small" clearable></el-input>
      </el-form-item>
      <el-form-item prop="daterange">
        <div class="block">
          <el-date-picker
                  v-model="dataForm.searchDate"
                  value-format="yyyy-MM-dd"
                  type="daterange"
                  range-separator="-"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期">
          </el-date-picker>
        </div>
      </el-form-item>
      <el-form-item>
        <el-button @click="restPage()" size="small">查询</el-button>
          <el-button  type="primary" @click="exportExcel()"
                      size="small">导出数据
          </el-button>
        <el-button @click="reset('dataForm')"  size="small">清空</el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="dataList"
      border
      size="small"
      v-loading="dataListLoading"
      @selection-change="selectionChangeHandle"
      :row-class-name="tableRowClassName"
      style="width: 100%;">
      <el-table-column
        prop="username"
        header-align="center"
        align="center"
        label="人员名称">
      </el-table-column>
      <el-table-column
        prop="remarks"
        header-align="center"
        align="center"
        label="搜索备注">
      </el-table-column>
      <el-table-column
              width="280"
              prop="searchProductNo1"
              header-align="center"
              align="center"
              :render-header="renderHeader"
              label="搜索结果">
        <template slot-scope="scope">
          <el-popover
                  placement="top-start"
                  width="200"
                  trigger="hover"
                  :content="scope.row.searchProductNo1">
          </el-popover>
          <div v-for="(item,index) in scope.row.searchProductNo00" :key="index" v-show="index < 5 && parseFloat(item.substring(item.indexOf(':')+1,item.indexOf(':')+5)) <= 2">
            <span>{{item.replace(/:/g,'，')}}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column
              prop="deNum4"
              header-align="center"
              align="center"
              label="结果数量">
        <template slot-scope="scope">
            <div>{{scope.row.deNum1}}（ΔE*<0.5）</div>
            <div>{{scope.row.deNum2}}（0.5<ΔE*<=1）</div>
            <div>{{scope.row.deNum3}}（1<ΔE*<=2）</div>
        </template>      
      </el-table-column>
      <el-table-column
              prop="searchType"
              header-align="center"
              align="center"
              width="100"
              label="查色方式">
          <template slot-scope="scope">
            <span v-if="scope.row.searchType=='0'">仪器查色</span>
            <span v-if="scope.row.searchType=='1'">色号查色</span>
            <span v-if="scope.row.searchType=='2'">色板查色</span>
            <span v-if="scope.row.searchType=='3'">精准查色</span>
          </template> 
      </el-table-column>
      <el-table-column
        header-align="center"
        align="center"
        label="搜索条件">
          <template slot-scope="scope">
            <span>{{scope.row.brandName ? scope.row.brandName+"，\n" : ""}}</span>
            <span>{{scope.row.factoryName ? scope.row.factoryName+"，\n" : ""}}</span>
            <span>{{scope.row.searchparams ? scope.row.searchparams +"，\n" : ""}}</span>
            <span v-if="scope.row.state=='0'">过程样板</span>
            <span v-if="scope.row.state=='1'">实验产品</span>
            <span v-if="scope.row.state=='2'">生产产品</span>
          </template>
      </el-table-column>
      <el-table-column
              prop="operation"
              header-align="center"
              align="center"
              width="100"
              label="是否匹配">
        <template slot-scope="scope">
          <span v-if="scope.row.operation=='1'">未处理</span>
          <span v-if="scope.row.operation=='2'">完全匹配</span>
          <span v-if="scope.row.operation=='3'">不匹配</span>
          <span v-if="scope.row.operation=='4'">配色可参考</span>
        </template>
      </el-table-column>
      <el-table-column
              prop="searchDate"
              header-align="center"
              width="90"
              align="center"
              label="搜索日期">
      </el-table-column>
      <el-table-column
        fixed="right"
        header-align="center"
        align="center"
        width="150"
        label="操作">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="addOrUpdateHandle(scope.row.id)">匹配处理</el-button>
          <el-button type="text" size="small" @click="deleteHandle(scope.row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      size="small"
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper">
    </el-pagination>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
  </div>
</template>

<script>
  import {list,del} from '@/api/modules/searchhistoryList'
  import AddOrUpdate from './add-or-update'
  import {getToken} from "@/utils/auth";
  export default {
    data () {
      return {
        dataForm: {
            username: '',
            deNum:'',
            operation:'',
            remarks:'',
            searchDate:[],
            daterange:[]
        },
          stateArry:[
              {stateKey:'1', stateValue:'未处理'},
              {stateKey:'2', stateValue:'完全匹配'},
              {stateKey:'3', stateValue:'不匹配'},
              {stateKey:'4', stateValue:'配色可参考'},
          ],
        dataList: [],
        pageIndex: 1,
        pageSize: 10,
        totalPage: 0,
        dataListLoading: false,
        dataListSelections: [],
        addOrUpdateVisible: false
      }
    },
    components: {
      AddOrUpdate
    },
    created () {
      this.getDataList()
    },
      //写个过滤器，保留小数点后两位
      filters: {
          numFilter (value) {
              let realVal = ''
              if (!isNaN(value) && value!== '') {
                  // 截取当前数据到小数点后两位
                  realVal = parseFloat(value).toFixed(2)
              } else {
                  realVal = '--'
              }
              return realVal
          }
      },
  watch: {
    $route() {
      if (this.$route.name == 'searchhistoryList') {
        this.restPage()
      }
    }
  },
    methods: {
        //导出数据
        exportExcel(){
            var arrOrderTime = this.dataForm.searchDate;
            var startDate = null;
            var endDate = null;
            if (arrOrderTime.length > 0) {
                startDate = arrOrderTime[0];
                endDate = arrOrderTime[1];
            }
            var params = {
                username: this.dataForm.username,
                deNum:this.dataForm.deNum,
                operation:this.dataForm.operation,
                'startDate': startDate,
                'endDate': endDate
            }
            var url =`${process.env.VUE_APP_BASE_API}/searchHistory/searchhistory/exportExcel?username=`+params.username+'&deNum='+params.deNum+'&operation='+params.operation+
                '&startDate='+params.startDate+'&endDate='+params.endDate+`&token=${getToken()}`
            // 模拟创建元素实现
            var alink = document.createElement('a');
            alink.href = url;
            alink.style = 'display:none';
            alink.click();
        },
        renderHeader (h,{column}) { // h即为cerateElement的简写，具体可看vue官方文档
          return (
            <div>
              <span style="cursor:pointer" title='搜索结果的格式为 “产品号 , 色差值 , 光泽值”'>搜索结果<i class="el-icon-question" style="margin-left:5px;"></i></span>
            </div>
          );
        },
        //状态不匹配，且数据不为空，就显示红色
        tableRowClassName({row}) {
            if (row.operation == '3'&&row.deNum1>0) {
                return 'warning-row';
            }
            if (row.operation == '3'&&row.deNum2>0) {
                return 'warning-row';
            }
            return '';
        },

      //重新查询
      restPage() {
        this.pageIndex = 1;
        this.getDataList();
      },
      reset(dataForm) {
          this.dataForm.searchDate = [];
       	this.$refs.dataForm.resetFields();
        this.restPage();
      },
      // 获取数据列表
      getDataList () {
        this.dataListLoading = true
          var arrOrderTime = this.dataForm.searchDate;
          var strOrderTime = "";
          var startDate = null;
          var endDate = null;
          if (arrOrderTime.length > 0) {
              startDate = arrOrderTime[0];
              endDate = arrOrderTime[1];
          }
        var params = {
          page: this.pageIndex,
          limit: this.pageSize,
            username: this.dataForm.username,
            remarks: this.dataForm.remarks,
            deNum:this.dataForm.deNum,
            operation:this.dataForm.operation,
            'startDate': startDate,
            'endDate': endDate
        }
        list(params).then(({data}) => {
          if (data && data.code === 0) {
            this.dataList = data.page.list;
            if(this.dataList !=null && this.dataList.length>0){
                for(var i=0;i<this.dataList.length;i++){
                    var list = []
                    if(this.dataList[i].searchProductNo1 !=undefined && this.dataList[i].searchProductNo1!=''){
                        var s1=(this.dataList[i].searchProductNo1).split(',');
                        this.dataList[i].searchProductNo11=s1;
                        list = list.concat(this.dataList[i].searchProductNo11)
                    }

                    if(this.dataList[i].searchProductNo2 !=undefined && this.dataList[i].searchProductNo2!=''){
                        var s1=(this.dataList[i].searchProductNo2).split(',');
                        this.dataList[i].searchProductNo22=s1;
                        list = list.concat(this.dataList[i].searchProductNo22)
                    }

                    if(this.dataList[i].searchProductNo3 !=undefined && this.dataList[i].searchProductNo3!=''){
                        var s1=(this.dataList[i].searchProductNo3).split(',');
                        this.dataList[i].searchProductNo33=s1;
                        list = list.concat(this.dataList[i].searchProductNo33)
                    }

                    if(this.dataList[i].searchProductNo4 !=undefined && this.dataList[i].searchProductNo4!=''){
                        var s1=(this.dataList[i].searchProductNo4).split(',');
                        this.dataList[i].searchProductNo44=s1;
                        list = list.concat(this.dataList[i].searchProductNo44)
                    }
                    console.log(list)
                    this.dataList[i].searchProductNo00=list
                }
                // console.log(this.dataList)
                // var item = '08640024(base):0.358:90'
                // console.log(parseFloat(item.substring(item.indexOf('.'),item.length)))
            }
            this.totalPage = data.page.totalCount
          } else {
            this.dataList = []
            this.totalPage = 0
          }
          this.dataListLoading = false
        })
      },
      // 每页数
      sizeChangeHandle (val) {
        this.pageSize = val
        this.pageIndex = 1
        this.getDataList()
      },
      // 当前页
      currentChangeHandle (val) {
        this.pageIndex = val
        this.getDataList()
      },
      // 多选
      selectionChangeHandle (val) {
        this.dataListSelections = val
      },
      // 新增 / 修改
      addOrUpdateHandle (id) {
        this.addOrUpdateVisible = true
        this.$nextTick(() => {
          this.$refs.addOrUpdate.init(id)
        })
      },
      // 删除
      deleteHandle (id) {
        var ids = id ? [id] : this.dataListSelections.map(item => {
          return item.id
        })
        this.$confirm(`确定删除该条历史记录?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          del(ids).then(({data}) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
                onClose: () => {
                  this.getDataList()
                }
              })
            } else {
              this.$message.error(data.msg)
            }
          })
        })
      }
    }
  }
</script>
<style>
  .el-table .warning-row {
    background: oldlace;
  }

  .el-table .success-row {
    background: #f0f9eb;
  }
</style>
