<template>
  <div class="app-container">
    <el-form
      :inline="true"
      size="small"
      :model="dataForm"
      ref="dataForm"
      @keyup.enter.native="restPage()"
    >
      <el-form-item prop="brandName">
        <el-input
          v-model="dataForm.brandName"
          placeholder="品牌名称"
          size="small"
          clearable
          maxlength="10"
        ></el-input>
      </el-form-item>
      <el-form-item prop="brandEname">
        <el-input
          v-model="dataForm.brandEname"
          placeholder="品牌英文名"
          size="small"
          clearable
          maxlength="10"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button @click="restPage()" size="small">查询</el-button>
        <el-button @click="reset('dataForm')" size="small">清空</el-button>
        <!-- <el-button v-if= "isAuth('factory:brand:save')" type="primary" @click="addOrUpdateHandle()" size="small">添加品牌</el-button> -->
      </el-form-item>
    </el-form>
    <el-table
      :data="dataList"
      border
      size="small"
      v-loading="dataListLoading"
      @selection-change="selectionChangeHandle"
      style="width: 100%"
    >
      <el-table-column
        prop="brandName"
        header-align="center"
        align="center"
        label="品牌名称"
      >
      </el-table-column>
      <el-table-column
        prop="brandEname"
        header-align="center"
        align="center"
        label="品牌英文名"
      >
      </el-table-column>
      <el-table-column
        prop="brandLogo"
        header-align="center"
        align="center"
        label="品牌LOGO"
      >
        <!-- 图片的显示 -->
        <template slot-scope="scope">
          <img :src="scope.row.brandLogo" min-width="70" height="70" />
        </template>
      </el-table-column>
      <el-table-column
        prop="userName"
        header-align="center"
        align="center"
        label="登陆账号"
      >
      </el-table-column>
      <el-table-column
        prop="orderTime"
        header-align="center"
        align="center"
        label="账号有效期"
      >
      </el-table-column>
      <el-table-column
        prop="state"
        header-align="center"
        align="center"
        label="状态"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.state == '0'">正常</span>
          <span v-if="scope.row.state == '1'">冻结</span>
          <span v-if="scope.row.state == '2'">过期</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="isEnable"
        header-align="center"
        align="center"
        label="小程序状态"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.isEnable == '1'">启用</span>
          <span v-if="scope.row.isEnable == '0'">停用</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="createDate"
        header-align="center"
        align="center"
        label="创建时间"
      >
      </el-table-column>
      <el-table-column
        fixed="right"
        header-align="center"
        align="center"
        width="150"
        label="操作"
      >
        <template slot-scope="scope">
          <!-- <el-button v-if= "isAuth('factory:brand:update')" type="text" size="small" @click="addOrUpdateHandle(scope.row.id)">编辑</el-button> -->
          <el-button
            v-if="isAuth('sys:user:update')"
            type="text"
            size="small"
            @click="updatepasswordHandle(scope.row.userId)"
            >修改密码</el-button
          >
          <el-button
            v-if="isAuth('sys:user:update')"
            type="text"
            size="small"
            @click="updatepemailHandle(scope.row.userId)"
            >修改邮箱</el-button
          >
          <el-button
            v-if="isAuth('factory:brand:applets')"
            type="text"
            size="small"
            @click="setBrand(scope.row)"
            >{{
              scope.row.isEnable == "1" ? "停用小程序" : "启用小程序"
            }}</el-button
          >
          <el-button
            type="text"
            size="small"
            @click="setBrandSampleHandle(scope.row.id)"
            >配置打样流程</el-button
          >
          <el-button
            type="text"
            size="small"
            @click="privatisationHandle(scope.row.id)"
            >私有化配置</el-button
          >
          <!-- <el-button v-if= "isAuth('factory:brand:delete')" type="text" size="small" style="color: red" @click="deleteHandle(scope.row.id)">删除</el-button> -->
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      size="small"
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper"
    >
    </el-pagination>
    <!-- 弹窗, 新增 / 修改 -->
    <update-password
      v-if="updatePasswordVisible"
      ref="updatePassword"
      @refreshDataList="getDataList"
    ></update-password>
    <update-email
      v-if="updateEmailVisible"
      ref="updateEmail"
      @refreshDataList="getDataList"
    ></update-email>
    <add-or-update
      v-if="addOrUpdateVisible"
      ref="addOrUpdate"
      @refreshDataList="getDataList"
    ></add-or-update>
    <set-brand-sample
      v-if="setBrandSampleVisible"
      ref="setBrandSample"
      @refreshDataList="getDataList"
    ></set-brand-sample>
    <privatisation
      ref="privatisation"
      v-if="privatisationVisible"
    ></privatisation>
  </div>
</template>

<script>
import { list, del, update } from "@/api/modules/brand";
import AddOrUpdate from "./add-or-update";
import UpdatePassword from "./updatepassword";
import UpdateEmail from "./updateEmail";
import Privatisation from "./privatisation";
import SetBrandSample from "./setBrandSample";

export default {
  data() {
    return {
      dataForm: {
        brandName: "",
        brandEname: "",
      },
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      dataListLoading: false,
      dataListSelections: [],
      setBrandSampleVisible: false,
      updatePasswordVisible: false,
      updateEmailVisible: false,
      addOrUpdateVisible: false,
      privatisationVisible: false,
    };
  },
  components: {
    AddOrUpdate,
    UpdatePassword,
    UpdateEmail,
    SetBrandSample,
    Privatisation,
  },
  created() {
    this.getDataList();
  },
  methods: {
    //重新查询
    restPage() {
      this.pageIndex = 1;
      this.getDataList();
    },
    reset(dataForm) {
      this.$refs.dataForm.resetFields();
      this.restPage();
    },
    // 根据条件获取数据列表
    getDataList() {
      this.dataListLoading = true;
      var params = {
        page: this.pageIndex,
        limit: this.pageSize,
        brandName: this.dataForm.brandName,
        brandEname: this.dataForm.brandEname,
      };
      list(params).then(({ data }) => {
        if (data && data.code === 0) {
          this.dataList = data.page.list;
          this.totalPage = data.page.totalCount;
        } else {
          this.dataList = [];
          this.totalPage = 0;
        }
        this.dataListLoading = false;
      });
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageIndex = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val;
      this.getDataList();
    },
    // 多选
    selectionChangeHandle(val) {
      this.dataListSelections = val;
    },
    // 新增 / 修改
    addOrUpdateHandle(id) {
      this.addOrUpdateVisible = true;
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(id);
      });
    },
    // 新增 / 修改
    setBrandSampleHandle(id) {
      this.setBrandSampleVisible = true;
      this.$nextTick(() => {
        this.$refs.setBrandSample.init(id);
      });
    },

    //修改密码
    updatepasswordHandle(id) {
      this.updatePasswordVisible = true;
      if (id == null) {
        this.$message.error("账号需要配置，请联系管理员");
        return;
      }
      this.$nextTick(() => {
        this.$refs.updatePassword.init(id);
      });
    },
    //设置品牌是否在小程序中展示
    setBrand(brandData) {
      this.$confirm(
        `是否` + `${brandData.isEnable !== "0" ? "停用" : "启用"}` + `小程序?`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(() => {
        var params = {
          isEnable: brandData.isEnable == "0" ? "1" : "0",
          id: brandData.id,
        };
        update(params).then(({ data }) => {
          if (data && data.code === 0) {
            this.$message({
              message: "操作成功",
              type: "success",
              duration: 1500,
              onClose: () => {
                this.getDataList();
              },
            });
          } else {
            this.$message.error(data.msg);
          }
        });
      });
    },
    //修改邮箱
    updatepemailHandle(id) {
      this.updateEmailVisible = true;
      if (id == null) {
        this.$message.error("账号需要配置，请联系管理员");
        return;
      }
      this.$nextTick(() => {
        this.$refs.updateEmail.init(id);
      });
    },
    // 删除
    deleteHandle(id) {
      // var ids = id ? [id] : this.dataListSelections.map(item => {
      //   return item.id
      // })
      this.$confirm(`是否确认删除品牌?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        del({ id: id }).then(({ data }) => {
          if (data && data.code === 0) {
            this.$message({
              message: "操作成功",
              type: "success",
              duration: 1500,
              onClose: () => {
                this.getDataList();
              },
            });
          } else {
            this.$message.error(data.msg);
          }
        });
      });
    },
    // 私有化配置
    privatisationHandle(id) {
      this.privatisationVisible = true;
      this.$nextTick(() => {
        this.$refs.privatisation.init(id);
      });
    },
  },
};
</script>
