import { render, staticRenderFns } from "./recipe.vue?vue&type=template&id=4430baca&scoped=true&"
import script from "./recipe.vue?vue&type=script&lang=js&"
export * from "./recipe.vue?vue&type=script&lang=js&"
import style0 from "./recipe.vue?vue&type=style&index=0&id=4430baca&prod&lang=scss&"
import style1 from "./recipe.vue?vue&type=style&index=1&id=4430baca&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.10.0@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4430baca",
  null
  
)

export default component.exports