<template xmlns="http://www.w3.org/1999/html">
  <el-dialog
    class="proDialog"
    title="产品详情"
    :close-on-click-modal="false"
    :visible.sync="visible"
    width="800px"
    @click="close()"
    top="5vh"
  >
    <div class="details">
      <div class="d_top">
        <img v-if="list.picUrl" :src="list.picUrl" />
        <div v-else-if="!list.picUrl && list.hex" :style="{backgroundColor:(list.hex || list.Hex),height:'146px',minWidth:'100%'}"></div>
        <p>{{list.brandName==null?'TIGER':list.brandName}}</p>
      </div>
      <div class="d_bottom">
        <div class="d_b_top">
          <div class="d_b_t_row">
            <div class="d_b_t_col">
              <p class="name">产品号</p>
              <p class="message">{{list.productNo}}</p>
            </div>
            <div class="d_b_t_col">
              <p class="name">标准色号</p>
              <p class="message">{{list.ralColorNo==null?'暂无':list.ralColorNo}}</p>
            </div>
          </div>
          <div class="d_b_t_row">
            <div class="d_b_t_col">
              <p class="name">品牌</p>
              <p class="message">{{list.brandName==null?'暂无':list.brandName}}</p>
            </div>
          </div>
        </div>
        <div class="d_b_bot">
          <div class="d_b_t_content">
            <div class="d_b_t_c_left">
              <div class="d_b_t_col">
                <p class="name">生产工厂</p>
                <p
                        class="message"
                >{{list.factoryName==null?'暂无':list.factoryName}}{{list.state=='2'?'':'实验室'}}</p>
              </div>
              <!-- <div class="d_b_t_col">
                <p class="name">分类</p>
                <p
                  class="message"
                >{{list.productCategoryLabel==null?'暂无':list.productCategoryLabel}}</p>
              </div> -->
              <div class="d_b_t_col">
                <p class="name">LAB值</p>
                <p class="message">L：{{list.lAve==null?'暂无':list.lAve}}</p>
                <p class="message">A：{{list.aAve==null?'暂无':list.aAve}}</p>
                <p class="message">B：{{list.bAve==null?'暂无':list.bAve}}</p>
              </div>
              <div class="d_b_t_col">
                <p class="name">光泽值</p>
                <p class="message">{{list.glossValue==null?'暂无':list.glossValue}}</p>
              </div>
              <div class="d_b_t_col">
                <p class="name">45°色差值</p>
                <p class="message">{{list.e00lab2=="-1"?'0':list.e00lab2}}</p>
              </div>
              <div class="d_b_t_col">
                <p class="name">色差值</p>
                <p class="message">{{list.e00Value=="-1"?'0':list.e00Value}}</p>
              </div>
            </div>
            <div>
              <div class="d_b_t_col">
                <div id="app">
                  <div v-for="(value,key) in list.resultMap">
                    <p class="name">{{ key}}</p>
                    <p class="message">{{ value}}</p>
                  </div>
                </div>
              </div>
              <div class="d_b_t_col">
                <p class="name">产品状态</p>
                <!-- <p class="message">{{list.test=='0'?'过程样板':(list.test=='1' ?  "实验产品" :'正式产品')}}< -->
                <p class="message">{{list.state=='0'?'过程样板':(list.state=='2' ? "生产产品" :'实验产品')}}</p>
              </div>
            </div>
            <img class="d_b_t_c_right" :src="list.qrUrl" />
          </div>
          <!-- <button class="btn" bindtap="saveImg">保存图片</button> -->
        </div>
        <div class="d_b_bot">
          
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import { searchProductInfo } from "@/api/modules/product";
export default {
  data() {
    return {
      id: "",
      visible: false,
      list: {},
    };
  },
  methods: {
    init(id, e00Value, e00lab2,brandId) {
      /*console.log("9999999"+e00lab2)*/
      this.visible = true;
      this.id = id;
      this.list = {};
      var params = {
        id: this.id ,
        brandId: brandId
      }
      searchProductInfo(params).then(({ data }) => {
        if (data && data.code === 0) {
          this.list = data.productInfoVo;
          this.list.e00Value = e00Value;
          this.list.e00lab2 = e00lab2;
        }
      });
    },
    //打样
    SampleHandle() {
      this.sampleVisible = true;
      this.$nextTick(() => {
        this.$refs.sample.init(this.dataForm.recipeId);
      });
    },
    close() {
      this.list = {};
      this.visible = false;
    },
  },
  beforeDestroy() {
    this.list = {};
  },
};
</script>
<style  lang="scss" scoped>
.details {
  padding: 0 15px 0;
}

.d_top {
  position: relative;
}

.d_top img {
  width: 100%;
  height: 146px;
}

.d_top p {
  position: absolute;
  bottom: 5px;
  right: 12px;
  font-size: 24px;
  color: rgba(255, 255, 255, 0.09);
  font-weight: blod;
}

.d_bottom {
  padding: 0 43px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  position: relative;
}

.d_bottom::before {
  content: "";
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: block;
  /* background: rgb(60, 53, 53);*/
  position: absolute;
  top: 137.5px;
  left: -10px;
  transform: translateY(-50%);
  box-shadow: -10px -1px 7.5px -6.5px rgba(0, 0, 0, 0.16) inset;
}

.d_bottom::after {
  content: "";
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: block;
  background: #fff;
  position: absolute;
  top: 137.5px;
  right: -10px;
  transform: translateY(-50%);
  box-shadow: 10px -1px 7.5px -6.5px rgba(0, 0, 0, 0.16) inset;
}

.d_b_top {
  padding: 13px 0 0;
  border-bottom: 1px #ccc dashed;
  margin-bottom: 10px;
  // height: 240px;
}

.d_b_t_row {
  display: flex;
  /* justify-content: space-between; */
}

.d_b_t_col {
  width: 100%;
  margin-bottom: 20px;
  #app {
    background: none;
  }
}

.d_b_t_col p {
  display: block;
}

.name {
  font-size: 15px;
  color: #ababab;
  /*margin-bottom: 8px;*/
  margin: 5px 0;
}

.message {
  font-size: 18px;
  color: #333;
  font-weight: bold;
  margin: 5px 0;
}

.d_b_bot {
  padding-bottom: 32px;
}

.d_b_t_content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.d_b_t_c_left {
  width: 35%;
}

.d_b_t_c_right {
  width: 88px;
  height: 88px;
}

.btn {
  background: linear-gradient(
    315deg,
    rgba(126, 0, 255, 1) 0%,
    rgba(0, 120, 255, 1) 100%
  );
  color: #ffffff;
  font-size: 16px;
  border-radius: 50px;
  padding: 8px 0;
  width: 168px;
  margin-top: 32px;
}
</style>
