<template>
    <div class="app-container">
        <el-form :inline="true" size="small" :model="dataForm" ref="dataForm" @keyup.enter.native="restPage()">
            <el-form-item v-if="role=='0'" prop="brandId" label="所属品牌">
                <el-select v-model="dataForm.brandId" size="small" placeholder="请选择品牌">
                    <el-option label="全部" value=""></el-option>
                    <el-option
                            v-for="item in  brandList"
                            :key="item.id"
                            :label="item.brandName"
                            :value="item.id"
                            size="small">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item prop="attrName" label="属性名称">
                <el-input v-model="dataForm.attrName" placeholder="属性名称"  size="small" clearable></el-input>
            </el-form-item>
            <el-form-item prop="isShow" label="列表展示">
                <el-select v-model="dataForm.isShow" size="small" placeholder="列表展示"
                >
                    <el-option
                            v-for="item in  isShow"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                            size="small">
                    </el-option>
                </el-select>
            </el-form-item>

            <el-form-item prop="isSupportSearch" label="支持搜索">
                <el-select v-model="dataForm.isSupportSearch" size="small" placeholder="支持搜索"
                >
                    <el-option
                            v-for="item in  isSupportSearch"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                            size="small">
                    </el-option>
                </el-select>
            </el-form-item>

            <el-form-item prop="appletShow" label="支持小程序搜索">
                <el-select v-model="dataForm.appletShow" size="small" placeholder="支持搜索"
                >
                    <el-option
                            v-for="item in  isSupportSearch"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                            size="small">
                    </el-option>
                </el-select>
            </el-form-item>

            <el-form-item prop="isEnable" label="显示状态">
                <el-select v-model="dataForm.isEnable" size="small" placeholder="显示状态"
                >
                    <el-option
                            v-for="item in  isEnable"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                            size="small">
                    </el-option>
                </el-select>
            </el-form-item>

            <el-form-item>
                <el-button @click="restPage()" size="small">筛选</el-button>
                <el-button @click="reset('dataForm')" size="small">清空</el-button>
                <el-button v-if="role=='0'" type="primary" @click="addOrUpdateHandle()" size="small">添加属性</el-button>
            </el-form-item>
            <el-form-item style="float:right;">
                <el-button type="primary" @click="synchronizationCheck()" size="small" v-if="role!='1'">数据同步检查</el-button>
            </el-form-item>
        </el-form>
        <el-table
                :data="dataList"
                border
                size="small"
                v-loading="dataListLoading"
                @selection-change="selectionChangeHandle"
                style="width: 100%;">
            <el-table-column
                    prop="attrName"
                    header-align="center"
                    align="center"
                    label="属性名称">
            </el-table-column>
            <el-table-column v-if="role=='0'"
                    prop="brandName"
                    header-align="center"
                    align="center"
                    label="所属品牌">
            </el-table-column>
            <el-table-column
                    prop="isUniversal"
                    header-align="center"
                    align="center"
                    label="是否通用">
                <template slot-scope="scope">
                    <div v-if="scope.row.isUniversal == '1'">是</div>
                    <div v-if="scope.row.isUniversal == '0'">否</div>
                </template>
            </el-table-column>
            <el-table-column
                    prop="currentSort"
                    header-align="center"
                    align="center"
                    label="当前排序">
            </el-table-column>
            <el-table-column
                    prop="isPrice"
                    header-align="center"
                    align="center"
                    label="是否计价">
            </el-table-column>
            <el-table-column
                    prop="isShow"
                    header-align="center"
                    align="center"
                    label="列表展示">
            </el-table-column>
            <el-table-column
                    prop="isSupportSearch"
                    header-align="center"
                    align="center"
                    label="支持搜索">
            </el-table-column>
            <el-table-column
                    prop="appletShow"
                    header-align="center"
                    align="center"
                    label="支持小程序搜索">
                <template slot-scope="scope">
                    <div v-if="scope.row.appletShow == '1'">是</div>
                    <div v-if="scope.row.appletShow == '0'">否</div>
                </template>
            </el-table-column>
            <!-- <el-table-column
                    prop="synState"
                    header-align="center"
                    align="center"
                    label="是否同步数据">
                <template slot-scope="scope">
                    <div v-if="scope.row.synState == '1' && scope.row.isUniversal == '1'">是</div>
                    <div v-if="scope.row.synState == '0' && scope.row.isUniversal == '1'">否</div>
                </template>
            </el-table-column>
            <el-table-column
                    prop="isSystem"
                    header-align="center"
                    align="center"
                    label="是否系统">
            </el-table-column> -->
            <el-table-column
                    prop="casLevel"
                    header-align="center"
                    align="center"
                    label="级联级别">
            </el-table-column>
            <el-table-column
                    prop="relationAttrName"
                    header-align="center"
                    align="center"
                    label="已关联属性">
            </el-table-column>
            <el-table-column
                    prop="operationType"
                    header-align="center"
                    align="center"
                    label="操作类型">
            </el-table-column>
            <el-table-column
                    prop="isEnable"
                    header-align="center"
                    align="center"
                    label="状态">
            </el-table-column>
            <el-table-column
                    prop="createTime"
                    header-align="center"
                    align="center"
                    label="创建时间">
            </el-table-column>
            <!-- <el-table-column
                    fixed="right"
                    header-align="center"
                    align="center"
                    width="150"
                    label="操作">
                <template slot-scope="scope">
                    <el-button type="text" size="small" @click="addOrUpdateHandle(scope.row.id,scope.row.brandId)">修改</el-button>
                    <el-button type="text" size="small" @click="setsynProductAttr(scope.row.id)" v-if="scope.row.isUniversal == '1'" >同步数据</el-button>
                    <el-button type="text" size="small" v-if="role=='0' && scope.row.isEnable=='关闭'" @click="dataFormSubmit(scope.row.id,'1',scope.row.currentSort)">启用</el-button>
                    <el-button type="text" size="small" v-if="role=='0' && scope.row.isEnable=='启用'" @click="dataFormSubmit(scope.row.id,'0',scope.row.currentSort)">关闭</el-button>
                    <el-button type="text" size="small" v-if="role=='0'" @click="deleteHandle(scope.row.id,scope.row.brandId)">删除</el-button>
                </template>
            </el-table-column> -->
            <el-table-column
                    fixed="right"
                    header-align="center"
                    align="center"
                    width="150"
                    label="操作">
                <template slot-scope="scope">
                    <el-button type="text" size="small" @click="addOrUpdateHandle(scope.row.id,scope.row.brandId,role)" v-if="scope.row.isUniversal !== '1' || role == '0'">修改</el-button>
                    <!-- <el-button type="text" size="small" @click="setsynProductAttr(scope.row.id)"  v-if="scope.row.isUniversal == '1' && role == '0'" >同步数据</el-button> -->
                    <el-button type="text" size="small" v-if="role=='0' && scope.row.isEnable=='关闭'" @click="dataFormSubmit(scope.row.id,'1',scope.row.brandId,scope.row.currentSort)">启用</el-button>
                    <el-button type="text" size="small" v-if="role=='0' && scope.row.isEnable=='启用'" @click="dataFormSubmit(scope.row.id,'0',scope.row.brandId,scope.row.currentSort)">关闭</el-button>
                    <el-button type="text" size="small" v-if="role=='0'" @click="deleteHandle(scope.row.id,scope.row.brandId)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
                size="small"
                @size-change="sizeChangeHandle"
                @current-change="currentChangeHandle"
                :current-page="pageIndex"
                :page-sizes="[10, 20, 50, 100]"
                :page-size="pageSize"
                :total="totalPage"
                layout="total, sizes, prev, pager, next, jumper">
        </el-pagination>
        <!-- 弹窗, 新增 / 修改 -->
        <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
        <synchronization-check  v-if="synchronizationVisible" ref="synchronization" @refreshDataList="getDataList"></synchronization-check>
    </div>
</template>

<script>
    import {list, del, productBatchUpdate,add,update,synProductAttr} from '@/api/modules/productattr'
    import {getBrand} from '@/api/modules/product'
    import AddOrUpdate from './add-or-update'
    import SynchronizationCheck from './synchronization-check'

    export default {
        data() {
            return {
                dataForm: {
                    attrName: '',
                    brandId: '',
                    daterange: [],
                    isShow:'',
                    isSupportSearch:'',
                    isEnable:'',
                    appletShow:'',
                },
                brandList: [],
                dataList: [],
                pageIndex: 1,
                pageSize: 10,
                totalPage: 0,
                dataListLoading: false,
                dataListSelections: [],
                addOrUpdateVisible: false,
                synchronizationVisible:false,
                role:'',
                dataForm2: {
                    productAttrId: '',
                    attrName: '',
                    currentSort: '',
                    brandId: '',
                    casLevel: '',
                    isPrice: '',
                    isShow: '',
                    isDelete: '',
                    createBy: '',
                    createTime: '',
                    updateBy: '',
                    updateTime: '',
                    version: '',
                    isEnable: '',
                    isListShow: '',
                    isSupportSearch: '',
                    operationType:'',
                    description:''
                },

                isShow: [
                    {
                        value: '',
                        label: '全部'
                    },
                    {
                        value: '1',
                        label: '是'
                    },
                    {
                        value: '0',
                        label: '否'
                    }
                ],
                isSupportSearch: [
                    {
                        value: '',
                        label: '全部'
                    },
                    {
                        value: '1',
                        label: '是'
                    },
                    {
                        value: '0',
                        label: '否'
                    }
                ],
                isEnable: [
                    {
                        value: '',
                        label: '全部'
                    },
                    {
                        value: '1',
                        label: '启用'
                    },
                    {
                        value: '0',
                        label: '关闭'
                    }
                ],
            }
        },
        components: {
            AddOrUpdate,
            SynchronizationCheck
        },
        // activated() {
        //     this.getDataList()
        //     this.getBrandList();
        // },
        mounted() {
            this.getDataList()
            this.getBrandList();
        },
        methods: {

            //重新查询
            restPage() {
                this.pageIndex = 1;
                this.getDataList();
                this.getBrandList();
            },
            reset(dataForm) {
                this.$refs.dataForm.resetFields();
                this.restPage();
            },
            //获取品牌列表
            getBrandList() {
                let params = {}
                getBrand(params).then(({data}) => {
                    if (data && data.code === 0) {
                        this.brandList = data.list
                    } else {
                        this.brandList = []
                    }
                })
            },
            // 获取数据列表
            getDataList() {
                this.dataListLoading = true
                var params = {
                    page: this.pageIndex,
                    limit: this.pageSize,
                    attrName: this.dataForm.attrName ? this.dataForm.attrName : '',
                    brandId: this.dataForm.brandId,
                    isShow: this.dataForm.isShow,
                    isSupportSearch: this.dataForm.isSupportSearch,
                    isEnable: this.dataForm.isEnable,
                    appletShow:this.dataForm.appletShow
                }
                list(params).then(({data}) => {
                    if (data && data.code === 0) {
                        this.dataList = data.page.records
                        this.totalPage = data.page.total
                        this.role = data.role
                    } else {
                        this.dataList = []
                        this.totalPage = 0
                    }
                    this.dataListLoading = false
                })
            },

            // 表单提交
            dataFormSubmit (id,isEnable,brandId,currentSort) {
                // console.log("id:"+id)
                // console.log("isEnable:"+isEnable)
                this.$refs['dataForm'].validate((valid) => {
                    if (valid) {
                        this.dataLoading = true;
                        var params = {
                            'productAttrId': id || undefined,
                            'isEnable': isEnable,
                            'attrName': '',
                            'currentSort': currentSort,
                            'brandId': brandId || '',
                            'casLevel': '',
                            'isPrice': '',
                            'isShow':'',
                            'isDelete':'',
                            'createBy': '',
                            'createTime':'',
                            'updateBy': '',
                            'updateTime': '',
                            'version': '',
                            'isListShow':'',
                            'isSupportSearch': '',
                            'operationType': '',
                            'description': '',
                        }
                        var tick = !id ? add(params) : update(params)
                        tick.then(({data}) => {
                            if (data && data.code === 0) {
                                this.$message({
                                    message: '操作成功',
                                    type: 'success',
                                    duration: 1500,
                                })
                                this.getDataList();
                            } else {
                                this.$message.error(data.msg)
                                this.dataLoading = false;
                            }
                        })
                    }
                })
            },

            // 每页数
            sizeChangeHandle(val) {
                this.pageSize = val
                this.pageIndex = 1
                this.getDataList()
            },
            // 当前页
            currentChangeHandle(val) {
                this.pageIndex = val
                this.getDataList()
            },
            // 多选
            selectionChangeHandle(val) {
                this.dataListSelections = val
            },
            // 新增 / 修改
            addOrUpdateHandle(id,brandId,role) {
                this.addOrUpdateVisible = true
                this.$nextTick(() => {
                    this.$refs.addOrUpdate.init(id,brandId,role)
                })
            },
            //数据同步检查
            synchronizationCheck(){
                this.synchronizationVisible = true
                this.$nextTick(() => {
                    this.$refs.synchronization.init()
                })
            },
            //同步数据
            setsynProductAttr(id){
                let params = {
                    productAttrId: id,
                }
                synProductAttr(params).then(({data}) => {
                    if (data && data.code === 0) {
                        this.$message({
                            message: '操作成功',
                            type: 'success',
                            duration: 1500,
                            onClose: () => {
                                this.getDataList()
                            }
                        })
                    } else {
                        this.$message.error(data.msg)
                    }
                })
            },

            // 删除
            deleteHandle(id,brandId) {
                /*var ids = id ? [id] : this.dataListSelections.map(item => {
                  return item.productAttrId
                })*/
                let params = {
                    ids: id,
                    brandId: brandId ? brandId : ''
                }
                this.$confirm(`是否删除当前属性?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    del(params).then(({data}) => {
                        if (data && data.code === 0) {
                            this.$message({
                                message: '操作成功',
                                type: 'success',
                                duration: 1500,
                                onClose: () => {
                                    this.getDataList()
                                }
                            })
                        } else {
                            this.$message.error(data.msg)
                        }
                    })
                })
            }
        }
    }
</script>
