import { render, staticRenderFns } from "./cpdetails.vue?vue&type=template&id=32cac878&scoped=true&xmlns=http%3A%2F%2Fwww.w3.org%2F1999%2Fhtml&"
import script from "./cpdetails.vue?vue&type=script&lang=js&"
export * from "./cpdetails.vue?vue&type=script&lang=js&"
import style0 from "./cpdetails.vue?vue&type=style&index=0&id=32cac878&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.10.0@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32cac878",
  null
  
)

export default component.exports