<template>
	<div class="app-container colorcompre">
		<div type="flex"
			style="text-align:center;align-items:center;height: 75px;font-size: 20px;font-weight: 550;color: #000000;-webkit-text-stroke: 1 #000000;text-stroke: 1 #000000;">
			<div class="titleone"><img :src="require('@/icons/svg/colorlogo.png')" style="'width:84px; height: 27px;margin-top:-10px;'" />L*a*b*色差比较</div>
		</div>
		<div class="contentBox">
			<el-row type="flex" align="middle" style="text-align:center;border-bottom: 2px solid #D2D2D2;height:56px;">
				<el-col :span="3" style="height:56px;line-height:56px;font-weight: 550;">
					<p>参与比较</p>
				</el-col>
				<el-col :span="7" v-for="(x,index) in pro1" :key="index" class="dancheck" style="height:56px;line-height:56px;">
					<el-checkbox v-model="pro1[index].checkState" size="medium" @change="checkState(index)"
						:disabled="index == 0 ? true:false"></el-checkbox>
				</el-col>
			</el-row>
			<el-row type="flex" align="middle" style="text-align:center;border-bottom: 2px solid #D2D2D2;height:60px;vertical-align: middle;">
				<el-col :span="3" style="height:60px;line-height:60px;font-weight: 550;">
					<p>样品名称</p>
				</el-col>
				<el-col :span="7" v-for="(x,index) in pro1" :key="index" class="inputkuang"  style="height:60px;line-height:60px;">
					<el-input v-model="x.itemName" :placeholder="x.itemName"
						style="width:60%;" size="small"
						@input="setItemName(index)"></el-input>
				</el-col>
			</el-row>
			<el-row type="flex" align="middle" style="text-align:center;border-bottom: 2px solid #D2D2D2;height:180px;">
				<el-col :span="3" style="height:180px;line-height:180px;font-weight: 550;">
					<p>查询方式</p>
				</el-col>
				<el-col :span="7" v-for="(x,index) in pro1" :key="index" style="padding: 13px 8px;height:180px;">
					<el-row type="flex" align="middle" style="height:40px;">
						<el-col :span="24">
							<el-radio-group v-model="x.checkType" @change="checkTypeChange(index)">
								<el-radio :label="0">输入编号</el-radio>
								<el-radio :label="1">输入Lab值</el-radio>
								<el-radio :label="2">从设备获取</el-radio>
							</el-radio-group>
						</el-col>
					</el-row>
					<el-row align="middle" style="height: 90px;justify-content: center;vertical-align:middle; ">
						<el-col :span="24" style="height: 90px;">
							<div v-show="x.checkType == 0" class="inputkuangtwo" style="line-height: 90px;white-space:nowrap">
								<!-- <el-input v-model="x.productNo" placeholder="产品号" style="width: 70%;" size="small"> </el-input>-->
								<el-autocomplete
									v-model="x.productNo"
									:fetch-suggestions="querySearch"
									@select="handleSelect"
									placeholder="产品号"
									:trigger-on-focus="true"
									size="small"
									:debounce=10
									ref='mycolorNoType'
									style="width: 70%;"
									@input="inputValue = x.productNo"
									@blur="inputValue = ''"
									@focus="inputValue = x.productNo"
								></el-autocomplete>
								<el-button size="small" type="primary" @click="getE00value()"
									style="margin-left: 2px;padding: 9px 10px;">查询</el-button>
							</div>
							<div v-show="x.checkType == 1" class="input12">
								<el-row v-for="(x4,index1) in pro4" :key="index1">
									<el-col :span="4">
										<el-input :placeholder="x4" size="small" @input="change($event)"
											:disabled="true"></el-input>
									</el-col>
									<el-col :span="5">
										<el-input v-model="x['l'+(index1+1)]" :placeholder="'L*'" size="small">
										</el-input>
									</el-col>
									<el-col :span="5">
										<el-input v-model="x['a'+(index1+1)]" :placeholder="'a*'" size="small">
										</el-input>
									</el-col>
									<el-col :span="5">
										<el-input v-model="x['b'+(index1+1)]" :placeholder="'b*'" size="small">
										</el-input>
									</el-col>
									<el-col :span="5">
										<el-button v-show="index1 == 1" size="small" type="primary"
											@click="getE00value()" style="margin-left: 2px;padding: 9px 10px;">查询
										</el-button>
									</el-col>
								</el-row>
							</div>
							<div v-show="x.checkType == 2" style="line-height: 90px;">
								<el-button size="small" type="primary" @click="syncDevice(index)"
									style="margin-left: 2px;padding: 9px 10px;" :loading="loading">读取设备</el-button>
							</div>
						</el-col>
					</el-row>
				</el-col>
			</el-row>
			<el-row type="flex" align="middle" style="text-align:center;border-bottom: 2px solid #D2D2D2;height:46px;vertical-align: middle;">
				<el-col :span="3" style="height:46px;">
					<p></p>
				</el-col>
				<el-col :span="7" style="height:46px;font-weight: normal;" v-for="(labx,index1) in pro1" :key="index1">
					<el-row type="flex" align="middle">
						<el-col :span="8" style="border-right: 2px solid #D2D2D2;font-size: 16px;color: #6F73FF;height:46px;">
							<p>L*</p>
						</el-col>
						<el-col :span="8" style="border-right: 2px solid #D2D2D2;font-size: 16px;color: #6F73FF;height:46px;">
							<p>a*</p>
						</el-col>
						<el-col :span="8" style="font-size: 16px;color: #6F73FF;height:46px;">
							<p>b*</p>
						</el-col>
					</el-row>
				</el-col>
			</el-row>
			<el-row type="flex" align="middle" v-for="(x,index) in pro4" :key="index"
				style="text-align:center;border-bottom: 2px solid #D2D2D2;height:46px;line-height: 12px;font-size:16px;">
				<el-col :span="3" style="height:46px;line-height:46px;">
					<p style="font-size: 16px;font-weight: 500;color: #000000;">{{x}}</p>
				</el-col>
				<el-col :span="7" style="padding: 0px;height:46px;" v-for="(labx,index1) in pro1" :key="index1">
					<el-row type="flex" style="height: 45px;font-size:14px;line-height:18px;">
						<el-col :span="8" style="border-right: 2px solid #D2D2D2;">
							<p size="small">{{labx['l'+(index+1)]}}</p>
						</el-col>
						<el-col :span="8" style="border-right: 2px solid #D2D2D2;">
							<p size="small">{{labx['a'+(index+1)]}}</p>
						</el-col>
						<el-col :span="8">
							<p size="small">{{labx['b'+(index+1)]}}</p>
						</el-col>
					</el-row>
				</el-col>
			</el-row>

			<el-row type="flex" align="middle" style="text-align:center;font-weight: 550;">
				<el-col :span="3">
					<p>色彩预览</p>
				</el-col>
				<el-col :span="7" v-for="(x,index) in pro1" :key="index">
					<div style="display:flex;justify-content: center;height:100px;">
						<img v-if="x.picUrl" class="pic" :src="x.picUrl" min-width="100%" />
                        <div v-else :style="{backgroundColor:x.hex}" class="pic"></div>
					</div>
				</el-col>
			</el-row>
		</div>
		<div type="flex" class="twotitle"
			style="text-align:center;height: 75px;font-size: 20px;font-weight: 550;color: #000000;-webkit-text-stroke: 1 #000000;text-stroke: 1 #000000;">
			<div class="titletwo"><img :src="require('@/icons/svg/colorlogo.png')" style="'width:84px; height: 27px;'" />L*a*b*色差比较结果</div>
			<div style="'font-size:14px;color: #6F73FF;'" class="daochu">
				<el-button size="small" type="primary" @click="judge()" style="margin-right: 30px;" :loading="hisloading">保存历史</el-button>
				<a @click="exportExcelHandle()">
					<div class="daochu_"><img :src="require('@/icons/svg/daochu.png')" style="'width:15px; height: 15px;'" /> 导出报告</div>
				</a>
			</div>
		</div>
		<div class="contentBoxtwo">
			<!-- <el-row type="flex" style="text-align:center;border-bottom: 2px solid #D2D2D2;line-height: 50px;">
				<el-col :span="24" style="line-height: 80px;font-weight: 400;font-size: 25px;">
					<p>{{testItem.testName}}</p>
				</el-col>
			</el-row> -->

			<div v-for="(testItem,index1) in test" :key="index1" v-show="testShow[index1]" style="border-bottom: 2px solid #D2D2D2;">
				<el-row type="flex" style="text-align:center;border-bottom: 2px solid #D2D2D2;height: 40px;">
					<el-col :span="24" style="font-size: 16px;font-weight: bold;color: #000000;height: 40px;line-height: 8px;">
						<p>{{testItem.testName}}</p>
					</el-col>
				</el-row>
				<el-row type="flex" align="middle" style="font-size: 14px;height:132px;font-weight: 550;">
					<el-col :span="3" style="'border-right: 2px solid #D2D2D2;height:132px;'">
						<el-row type="flex" style="text-align:center;border-bottom: 2px solid #D2D2D2;height: 36px;">
							<el-col :span="24" style="line-height: 9px;">
								<p>综合</p>
							</el-col>
						</el-row>
						<el-row type="flex" style="height: 98px;line-height:48px;text-align:center;">
							<el-col :span="24" style="font-size: 24px;font-weight: 500;color: #6F73FF;">
								<p>{{testItem.del2000}}</p>
							</el-col>
						</el-row>
					</el-col>
					<el-col :span="7" v-for="(resultItem,index2) in testItem.testResult" :key="index2" style="padding:0px;height:132px;font-weight: 500;">
						<el-row type="flex" style="text-align:center;border-bottom: 2px solid #D2D2D2;height: 36px;line-height: 9px;">
							<el-col :span="24">
								<p>{{resultItem.resultName}}</p>
							</el-col>
						</el-row>
						<el-row type="flex" style="text-align:center;border-bottom: 2px solid #D2D2D2;color: #6F73FF;height: 32px;line-height: 6px;">
							<el-col :span="6" style="border-right: 2px solid #D2D2D2;height: 32px;">
								<p>ΔL*</p>
							</el-col>
							<el-col :span="6" style="border-right: 2px solid #D2D2D2;height: 32px;">
								<p>Δa*</p>
							</el-col>
							<el-col :span="6" style="border-right: 2px solid #D2D2D2;height: 32px;">
								<p>Δb*</p>
							</el-col>
							<el-col :span="6">
								<p>ΔE*</p>
							</el-col>
						</el-row>
						<el-row type="flex" style="text-align:center;border-bottom: 2px solid #D2D2D2;height: 32px;line-height: 6px;">
							<el-col :span="6" style="border-right: 2px solid #D2D2D2;height: 32px;">
								<p>{{resultItem.deL}}</p>
							</el-col>
							<el-col :span="6" style="border-right: 2px solid #D2D2D2;height: 32px;">
								<p>{{resultItem.dea}}</p>
							</el-col>
							<el-col :span="6" style="border-right: 2px solid #D2D2D2;height: 32px;">
								<p>{{resultItem.deb}}</p>
							</el-col>
							<el-col :span="6" >
								<p>{{resultItem.dee}}</p>
							</el-col>
						</el-row>
						<el-row type="flex" style="text-align:center;height: 32px;line-height: 6px;">
							<el-col :span="6" style="border-right: 2px solid #D2D2D2;height: 32px;">
								<p>{{resultItem.deLStr}}</p>
							</el-col>
							<el-col :span="6" style="border-right: 2px solid #D2D2D2;height: 32px;">
								<p>{{resultItem.deaStr}}</p>
							</el-col>
							<el-col :span="6" style="border-right: 2px solid #D2D2D2;height: 32px;">
								<p>{{resultItem.debStr}}</p>
							</el-col>
							<el-col :span="6">
								<p>{{resultItem.deeStr}}</p>
							</el-col>
						</el-row>
					</el-col>
				</el-row>
			</div>
			<el-dialog
				:visible.sync="dialogVisible"
				title="请输入比色名称"
				@close="colorCompreName = ''"
				width="30%">
				<el-input v-model="colorCompreName" placeholder="" size="small"> </el-input>
				<template #footer>
				<span class="dialog-footer">
					<el-button @click="dialogVisible = false,colorCompreName = ''">取消</el-button>
					<el-button type="primary" @click="saveHistory()">确定</el-button>
				</span>
				</template>
			</el-dialog>
		</div>
		<device-boot v-if="deviceBootShow" ref="deviceBoot" />
	</div>
</template>

<script>
	import { syncDevice, colorcomparison, saveHistory, selectProductNoList } from "@/api/modules/product";
	import { getToken } from "@/utils/auth";
	import { info } from '@/api/modules/colorcomprehistory';
	import { setDeviceState } from '@/api/modules/device';
	export default {
		data() {
			return {
				websock: null,
				activeIndex: '',
				loading: false,
				copydata:[],
				deviceBootShow: false,
				dialogVisible:false,//输入比色名称弹框
				colorCompreName:'',//比色名称
				hisloading:false,
				inputValue:'',
				timer: null, //首先我在data函数里面进行定义定时器名称：
				timerNum: 0, // 设置定时器时间
				device: {
					deviceId: "",
				},
				pro: [{
						checkState: false,
						itemName: "标样",
						checkType: "0",
						productNo: "",
						imgshow: true
					},
					{
						checkState: false,
						itemName: "试样一",
						checkType: 0,
						productNo: "",
						imgshow: true
					},
					{
						checkState: false,
						itemName: "试样二",
						checkType: 0,
						productNo: "",
						imgshow: true
					},
				],
				test: [{
						testName: "试样一",
						checkState: true,
						del2000: "-",
						testResult: [{
								resultName: "25°",
								deL: "-",
								dea: "-",
								deb: "-",
								dee: "-",
								deLStr: "-",
								deaStr: "-",
								debStr: "-",
								deeStr: "-"
							},
							{
								resultName: "45°",
								deL: "-",
								dea: "-",
								deb: "-",
								dee: "-",
								deLStr: "-",
								deaStr: "-",
								debStr: "-",
								deeStr: "-"
							},
							{
								resultName: "75°",
								deL: "-",
								dea: "-",
								deb: "-",
								dee: "-",
								deLStr: "-",
								deaStr: "-",
								debStr: "-",
								deeStr: "-"
							}
						]
					},
					{
						testName: "试样二",
						checkState: false,
						del2000: "",
						testResult: [{
								resultName: "25°",
								deL: "-",
								dea: "-",
								deb: "-",
								dee: "-",
								deLStr: "-",
								deaStr: "-",
								debStr: "-",
								deeStr: "-"
							},
							{
								resultName: "45°",
								deL: "-",
								dea: "-",
								deb: "-",
								dee: "-",
								deLStr: "-",
								deaStr: "-",
								debStr: "-",
								deeStr: "-"
							},
							{
								resultName: "75°",
								deL: "-",
								dea: "-",
								deb: "-",
								dee: "-",
								deLStr: "-",
								deaStr: "-",
								debStr: "-",
								deeStr: "-"
							}
						]
					}
				],
				pro1: [{
						checkState: true,
						itemName: "标样",
						checkType: 0,
						picUrl: "",
						hex:'',
						productNo: "",
						l1: "",
						a1: "",
						b1: "",
						l2: "",
						a2: "",
						b2: "",
						l3: "",
						a3: "",
						b3: ""
					},
					{
						checkState: true,
						itemName: "试样一",
						checkType: 0,
						picUrl: "",
						hex:'',
						productNo: "",
						l1: "",
						a1: "",
						b1: "",
						l2: "",
						a2: "",
						b2: "",
						l3: "",
						a3: "",
						b3: ""
					},
					{
						checkState: false,
						itemName: "试样二",
						checkType: 0,
						picUrl: "",
						hex:'',
						productNo: "",
						l1: "",
						a1: "",
						b1: "",
						l2: "",
						a2: "",
						b2: "",
						l3: "",
						a3: "",
						b3: ""
					},
				],
				pro2: [{
						col1: "L*",
						col2: "a*",
						col3: "b*"
					},
					{
						col1: "L*",
						col2: "a*",
						col3: "b*"
					},
					{
						col1: "L*",
						col2: "a*",
						col3: "b*"
					}
				],
				pro3: ['', '25°', '45°', '75°'],
				pro4: ['25°', '45°', '75°'],
				deviceList: [],
				testShow: [true, false],
				is_query: false,
				dataForm: {
					e00lab2: "",
					e00Value: "",
					productOrder: "",
					productName: "",
					id: "",
					productNo: "",
					picUrl: "",
					qrUrl: "",
					ralColorNo: "",
					gloss: "",
					glossValue: "",
					surface: "",
					colorSeries: "",
					productPerformance: "",
					otherPerformance: "",
					productCategory: "",
					productHierarchy: "",
					industryId: "",
					factoryId: "",
					brandId: "",
					description: "",
					labAve: "",
					l1: "",
					a1: "",
					b1: "",
					l2: "",
					a2: "",
					b2: "",
					l3: "",
					a3: "",
					b3: "",
					lAve: "",
					aAve: "",
					bAve: "",
					isOpen: "",
				},
			};
		},
		components: {
			DeviceBoot: () => import('@/components/DeviceBoot')
		},
		created() {
		},
		//写个过滤器，保留小数点后两位
		filters: {
			numFilter(value) {
				let realVal = "";
				if (!isNaN(value) && value !== "" && value !== null) {
					// 截取当前数据到小数点后两位
					realVal = parseFloat(value).toFixed(2);
				} else {
					realVal = "";
				}
				return realVal;
			},
		},
		activated(){
			var query = this.$route.query;
			if (query && query.id) {
				var id = query.id;
				this.getInfo(id)
			}
		},
		methods: {
			judge(){
				if (this.copydata[0] && this.copydata[0].l1 && this.copydata[0].l2 && this.copydata[0].l3 && this.copydata[0].a1 && this.copydata[0].a2 && this.copydata[0].a3 && this.copydata[0].b1 && this.copydata[0].b2 && this.copydata[0].b3) {
					if ((this.copydata[1] && this.copydata[1].checkState && this.copydata[1].l1 && this.copydata[1].l2 && this.copydata[1].l3 && this.copydata[1].a1 && this.copydata[1].a2 && this.copydata[1].a3 && this.copydata[1].b1 && this.copydata[1].b2 && this.copydata[1].b3) || (this.copydata[2] && this.copydata[2].checkState && this.copydata[2].l1 && this.copydata[2].l2 && this.copydata[2].l3 && this.copydata[2].a1 && this.copydata[2].a2 && this.copydata[2].a3 && this.copydata[2].b1 && this.copydata[2].b2 && this.copydata[2].b3)) {
						if (this.test[0].del2000 || this.test[1].del2000) {
							this.dialogVisible = true
							this.colorCompreName = this.format()
						}
					}else{
						this.$message({
							message: '试样信息为空',
							type: 'error',
							duration: 1500,
						})
					}
				}else{
					this.$message({
						message: '标样信息为空',
						type: 'error',
						duration: 1500,
					})
				}
			},
			format(){
				var time = new Date();
				var y = time.getFullYear();
				var m = time.getMonth()+1;
				var d = time.getDate();
				var h = time.getHours();
				var mm = time.getMinutes();
				var s = time.getSeconds();
				return y.toString()+this.add0(m).toString()+this.add0(d).toString()+this.add0(h).toString()+this.add0(mm).toString()+this.add0(s).toString()
			},
			add0(m){
				return m < 10 ? '0' + m : m 
			},
			saveHistory(){
				var standardParam = {};
				var sampleOneParam = {};
				var sampleTwoParam = {};
				standardParam = this.copydata[0];
				if (this.copydata[1].checkState && this.copydata[1].l1 && this.copydata[1].l2 && this.copydata[1].l3 && this.copydata[1].a1 && this.copydata[1].a2 && this.copydata[1].a3 && this.copydata[1].b1 && this.copydata[1].b2 && this.copydata[1].b3) {
					sampleOneParam = this.copydata[1]
					sampleOneParam.dee = this.test[0].del2000
				}else{
					sampleOneParam = {}
				}
				if (this.copydata[2].checkState && this.copydata[2].l1 && this.copydata[2].l2 && this.copydata[2].l3 && this.copydata[2].a1 && this.copydata[2].a2 && this.copydata[2].a3 && this.copydata[2].b1 && this.copydata[2].b2 && this.copydata[2].b3) {
					sampleTwoParam = this.copydata[2]
					sampleTwoParam.dee = this.test[1].del2000
				}else{
					sampleTwoParam = {}
				}
				if (JSON.stringify(sampleOneParam) == "{}" && JSON.stringify(sampleTwoParam) == "{}") {
					this.$message({
						message: '试样信息有误',
						type: 'error',
						duration: 1500,
					})
				}else{
					this.hisloading = true;
					var params = {
						colorCompreName:this.colorCompreName,
						standardParam: standardParam,
						sampleOneParam: JSON.stringify(sampleOneParam) == "{}" ?  null : sampleOneParam,
						sampleTwoParam: JSON.stringify(sampleTwoParam) == "{}" ?  null : sampleTwoParam,
					}
					saveHistory(params).then(({data}) => {
						if (data && data.code === 0) {
							this.$message({
								message: '操作成功',
								type: 'success',
								duration: 1500,
							})
						} else {
							this.$message.error(data.msg)
						}
						this.hisloading = false
						this.colorCompreName = ''
						this.dialogVisible = false
					})
				}
			},

			querySearch(queryString, cb) {
			if(queryString && this.inputValue.length > 2){
				setTimeout(() =>{
					let params = { 
						"productNo": this.inputValue
					};
					selectProductNoList(params).then(({ data }) => {
						if (data && data.code === 0) {
							var restaurants = [];
							restaurants = data.data.map((v) => {
							var obj = {
								value : v
							}
							return obj
							})

							if(restaurants.length <= 0){
							return this.$message.error("没有匹配到相关产品");
							}
							cb(restaurants);
						}
					})
					},500);
				}
			},

			getInfo(id){
				var params = {
					id: id
				}
				info(params).then(({ data }) => {
					if (data && data.code === 0) {
						if (data.data.normVo) {
							this.pro1[0].checkState = data.data.normVo.checkState
							this.pro1[0].itemName = data.data.normVo.itemName
							this.pro1[0].checkType = data.data.normVo.checkType
							this.pro1[0].picUrl = data.data.normVo.picUrl
							this.pro1[0].productNo = data.data.normVo.productNo
							this.pro1[0].hex = data.data.normVo.hex
							this.pro1[0].l1 = data.data.normVo.l1
							this.pro1[0].a1 = data.data.normVo.a1
							this.pro1[0].b1 = data.data.normVo.b1
							this.pro1[0].l2 = data.data.normVo.l2
							this.pro1[0].a2 = data.data.normVo.a2
							this.pro1[0].b2 = data.data.normVo.b2
							this.pro1[0].l3 = data.data.normVo.l3
							this.pro1[0].a3 = data.data.normVo.a3
							this.pro1[0].b3 = data.data.normVo.b3
						}
						if (data.data.labProVo) {
							// this.pro1[1] = data.data.labProVo
							this.pro1[1].checkState = data.data.labProVo.checkState
							this.testShow[0] = true
							this.pro1[1].itemName = data.data.labProVo.itemName
							this.pro1[1].checkType = data.data.labProVo.checkType
							this.pro1[1].picUrl = data.data.labProVo.picUrl
							this.pro1[1].productNo = data.data.labProVo.productNo
							this.pro1[1].hex = data.data.labProVo.hex
							this.pro1[1].l1 = data.data.labProVo.l1
							this.pro1[1].a1 = data.data.labProVo.a1
							this.pro1[1].b1 = data.data.labProVo.b1
							this.pro1[1].l2 = data.data.labProVo.l2
							this.pro1[1].a2 = data.data.labProVo.a2
							this.pro1[1].b2 = data.data.labProVo.b2
							this.pro1[1].l3 = data.data.labProVo.l3
							this.pro1[1].a3 = data.data.labProVo.a3
							this.pro1[1].b3 = data.data.labProVo.b3
						}else{
							this.pro1[1].checkState = false
							this.testShow[0] = false
							this.pro1[1].checkType = 0
							this.pro1[1].picUrl = ''
							this.pro1[1].productNo = ''
							this.pro1[1].hex = ''
							this.pro1[1].l1 = ''
							this.pro1[1].a1 = ''
							this.pro1[1].b1 = ''
							this.pro1[1].l2 = ''
							this.pro1[1].a2 = ''
							this.pro1[1].b2 = ''
							this.pro1[1].l3 = ''
							this.pro1[1].a3 = ''
							this.pro1[1].b3 = ''
						}
						if (data.data.TwolabProVo) {
							// this.pro1[2] = data.data.TwolabProVo
							this.pro1[2].checkState = data.data.TwolabProVo.checkState
							this.testShow[1] = true
							this.pro1[2].itemName = data.data.TwolabProVo.itemName
							this.pro1[2].checkType = data.data.TwolabProVo.checkType
							this.pro1[2].picUrl = data.data.TwolabProVo.picUrl
							this.pro1[2].productNo = data.data.TwolabProVo.productNo
							this.pro1[2].hex = data.data.TwolabProVo.hex
							this.pro1[2].l1 = data.data.TwolabProVo.l1
							this.pro1[2].a1 = data.data.TwolabProVo.a1
							this.pro1[2].b1 = data.data.TwolabProVo.b1
							this.pro1[2].l2 = data.data.TwolabProVo.l2
							this.pro1[2].a2 = data.data.TwolabProVo.a2
							this.pro1[2].b2 = data.data.TwolabProVo.b2
							this.pro1[2].l3 = data.data.TwolabProVo.l3
							this.pro1[2].a3 = data.data.TwolabProVo.a3
							this.pro1[2].b3 = data.data.TwolabProVo.b3
						}else{
							this.pro1[2].checkState = false
							this.testShow[1] = false
							this.pro1[2].checkType = 0
							this.pro1[2].picUrl = ''
							this.pro1[2].productNo = ''
							this.pro1[2].hex = ''
							this.pro1[2].l1 = ''
							this.pro1[2].a1 = ''
							this.pro1[2].b1 = ''
							this.pro1[2].l2 = ''
							this.pro1[2].a2 = ''
							this.pro1[2].b2 = ''
							this.pro1[2].l3 = ''
							this.pro1[2].a3 = ''
							this.pro1[2].b3 = ''
						}
						this.$set(this.pro1)
						this.getE00value()
					}
				})
			},

			handleSelect(item) { },

			//清空input框数据
			clear(index) {
				// console.log(index)
				//循环输入框的内容把数据清理掉
				this.pro[index].productNo = "";
				//遍历数据显示部分的数据，并清理掉
				this.$forceUpdate();
				// this.getE00value(index);
			},

			checkState(index) {
				// console.log(index);
				// console.log(this.pro1[index].checkState);
				this.testShow[index - 1] = this.pro1[index].checkState;
				this.getE00value();
			},

			setItemName(index) {
				// console.log(index);
				if (index >= 1 && this.pro1[index].checkState) {
					this.test[index - 1].testName = this.pro1[index].itemName;
				}
			},

			checkTypeChange(index) {
				//console.log(index);
				//console.log(this.pro1[index].checkType);

			},
			//根据产品编号和LAB值进行计算（通过失去焦点事件触发）
			getE00value() {
				let standardParam = this.pro1[0];
				let sampleOneParam = this.pro1[1];
				let sampleTwoParam = this.pro1[2];
				standardParam['picUrl'] = ''
				sampleOneParam['picUrl'] = ''
				sampleTwoParam['picUrl'] = ''
				var params = {
					standardParam: standardParam, //标准色
					sampleOneParam: sampleOneParam, //批次样色1
					sampleTwoParam: sampleTwoParam //批次样色2
				};
				// console.log(params);
				colorcomparison(params).then(({
					data
				}) => {
					if (data && data.code === 0) {
						/*如果先点击读取设备按钮获取到数据，再次获取标准产品编号数据就会直接把前面数据覆盖为空*/
						var normVo = JSON.parse(JSON.stringify(data.map.normVo));
						// console.log(normVo);
						if (normVo.checkState && normVo.checkType == 0 && (!normVo.l2 || !normVo.a2 || !normVo.b2) && normVo.productNo) {
							this.$message.error('没有查询到产品数据');
						}
						this.pro1[0].checkState = normVo.checkState
						this.pro1[0].itemName = normVo.itemName
						this.pro1[0].checkType = normVo.checkType
						this.pro1[0].picUrl = normVo.picUrl
						this.pro1[0].productNo = normVo.productNo
						this.pro1[0].hex = normVo.hex
						this.pro1[0].l1 = normVo.l1
						this.pro1[0].a1 = normVo.a1
						this.pro1[0].b1 = normVo.b1
						this.pro1[0].l2 = normVo.l2
						this.pro1[0].a2 = normVo.a2
						this.pro1[0].b2 = normVo.b2
						this.pro1[0].l3 = normVo.l3
						this.pro1[0].a3 = normVo.a3
						this.pro1[0].b3 = normVo.b3
						var labProVo = JSON.parse(JSON.stringify(data.map.labProVo));
						if (labProVo.checkState && labProVo.checkType == 0 && (!labProVo.l2 || !labProVo.a2 || !labProVo.b2) && labProVo.productNo) {
							this.$message.error('没有查询到产品数据');
						}
						this.pro1[1].checkState = labProVo.checkState
						this.pro1[1].itemName = labProVo.itemName
						this.pro1[1].checkType = labProVo.checkType
						this.pro1[1].picUrl = labProVo.picUrl
						this.pro1[1].productNo = labProVo.productNo
						this.pro1[1].hex = labProVo.hex
						this.pro1[1].l1 = labProVo.l1
						this.pro1[1].a1 = labProVo.a1
						this.pro1[1].b1 = labProVo.b1
						this.pro1[1].l2 = labProVo.l2
						this.pro1[1].a2 = labProVo.a2
						this.pro1[1].b2 = labProVo.b2
						this.pro1[1].l3 = labProVo.l3
						this.pro1[1].a3 = labProVo.a3
						this.pro1[1].b3 = labProVo.b3
						var TwolabProVo = JSON.parse(JSON.stringify(data.map.TwolabProVo));
						if (TwolabProVo.checkState && TwolabProVo.checkType == 0 && (!TwolabProVo.l2 || !TwolabProVo.a2 || !TwolabProVo.b2) && TwolabProVo.productNo) {
							this.$message.error('没有查询到产品数据');
						}
						this.pro1[2].checkState = TwolabProVo.checkState
						this.pro1[2].itemName = TwolabProVo.itemName
						this.pro1[2].checkType = TwolabProVo.checkType
						this.pro1[2].picUrl = TwolabProVo.picUrl
						this.pro1[2].productNo = TwolabProVo.productNo
						this.pro1[2].hex = TwolabProVo.hex
						this.pro1[2].l1 = TwolabProVo.l1
						this.pro1[2].a1 = TwolabProVo.a1
						this.pro1[2].b1 = TwolabProVo.b1
						this.pro1[2].l2 = TwolabProVo.l2
						this.pro1[2].a2 = TwolabProVo.a2
						this.pro1[2].b2 = TwolabProVo.b2
						this.pro1[2].l3 = TwolabProVo.l3
						this.pro1[2].a3 = TwolabProVo.a3
						this.pro1[2].b3 = TwolabProVo.b3
						if (data.map.colorCompreResult1) {
							var testMap1 = JSON.parse(JSON.stringify(data.map.colorCompreResult1));
							this.test[0] = testMap1;
						}else{
							this.test[0].testName =  this.pro1[1].itemName;
							this.test[0].checkState =  this.pro1[1].checkState;
							this.test[0].del2000 =  '';
							this.test[0].testResult = [{
								resultName: "25°",
								deL: "-",
								dea: "-",
								deb: "-",
								dee: "-",
								deLStr: "-",
								deaStr: "-",
								debStr: "-",
								deeStr: "-"
							},
							{
								resultName: "45°",
								deL: "-",
								dea: "-",
								deb: "-",
								dee: "-",
								deLStr: "-",
								deaStr: "-",
								debStr: "-",
								deeStr: "-"
							},
							{
								resultName: "75°",
								deL: "-",
								dea: "-",
								deb: "-",
								dee: "-",
								deLStr: "-",
								deaStr: "-",
								debStr: "-",
								deeStr: "-"
							}]
						}
						if (data.map.colorCompreResult2) {
							var testMap2 = JSON.parse(JSON.stringify(data.map.colorCompreResult2));
							this.test[1] = testMap2;
						}else{
							this.test[1].testName =  this.pro1[2].itemName;
							this.test[1].checkState =  this.pro1[2].checkState;
							this.test[1].del2000 =  '';
							this.test[1].testResult = [{
								resultName: "25°",
								deL: "-",
								dea: "-",
								deb: "-",
								dee: "-",
								deLStr: "-",
								deaStr: "-",
								debStr: "-",
								deeStr: "-"
							},
							{
								resultName: "45°",
								deL: "-",
								dea: "-",
								deb: "-",
								dee: "-",
								deLStr: "-",
								deaStr: "-",
								debStr: "-",
								deeStr: "-"
							},
							{
								resultName: "75°",
								deL: "-",
								dea: "-",
								deb: "-",
								dee: "-",
								deLStr: "-",
								deaStr: "-",
								debStr: "-",
								deeStr: "-"
							}]
						}
						console.log(this.test)
						this.$forceUpdate();
						this.copydata = JSON.parse(JSON.stringify(this.pro1))
					} else {
						this.$message.error(data.msg);
					}
				});
			},
			//设备引导
			handleDeviceBoot() {
				this.deviceBootShow = true
				this.$nextTick(() => {
					setTimeout(() => {
						this.$refs.deviceBoot.init()
					})
				})
			},
			//设备同步
			syncDevice(index) {
				//this.restPage();
				this.loading = true;
				this.device.deviceId = this.$store.getters.userDevice.id;
				// console.log(this.device.deviceId);
				if (!this.device.deviceId) {
					this.loading = false;
					this.handleDeviceBoot();
					this.device.deviceId = this.$store.getters.userDevice.id;
				}
				let params = {
					deviceId: this.$store.getters.userDevice.id
				}
				syncDevice(params).then(({
					data
				}) => {
					if (data && data.code === 0) {
						console.log(data)
						this.initWebSocket();
						this.activeIndex = index;
						this.timer = setInterval(() => {
							//如果已经同步就关闭定时任务
							this.timerNum = this.timerNum + 1;
							console.log(this.timerNum)
							if (this.timerNum == 4) {
								this.clearTimer(); // 关闭定时器
								this.loading = false;
								this.pro1[this.activeIndex].l1 = '';
								this.pro1[this.activeIndex].a1 = '';
								this.pro1[this.activeIndex].b1 = '';
								this.pro1[this.activeIndex].l2 = '';
								this.pro1[this.activeIndex].a2 = '';
								this.pro1[this.activeIndex].b2 = '';
								this.pro1[this.activeIndex].l3 = '';
								this.pro1[this.activeIndex].a3 = '';
								this.pro1[this.activeIndex].b3 = '';
								this.pro1[this.activeIndex].picUrl = '';
								this.pro1[this.activeIndex].hex = '';
								this.$message.error("数据同步失败");
								this.setDeviceState();
								this.getDataLAB(this.activeIndex);
							}else{
								this.$message.warning("数据同步中，请确认设备是否有待同步数据")
							}
						}, 5000);
					} else {
						this.$message.error(data.msg);
						this.loading = false;
					}
				});
			},
			 setDeviceState() {
				let params = {
					deviceId : this.$store.getters.userDevice.id
				};
				setDeviceState(params).then(({ data }) => {});
			},
			clearTimer() {
				//清除定时器
				clearInterval(this.timer);
				this.timer = null;
				this.timerNum = 0;
			},
			change(e) {
				this.$forceUpdate()
			},
			//选择设备显示在页面
			handleCommand(command) {
				//  console.log(command);
				//this.$message('click on item ' + command);
				this.device = command;
			},
			//根据设备读取按钮传的数据计算色差值
			getDataLAB(index) {
				//根据设备读取的LAB值和标准色产品编号计算色差值
				let standardParam = this.pro1[0];
				let sampleOneParam = this.pro1[1];
				let sampleTwoParam = this.pro1[2];
				var params = {
					standardParam: standardParam, //标准色
					sampleOneParam: sampleOneParam, //批次样色1
					sampleTwoParam: sampleTwoParam //批次样色2
				};

				//如果只点击设备读取按钮时对应的对象
				colorcomparison(params).then(({
					data
				}) => {
					// console.log(can);
					if (data && data.code === 0) {
						var normVo = JSON.parse(JSON.stringify(data.map.normVo));
						this.pro1[0].checkState = normVo.checkState
						this.pro1[0].itemName = normVo.itemName
						this.pro1[0].checkType = normVo.checkType
						this.pro1[0].picUrl = normVo.picUrl
						this.pro1[0].hex = normVo.hex
						this.pro1[0].productNo = normVo.productNo
						this.pro1[0].l1 = normVo.l1
						this.pro1[0].a1 = normVo.a1
						this.pro1[0].b1 = normVo.b1
						this.pro1[0].l2 = normVo.l2
						this.pro1[0].a2 = normVo.a2
						this.pro1[0].b2 = normVo.b2
						this.pro1[0].l3 = normVo.l3
						this.pro1[0].a3 = normVo.a3
						this.pro1[0].b3 = normVo.b3
						var labProVo = JSON.parse(JSON.stringify(data.map.labProVo));
						this.pro1[1].checkState = labProVo.checkState
						this.pro1[1].itemName = labProVo.itemName
						this.pro1[1].checkType = labProVo.checkType
						this.pro1[1].picUrl = labProVo.picUrl
						this.pro1[1].hex = labProVo.hex
						this.pro1[1].productNo = labProVo.productNo
						this.pro1[1].l1 = labProVo.l1
						this.pro1[1].a1 = labProVo.a1
						this.pro1[1].b1 = labProVo.b1
						this.pro1[1].l2 = labProVo.l2
						this.pro1[1].a2 = labProVo.a2
						this.pro1[1].b2 = labProVo.b2
						this.pro1[1].l3 = labProVo.l3
						this.pro1[1].a3 = labProVo.a3
						this.pro1[1].b3 = labProVo.b3
						var TwolabProVo = JSON.parse(JSON.stringify(data.map.TwolabProVo));
						this.pro1[2].checkState = TwolabProVo.checkState
						this.pro1[2].itemName = TwolabProVo.itemName
						this.pro1[2].checkType = TwolabProVo.checkType
						this.pro1[2].picUrl = TwolabProVo.picUrl
						this.pro1[2].hex = TwolabProVo.hex
						this.pro1[2].productNo = TwolabProVo.productNo
						this.pro1[2].l1 = TwolabProVo.l1
						this.pro1[2].a1 = TwolabProVo.a1
						this.pro1[2].b1 = TwolabProVo.b1
						this.pro1[2].l2 = TwolabProVo.l2
						this.pro1[2].a2 = TwolabProVo.a2
						this.pro1[2].b2 = TwolabProVo.b2
						this.pro1[2].l3 = TwolabProVo.l3
						this.pro1[2].a3 = TwolabProVo.a3
						this.pro1[2].b3 = TwolabProVo.b3
						if (data.map.colorCompreResult1) {
							var testMap1 = JSON.parse(JSON.stringify(data.map.colorCompreResult1));
							this.test[0] = testMap1;
						}
						if (data.map.colorCompreResult2) {
							var testMap2 = JSON.parse(JSON.stringify(data.map.colorCompreResult2));
							this.test[1] = testMap2;
						}
						this.copydata = JSON.parse(JSON.stringify(this.pro1))
						//this.$forceUpdate();
					}
				});
			},
			//导出比色结果excel
			exportExcelHandle(){
				let standardParam = JSON.stringify(this.pro1[0]);
				let sampleOneParam = JSON.stringify(this.pro1[1]);
				let sampleTwoParam = JSON.stringify(this.pro1[2]);
				var url = `${process.env.VUE_APP_BASE_API}colorcompre/colorcompre/exportExcel?token=${getToken()}`
				 	+`&standardParam=`+encodeURIComponent(standardParam)+`&sampleOneParam=`+encodeURIComponent(sampleOneParam)
				 	+`&sampleTwoParam=`+encodeURIComponent(sampleTwoParam)
				// 模拟创建元素实现
				var alink = document.createElement('a');
				alink.href = url;
				alink.style = 'display:none';
				alink.click();
			},
			initWebSocket() { //初始化weosocket
				//ws地址
				if(this.websock){
					this.websock.close();
				}
				var id = this.$store.getters.userDevice.id;
				var wsuri = process.env.VUE_APP_WS_API + '/websocket/search_product_' + id;
				this.websock = new WebSocket(wsuri);
				this.websock.onmessage = this.websocketonmessage;
				this.websock.onerror = this.websocketonerror
				this.websock.onopen = this.websocketonopen
				// this.websock.onclose = this.websocketclose
			},
			websocketonmessage(e) { //数据接收
				console.log('数据接受');
				//code:0.搜索产品,1.状态变更,2.连接成功
				//msg:
				//data:
				var retData = JSON.parse(e.data);
				var code = retData.code;
				var data = retData.data;
				if (code == 0) {
					this.pro1[this.activeIndex].l1 = data.l1;
					this.pro1[this.activeIndex].a1 = data.a1;
					this.pro1[this.activeIndex].b1 = data.b1;
					this.pro1[this.activeIndex].l2 = data.l2;
					this.pro1[this.activeIndex].a2 = data.a2;
					this.pro1[this.activeIndex].b2 = data.b2;
					this.pro1[this.activeIndex].l3 = data.l3;
					this.pro1[this.activeIndex].a3 = data.a3;
					this.pro1[this.activeIndex].b3 = data.b3;
					//在数据同步后赋值给dataForm时调用接口方法，把数据同步返回的数据给赋值到参数上
					this.getDataLAB(this.activeIndex);
					this.$message.success("同步成功");
					this.clearTimer(); // 关闭定时器
					this.loading = false;
					this.websock.close();
				}else{
					if (this.timerNum == 4) {
						this.pro1[this.activeIndex].l1 = '';
						this.pro1[this.activeIndex].a1 = '';
						this.pro1[this.activeIndex].b1 = '';
						this.pro1[this.activeIndex].l2 = '';
						this.pro1[this.activeIndex].a2 = '';
						this.pro1[this.activeIndex].b2 = '';
						this.pro1[this.activeIndex].l3 = '';
						this.pro1[this.activeIndex].a3 = '';
						this.pro1[this.activeIndex].b3 = '';
						this.pro1[this.activeIndex].picUrl = '';
						this.pro1[this.activeIndex].hex = '';
						this.$message.error("数据同步失败");
						this.clearTimer(); // 关闭定时器
						this.getDataLAB(this.activeIndex);
						this.loading = false;
						this.websock.close();
					}
				}
			},
			websocketonopen() { // 连接建立之后执行send方法发送数据
				// let data = {
				//   code: 0,
				//   msg: '这是client：初次连接'
				// }
				// this.websocketsend(JSON.stringify(data))
			},
			websocketsend(agentData) { //数据发送
				this.websock.send(agentData);
			},
			websocketonerror() {
				return this.$message.error("WebSocket连接失败");
				//console.log( 'WebSocket连接失败')
			},
			websocketclose(e) { //关闭
				this.websock.close();
				// console.log("connection closed (" + e.code + ")");
			}
		},
	};
</script>
<style lang="scss">
.colorcompre {
	.el-button--mini, .el-button--small {
		height:32px;
		border-radius:7px;
	}
	.el-radio {
		margin-right:8px;
	}
	.el-button--primary {
		font-size:14px;
		line-height:14px;
	}
	element.style {
		margin-left: 5px;
	}
	.el-checkbox__inner {
		width:30px;
		height:30px;
		border-radius:30px;
	}
	.inputkuang{
		.el-input__inner{
			border: 2px solid #6F73FF;
			border-radius: 12px !important;
			color:#6F73FF;
			font-size: 14px;
			font-weight: 500;
			text-align:center;
			height:37px;
		}
	}
	.input12{
		.el-input__inner{
			color:#000000;
			font-size: 14px;
			font-weight: 500;
			text-align:center;
			padding:0px 3px;	
		}
		input::-webkit-input-placeholder{
			color:rgba(86, 86, 86, 0.7)!important;
		}
	}
	.el-input__label {
		font-size: 16px;
	}
	.inputkuangtwo{
		.el-input__inner{
			border: 2px solid #B7B7B7;
			border-radius: 9px !important;
			color:#000000;
			font-size: 14px;
			font-weight: 500;
			text-align:center;
			height:37px;
		}
		input::-webkit-input-placeholder{
			color:rgba(86, 86, 86, 0.7)!important;
		}
	}
	.el-checkbox__inner::after {
		height: 18px;
		width:6px;
		border-top-color: initial;
		border-top-style: initial;
		border-top-width: 0px;
		border-right-color: rgb(255, 255, 255);
		border-right-style: solid;
		border-right-width: 3px;
		border-bottom-color: rgb(255, 255, 255);
		border-bottom-style: solid;
		border-bottom-width: 3px;
		border-left-color: initial;
		border-left-style: initial;
		border-left-width: 0px;
		border-image-source: initial;
		border-image-slice: initial;
		border-image-width: initial;
		border-image-outset: initial;
		border-image-repeat: initial;
		position:absolute;
		top:3px;
		left:10px;
	}
	.el-radio__inner{
		width:15px;
		height:15px;
	}
	.el-radio__label {
		font-size:14px;
	}
	.el-radio__input.is-checked .el-radio__inner {
		background: #ffffff;
		font-size:16px;
	}
	.el-radio__inner::after {
		width:8px;
		height:8px;
		background: #6F73FF;
	}
}
</style>
<style lang="scss">
	.colorcompre {
		margin:0 20px;
		.titleone{
			position: relative;
			width:230px;
			height: 75px;
			line-height:75px; 
			display: inline-block;
			text-align: right;
			>img{
				position: absolute;
				left: 0px;
				top:35px;
			}
		}
		.twotitle{
			height: 75px;
			position: relative;
			.daochu{
				display: flex;
				height: 10px;
				align-items: center;
				position: absolute;
				right: 10px;
				top:32px;
				font-size:14px;
				.daochu_{
					position: relative;
					>img{
						position: absolute;
						left: -20px;
						top:0px;
					}
				}	
			}
			.titletwo{
				position: relative;
				width:270px;
				height: 75px;
				line-height:75px; 
				display: inline-block;
				text-align: right;
				>img{
					position: absolute;
					left: 0px;
					top:26px;
				}
			}
		}
		.contentBox {
			border: 2px solid #D2D2D2;
			width: 100%;
			border-radius: 2px;
			.el-col-3 {
				>p {
					margin:0 auto;
					color: #000000;
					font-size: 16px;
					font-weight: 550;
				}

				>span {
					color: #333333;
					font-size: 16px;
					display: block;
					line-height: 60px;
					font-weight: 500;
					text-align: left;
					padding: 0 15px;
				}

				.el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
					width: 30px;
					height: 30px;
					border-radius: 30px;
				}
			}

			.el-col-7 {
				border-left: 2px solid #D2D2D2;
				>div:nth-child(2) {
					margin: 10px;
				}
			}

			.li10 {

				//   padding: 30px 18px 20px;
				p {
					height: 60px;
					border-bottom: 2px solid #D2D2D2;
					font-size: 16px;
					color: #666666;
					line-height: 60px;
					margin: 0;
				}

				>p:nth-last-child(1) {
					border-bottom: 0;
				}
			}

			.tit {
				text-align: right;

				p {
					padding: 0 15px;
					color: #7285e0;
					margin: 0;
				}
			}

			.pic {
				margin:13px auto;
				width: 80%;
				height: 74px;
				border-radius: 12px;
			}

			.itemNameClass {
				text-align: center;
			}
		}
		.contentBoxtwo {
			border: 2px solid #D2D2D2;
			border-bottom:0px;
			width: 100%;
			border-radius: 2px;
			.el-col-3 {
				>p {
					color: #707070;
					font-size: 15px;
				}

				>span {
					color: #333333;
					font-size: 16px;
					display: block;
					line-height: 60px;
					text-align: left;
					padding: 0 15px;
				}
			}

			.el-col-7 {
				border-left: 2px solid #D2D2D2;
			}

			.li10 {

				//   padding: 30px 18px 20px;
				p {
					height: 60px;
					border-bottom: 2px solid #D2D2D2;
					font-size: 16px;
					color: #666666;
					line-height: 60px;
					margin: 0;
				}

				>p:nth-last-child(1) {
					border-bottom: 0;
				}
			}

			.tit {
				text-align: right;

				p {
					padding: 0 15px;
					color: #7285e0;
					margin: 0;
				}
			}

			.itemNameClass {
				text-align: center;
			}
		}
	}
</style>
<style lang="scss" scoped>
	// .w100 {
	//   width: 100px;
	// }
	// .mr10 {
	//   margin-right: 10px;
	// }
	// .ml10 {
	//   margin-left: 10px;
	// }
	// .li10 {
	//   margin-top: 50px;
	//   list-style: none;
	//   /*overflow:hidden;
	//     height:80px;*/
	// }
	// .li11 {
	//   margin-top: 51px;
	//   list-style: none;
	// }
	// .li11:nth-child(3) {
	//   margin-top: 100px;
	// }
	// .li11:nth-child(4) {
	//   margin-top: 130px;
	// }
	.b1 {
		height: 25px;
		//   margin-top: 137px;
		//   margin-left: 20px;
	}

	// .u1 {
	//   margin-top: 110px;
	// }

	.el-input--small .el-input__inner {
		height: 32px;
		line-height: 32px;
		text-align: center;
	}
</style>
