<template>
  <!--<el-dialog
    :title="!dataForm.id ? '新增' : '修改'"
    :close-on-click-modal="false"
    :visible.sync="visible"
    width="750px"
    @close="close()"
  >-->
    <sides-lip ref="SidesLip"
               @click="close()"
    >
        <div slot="soltForm">
    <el-form
      :model="dataForm"
      :rules="dataRule"
      size="small"
      ref="dataForm"
      @keyup.enter.native="dataFormSubmit()"
      label-width="120px"
      v-loading="dataLoading"
      style="width:100%;"
      element-loading-text
      element-loading-spinner="el-icon-loading"
    >
      <el-form-item label="选择产品" prop="productNo">
        <el-input
          v-model="dataForm.productNo"
          placeholder="选择产品"
          size="small"
          @input="getProductInfo"
        ></el-input>
      </el-form-item>
      <!--<div class="productDirs" v-for="x in productInfo">-->
        <div v-show="productInfo!==undefined&&productInfo.length>0" class="productDirs" v-for="(x,index) in productInfo" :key="index">
        <div class="left">
          <p>
            产品名称：
            <span class="fontNormal">{{x.productName}}</span>
          </p>
          <p>
            产品编号：
            <span class="fontNormal">{{x.productNo}}</span>
          </p>
            <p>
                实验订单编号：
                <span class="fontNormal">{{x.productOrder}}</span>
            </p>
          <p>
            L*a*b*：
            <span class="fontNormal">{{x.aAve}} {{x.bAve}} {{x.lAve}}</span>
          </p>
        </div>
        <div class="right">
          <el-image style="width: 180px; height: 100px" :src="x.picUrl" fit="contain"></el-image>
        </div>
      </div>

      <!-- 配方材料集合 -->
      <!-- <el-form-item label="添加材料" prop="selMaterial" class="mt15">
<div  style="display: flex;" >
  <div >
    <el-select
            v-model="dataForm.selMaterial"
            value-key="materialId"
            placeholder="请选择"
            size="small"
            class="mr10"
    >
      <el-option
              v-for="x in materialList"
              :key="x.materialId"
              :label="x.materialName"
              :value="x"
      ></el-option>
    </el-select>
    <el-button size="small" @click="addMaterial">添加</el-button>
  </div>
  <el-input v-model="dataForm.materialNo" placeholder="输入材料编号" size="small" @input="getMaterId()" style="width:40%;margin-left: 10px;"></el-input>

</div> -->
        <!-- </el-form-item> -->
      <!--<div  class="materialInfo">-->
        <div v-show="dataForm.recipeMaterial!==undefined&&dataForm.recipeMaterial.length>0" class="materialInfo">
            <el-table :data="dataForm.recipeMaterial"
                      border
                      v-loading="dataListLoading"
                      style="width: 100%;">
                <el-table-column
                        prop="materialNo"
                        header-align="center"
                        align="center"
                        label="材料编号">
                    <template slot-scope="scope">
                        <el-link type="primary" v-if="scope.row.componentId!=null||scope.row.productId!=null" @click="skipMaterial(scope.row.productId,scope.row.componentId)">{{scope.row.productNo}}{{scope.row.sampleNo}}</el-link>
                        <label v-if="scope.row.materialNo!=null">{{scope.row.materialNo}}</label>
                        <label v-if="scope.row.materialNo==null">{{scope.row.productNo}}</label>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="materialName"
                        header-align="center"
                        align="center"
                        label="原料名称">
                </el-table-column>
                <!-- <el-table-column
                        prop="value"
                        header-align="center"
                        align="center"
                        label="计量值">
                    <template slot-scope="scope">
                        <el-input size="small"
                                  placeholder="请输入"
                                  controls-position="right"
                                  class="inputClass"
                                  style="display: initial;width: 100px;"
                                  v-model="scope.row.value"
                                  maxlength="30"></el-input>
                    </template>
                </el-table-column> -->
                <el-table-column
                                prop="value"
                                header-align="center"
                                align="center"
                                label="计量值">
                </el-table-column>
                <el-table-column
                        prop="materialUnits"
                        header-align="center"
                        align="center"
                        label="单位">
                </el-table-column>
                <el-table-column
                                prop="rate"
                                header-align="center"
                                align="center"
                                label="计量百分比">
                </el-table-column>
                <!-- <el-table-column
                        width="150"
                        fixed="right"
                        header-align="center"
                        align="center"
                        label="操作">
                    <template>
                        <el-button type="text" size="small" @click="del(index)">删除</el-button>
                    </template>
                </el-table-column> -->
            </el-table>
            <br/>
                <div v-if="dataCipeSize != 0">
                    <p>邦定工艺</p>
                    <el-table :data="dataCipeList"
                            border
                            :span-method="objectSpanMethod"
                            style="width: 100%;">
                    <el-table-column
                            prop="addStart"
                            header-align="center"
                            align="center"
                            label="工序">
                    </el-table-column>
                    <el-table-column
                            prop="data"
                            header-align="center"
                            align="center"
                            label="数据">
                    </el-table-column>
                    <el-table-column
                            prop="weight"
                            header-align="center"
                            align="center"
                            label="重量">
                    </el-table-column>
                    </el-table>
                </div>
        <!--<ul>
          <li v-for="(x,index) in dataForm.recipeMaterial">
              <div class="materialName" v-if="x.materialName!==null">材料名称：{{x.materialName}}</div>
              <div class="materialName" v-if="x.materialName==null&&x.productNo==null">材料名称：</div>
              <div class="materialName" v-if="x.productNo!==null" @click="skipMaterial(x.productId)" >材料名称：<span style="color: #3A71A8">{{x.productNo}}</span></div>
            <div>
              <span class="mr10">计量：</span>
              <el-input
                size="small"
                placeholder="请输入"
                class="mr10"
                style="display: initial;"
                v-model="x.value"
              ></el-input>
              <span class="mr50">{{x.materialUnits}}</span>
            </div>
            <div>
              <el-button type="primary" size="small" class="mr10">称重</el-button>
              <el-button size="small" @click="del(index)">删除</el-button>
            </div>
          </li>
        </ul>-->
      </div>
      <br/>
      <el-form-item label="固化条件" prop="solidifyId" class="mt15">
        <el-select v-model="dataForm.solidifyId"  placeholder="请选择" @change="solidifyDD(dataForm.solidifyId)">
          <el-option
            v-for="item in solidifyList"
            :key="item.solidifyId"
            :label="item.solidifyName"
            :value="item.solidifyId"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="固化温度" prop="temperature">
        <el-input v-model="dataForm.temperature" placeholder="固化温度" size="small"></el-input>
      </el-form-item>
      <el-form-item label="固化时间" prop="solTime">
         <el-input v-model="dataForm.solTime" placeholder="固化时间（分钟）" size="small"></el-input>
    <!--</el-date-picker>-->
      </el-form-item>
      <el-form-item label="固化条件说明" prop="solidifyDesc">
        <el-input type="textarea" :rows="5" v-model="dataForm.solidifyDesc"   size="small"></el-input>
      </el-form-item>
    </el-form>
        </div>
        <div slot="soltFooter">
    <span slot="footer" class="dialog-footer">
      <el-button @click="close()" size="small">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()" size="small" :disabled="dataLoading">确定</el-button>
    </span>
      <!--材料跳转-->
      <material-recipe ref="materialRecipe"></material-recipe>
        </div>

  <!--</el-dialog>-->
    </sides-lip>
</template>

<script>
import {
    getMaId,
    update,
    add,
    //recipeMterialInfo,
    //recipeInfo,
  queryMateriaInfo,
  querySolidify,
  queryProduct,
    getSolidifyInFo
} from "@/api/modules/recipe";
import {
    selectRecipe,listAllSCipe
} from "@/api/modules/product";
import MaterialRecipe from './materialRecipe'
import SidesLip from "@/components/SidesLip";
export default {
  data() {
    return {
        materialRecipeVisible: false,
      visible: false,
      dataLoading: false,
      dataForm: {
          materialNo:"",
        id: "",
        productNo: "",
        recipeNo: "",
        productId: "",
        recipeMaterial: [],
        solidifyId: "",
        solTime: "",
        temperature: "",
        solidifyDesc: "",
        selMaterial: {},
          materialId:'',
         // recipeId:''
      },
      productInfo: [],
      materialList: [],
      solidifyList: [],
      dataCipeList:[],
      dataCipeSize: 0,
      cellList: [], // 单元格数组
      count: null, // 计数
      dataRule: {
          productNo: [
              {required: true, message: '产品编号不能为空', trigger: 'blur'}
          ],
          // solidifyId: [
          //     {required: true, message: '固化条件不能为空', trigger: 'blur'}
          // ],
          // recipeMaterial: [
          //     {required: true, message: '配方材料不能为空', trigger: 'blur'}
          // ],
          // temperature: [
          //     {required: true, message: '固化温度不能为空', trigger: 'blur'}
          // ],
          // solTime: [
          //     {required: true, message: '固化时间不能为空', trigger: 'blur'}
          // ],
          // solidifyDesc: [
          //     {required: true, message: '固化条件说明不能为空', trigger: 'blur'}
          // ],
      }
    };
  },
  mounted() {


  },
    components: {
        MaterialRecipe,
        SidesLip
    },
  methods: {
      //点击超链接文字，触发跳转页面
      skipMaterial(productId,componentId){
          this.materialRecipeVisible = true
          this.$nextTick(() => {
              this.$refs.materialRecipe.init(productId,componentId)
          })
      },
      solidifyDD(id){
          var params ={
              id:id
          }
          //固化
          getSolidifyInFo(params).then(({ data }) => {
              if (data.code == 0) {
                  this.dataForm.solidifyName = data.solidifyInFo.solidifyName;
                  this.dataForm.temperature = data.solidifyInFo.temperature;
                  this.dataForm.solTime = data.solidifyInFo.solTime;
                  this.dataForm.solidifyDesc = data.solidifyInFo.description;
              } else {
                  this.$message.error(data.msg);
              }
          });
      },
      //根据编号获取id
      getMaterId(){
          let params = {
              materialNo:this.dataForm.materialNo
          }
          getMaId(params).then(({data}) => {
              if (data && data.code === 0) {
                  if(data.materialInfoVo.materialId){
                      this.dataForm.selMaterial = data.materialInfoVo;
                      this.dataForm.recipeMaterial.push(this.dataForm.selMaterial);
                  }else {
                      this.$message.error("请输入有效材料编号！");
                      return;
                  }

              } else {
                  this.dataForm.selMaterial = {}
              }
          })
      },
      //配方材料
      getQueryMateriaInfo(){
          //材料
          queryMateriaInfo().then(({ data }) => {
              if (data.code === 0) {
                  this.materialList = data.materialList;
              } else {
                  this.$message.error(data.msg);
              }
          });
      },
      //固化
      getQuerySolidify(){
          //固化
          querySolidify().then(({ data }) => {
              if (data.code == 0) {
                  this.solidifyList = data.solidifyList;
              } else {
                  this.$message.error(data.msg);
              }
          });
      },
      close(){
          this.productInfo=[];
          this.materialList = [];
          this.dataForm.materialNo = '';
          this.dataForm.selMaterial = {};
          this.dataForm.recipeMaterial = [];
          this.visible = false;
          this.$refs.SidesLip.sideClose();
      },
    init(id) {
        this.productInfo=[];
        this.materialList = [];
        this.dataForm.materialNo = '';
        this.dataForm.selMaterial = {};
        this.dataForm.recipeMaterial = [];
          this.getQueryMateriaInfo();
          this.getQuerySolidify();
      this.dataForm.id = id || 0;
      this.dataCipeList = [],
      this.dataCipeSize = 0,
      this.cellList = [], // 单元格数组
      this.count = null, // 计数
        this.$refs.SidesLip.init(this.dataForm.id?"修改":"新增", "750px", "block");
      this.visible = true;
      this.$nextTick(() => {
        this.$refs["dataForm"].resetFields();
          this.$refs.dataForm.recipeMaterial =[];
        if (this.dataForm.id) {
            selectRecipe({ id: this.dataForm.id }).then(({ data }) => { //获去配方详情
                if (data && data.code === 0) {
                    this.dataForm.productNo = data.recipeList.productNo;
                    this.dataForm.productName = data.recipeList.productName;
                    this.dataForm.solidifyName = data.recipeList.solidifyName;
                    this.dataForm.solTime = data.recipeList.solTime;
                    this.dataForm.temperature = data.recipeList.temperature;
                    this.dataForm.solidifyDesc = data.recipeList.solidifyDesc;
                    this.dataForm.productId = data.recipeList.productId;
                    if(data.recipeList.recipeMaterial){
                        this.dataForm.recipeMaterial = data.recipeList.recipeMaterial;
                    }

                    this.dataForm.id = data.recipeList.id;
                    var obj={
                        lAve:data.recipeList.lAve,
                        aAve:data.recipeList.aAve,
                        bAve:data.recipeList.bAve,
                        picUrl:data.recipeList.picUrl,
                        productNo:data.recipeList.productNo,
                        productOrder:data.recipeList.productOrder,
                        productName:data.recipeList.productName,

                    }
                    this.productInfo.push(obj);
                    console.log(this.productInfo)
                    this.dataForm.lAve = data.recipeList.lAve;
                    this.dataForm.aAve = data.recipeList.aAve;
                    this.dataForm.bAve = data.recipeList.bAve;
                    this.dataForm.picUrl = data.recipeList.picUrl;
                    this.dataForm.solidifyId = data.recipeList.solidifyId;
                }
            })
            listAllSCipe({ id: this.dataForm.id }).then(({data})=>{
                            if(data && data.code === 0){
                                this.dataCipeList = data.specialrecipeDatas
                                this.dataCipeSize = data.totalCount
                                this.computeCell(this.dataCipeList);
                            }else {
                                this.dataCipeList = []
                                this.dataCipeSize = 0
                            }
                        })
          //   recipeMterialInfo({ id: this.dataForm.id }).then(({ data }) => { //获取配方材料详情
          //   if (data && data.code === 0) {
          //       console.log(data)
          //     this.dataForm.recipeMaterial = data.recipeMaterialInfo;
          //     // this.dataForm.value = data.recipeMterialInfo.value;
          //     // this.dataForm.materialUnits = data.recipeMterialInfo.materialUnits;
          //   }
          // });
        }
      });
    },
    getProductInfo(value) {
      queryProduct({ productNo: value }).then(({ data }) => {
        if (data.code == 0 && data.productList.length > 0) {
          this.dataForm.productId = data.productList[0].productId;
          this.productInfo = data.productList;
        }
      });
    },
    addMaterial() {
      if (JSON.stringify(this.dataForm.selMaterial) == "{}") {
        this.$message.error("请先选择材料！");
        return;
      }
      let obj = {
          materialNo: this.dataForm.selMaterial.materialNo,
        materialName: this.dataForm.selMaterial.materialName,
        value: '',
        materialUnits: this.dataForm.selMaterial.materialUnits
      };
        //判断配方材料添加重复
        let recipeMaterialList = this.dataForm.recipeMaterial.find(v=>{
            return this.dataForm.selMaterial.materialName == v.materialName
        })
        if (recipeMaterialList){
            this.$message.error("配方材料不能重复添加！");
            return;
        }
      this.dataForm.recipeMaterial.push(obj);
    },
    del(index) {
      this.dataForm.recipeMaterial.splice(index, 1);
    },
    // 表单提交
    dataFormSubmit() {
         // console.log(this.dataForm)
      this.$refs["dataForm"].validate(valid => {
        if (valid) {
          this.dataLoading = true;
          var tick = !this.dataForm.id ? add(this.dataForm) : update(this.dataForm);
          tick.then(({ data }) => {
            if (data && data.code === 0) {
              this.$message({
                message: "操作成功",
                type: "success",
                duration: 1500,
                onClose: () => {
                  //this.visible = false;
                  this.dataLoading = false;
                    this.$refs.SidesLip.sideClose();
                  this.$emit("refreshDataList");
                }
              });
            } else {
              this.$message.error(data.msg);
              this.dataLoading = false;
            }
          });
        }
      });
    },
              computeCell(tableBody) {
                // 循环遍历表体数据
                for (let i = 0; i < tableBody.length; i++) {
                if (i == 0) {
                    // 先设置第一项
                    this.cellList.push(1); // 初为1，若下一项和此项相同，就往cellList数组中追加0
                    this.count = 0; // 初始计数为0
                    console.log("索引", 0, this.count);
                } else {
                    // 判断当前项与上项的设备类别是否相同，因为是合并这一列的单元格
                    if (tableBody[i].addStart == tableBody[i - 1].addStart) {
                    // 如果相等
                    this.cellList[this.count] += 1; // 增加计数
                    this.cellList.push(0); // 相等就往cellList数组中追加0
                    console.log("索引", i, this.count);
                    } else {
                    this.cellList.push(1); // 不等就往cellList数组中追加1
                    this.count = i; // 将索引赋值为计数
                    console.log("索引", i, this.count);
                    }
                }
                }
            },
            // 第2步，将计算好的结果返回给el-table，这样的话表格就会根据这个结果做对应合并列渲染
            objectSpanMethod({ row, column, rowIndex, columnIndex }) {
                // 给第二列做单元格合并。0是第一列，1是第二列。
                if (columnIndex === 0) {
                console.log("单元格数组，若下一项为0，则代表合并上一项", this.cellList);
                const rowCell = this.cellList[rowIndex];
                if (rowCell > 0) {
                    const colCell = 1;
                    console.log(`动态竖向合并单元格, 第${colCell}列，竖向合并${rowCell}个单元格 `);
                    return {
                    rowspan: rowCell,
                    colspan: colCell,
                    };
                } else {
                    // 清除原有的单元格，必须要加，否则就会出现单元格会被横着挤到后面了！！！
                    // 本例中数据是写死的不会出现，数据若是动态后端获取的，就会出现了！！！
                    return {
                    rowspan: 0,
                    colspan: 0,
                    };
                }
                }
            },
  }
};
</script>
<style lang="scss">
.materialInfo {
  .el-input {
    input {
      width: 100px;
    }
  }
}
.el-date-editor.el-input{
  width: 100%;
}
</style>
<style lang="scss" scoped>
.productDirs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  color: #606266;
  font-weight: 700;
  padding-bottom: 10px;
  border-bottom: 1px solid #d8d8d8;
  .left {
    margin-left: 100px;
  }
  .right {
    margin-right: 50px;
  }
}
.materialInfo {
  ul {
    margin: 0;
    padding: 10px 20px 0;
    border-bottom: 1px solid #d8d8d8;
  }
  ul li {
    list-style: none;
    margin-left: 30px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .materialName {
      width: 200px;
    }
  }
}
.ml30 {
  margin-left: 30px;
}
.mr50 {
  margin-right: 50px;
}
.mr30 {
  margin-right: 30px;
}
.mr20 {
  margin-right: 20px;
}
.mr10 {
  margin-right: 10px;
}
.mt15 {
  margin-top: 15px;
}
.fontNormal {
  font-weight: normal;
}
</style>
