<template>
  <el-dialog
    :title="!dataForm.productAttrId ? '新增' : '修改'"
    :close-on-click-modal="false"
    class="productattr"
    :visible.sync="visible"
    width="600px">
    <el-form :model="dataForm" :rules="dataRule"  size="small" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="150px"
     v-loading="dataLoading" style="width:100%;"  element-loading-text=""  element-loading-spinner="el-icon-loading"
    >
      <el-form-item label="属性名称" prop="attrName">
        <el-input v-model="dataForm.attrName" placeholder="属性名称"  size="small" style="width: 80%"></el-input>
      </el-form-item>
      <el-form-item label="当前排序" prop="currentSort">
        <el-input v-model="dataForm.currentSort" placeholder="当前排序"  size="small" style="width: 80%"></el-input>
      </el-form-item>
      <el-form-item label="关联品牌" prop="isUniversal">
        <template>
          <el-radio v-model="dataForm.isUniversal" label="0" :disabled="dataForm.productAttrId ? true : false">指定品牌</el-radio>
          <el-radio v-model="dataForm.isUniversal" label="1" :disabled="dataForm.productAttrId ? true : false">通用</el-radio>
        </template>
      </el-form-item>
      <el-form-item label="品牌" prop="brandId" v-if="dataForm.isUniversal == '0'">
        <!--品牌选择标签-->
          <el-select v-model="dataForm.brandId" size="small" placeholder="请选择品牌" style="width: 80%" :disabled="dataForm.productAttrId ? true : false" 
            @change="getproductAttrInfo(dataForm.brandId)">
            <el-option
                    v-for="item in  brandList"
                    :key="item.id"
                    :label="item.brandName"
                    :value="item.id"
                    size="small">
            </el-option>
          </el-select>
      </el-form-item>
      <el-form-item prop="isListShow">
        <span slot="label">
            <span class="span-box" :title="massage.isListShow" style="cursor:pointer">
                <span>列表展示</span>
                <i class="el-icon-question" style="margin-left:5px;"></i>
            </span>
        </span>
        <template>
          <el-radio v-model="dataForm.isListShow" @change="setListShow('0')" label="0">否</el-radio>
          <el-radio v-model="dataForm.isListShow" @change="setListShow('1')" label="1">是</el-radio>
        </template>
      </el-form-item>
      <el-form-item label="" prop="isSupportSearch">
        <span slot="label">
            <span class="span-box" :title="massage.isSupportSearch" style="cursor:pointer">
                <span>支持搜索</span>
                <i class="el-icon-question" style="margin-left:5px;"></i>
            </span>
        </span>
        <template>
          <el-radio v-model="dataForm.isSupportSearch" @change="setSupportSearch('0')" label="0">否</el-radio>
          <el-radio v-model="dataForm.isSupportSearch" @change="setSupportSearch('1')" label="1">是</el-radio>
        </template>
      </el-form-item>
      <el-form-item label="支持小程序搜索" prop="appletShow">
        <span slot="label">
            <span class="span-box" :title="massage.appletShow" style="cursor:pointer">
                <span>支持小程序搜索</span>
                <i class="el-icon-question" style="margin-left:5px;"></i>
            </span>
        </span>
        <template>
          <el-radio v-model="dataForm.appletShow" label="0">否</el-radio>
          <el-radio v-model="dataForm.appletShow" label="1">是</el-radio>
        </template>
      </el-form-item>
      <el-form-item label="级联级别" prop="casLevel">
        <span slot="label">
          <span class="span-box" title="  一级：当前属性的属性值只有其本身一个层级
  二级：当前属性的属性值存在两个层级" style="cursor:pointer">
              <span>级联级别</span>
              <i class="el-icon-question" style="margin-left:5px;"></i>
          </span>
        </span>
        <el-select v-model="dataForm.casLevel" size="small" placeholder="级联级别" style="width: 80%" @change="cahn()">
          <el-option
                  v-for="item in jlList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                  size="small"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="操作类型" prop="operationType">
        <el-select v-model="dataForm.operationType" size="small" placeholder="选择操作类型" style="width: 80%" @change="cahn()">
          <el-option
                  v-for="item in operationTypeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                  size="small"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="关联标准属性" prop="relationAttrId" v-if="dataForm.isUniversal == '0'">
        <el-select v-model="dataForm.relationAttrId" size="small" placeholder="选择关联标准属性" style="width: 80%" @change="cahn()" clearable :disabled="role == '1' ? true : false">
          <el-option
                  v-for="item in relationAttrIdList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                  size="small"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="状态" prop="isEnable">
        <template>
          <el-radio v-model="dataForm.isEnable" @change="setEnable('0')" label="0">禁用</el-radio>
          <el-radio v-model="dataForm.isEnable" @change="setEnable('1')" label="1">启用</el-radio>
        </template>
      </el-form-item>
      <el-form-item label="是否光泽" prop="isGloss" >
        <template>
          <el-radio :disabled="dataForm.productAttrId=='0'?false:true" v-model="dataForm.isGloss" @change="setIsGloss('0')" label="0">否</el-radio>
          <el-radio :disabled="dataForm.productAttrId=='0'?false:true" v-model="dataForm.isGloss" @change="setIsGloss('1')" label="1">是</el-radio>
        </template>
      </el-form-item>
      <el-form-item label="是否计价" prop="isPrice">
        <span slot="label">
            <span class="span-box" :title="massage.isPrice" style="cursor:pointer">
                <span>是否计价</span>
                <i class="el-icon-question" style="margin-left:5px;"></i>
            </span>
        </span>
        <el-select v-model="dataForm.isPrice" size="small" placeholder="是否计价" style="width: 80%" @change="cahn()">
          <el-option
                  v-for="item in ggList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                  size="small"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="是否展示" prop="isShow">
        <span slot="label">
            <span class="span-box" :title="massage.isShow" style="cursor:pointer">
                <span>是否展示</span>
                <i class="el-icon-question" style="margin-left:5px;"></i>
            </span>
        </span>
        <el-select v-model="dataForm.isShow" size="small" placeholder="是否展示" style="width: 80%">
          <el-option
                  v-for="item in testList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                  size="small"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="属性说明" prop="description">
        <div style="margin: 5px 0;"></div>
        <el-input
                type="textarea"
                :rows="4"
                placeholder=""
                v-model="dataForm.description"
                maxlength="150"
                show-word-limit
                style="width: 80%"
        >
        </el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false" size="small">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()" size="small"  :disabled="dataLoading">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import {info,add,update} from '@/api/modules/productattr'
  import {getproductAttrInfo} from '@/api/modules/productattrvalue'
  import {getBrand} from '@/api/modules/product'
  export default {
    data () {
      return {
        visible: false,
        dataLoading: false,
        role:'',
        dataForm: {
          productAttrId: 0,
          attrName: '',
          currentSort: '',
          isUniversal:'1',
          brandId: '',
          casLevel: '',
          isPrice: '',
          isShow: '',
          isDelete: '',
          createBy: '',
          createTime: '',
          updateBy: '',
          updateTime: '',
          version: '',
          isEnable: '0',
          isGloss: '0',
          isListShow: '0',
          isSupportSearch: '0',
          appletShow:'0',
          operationType:'',
          relationAttrId:'',
          description:'',
        },
        massage:{
          isListShow:'若设置“是”，当前属性显示在“产品管理”的产品列表，否则不显示',
          isSupportSearch:'若设置“是”，当前属性作为筛选条件显示在“搜索产品”和“产品管理”功能中，否则不显示',
          appletShow:'若设置“是”，当前属性作为筛选条件显示在【小程序】，否则不显示',
          casLevel:'',
          isPrice:'若设置“是”，当前属性参与【小程序】打样订单产品预估价的计算，否则不参与计算',
          isShow:'若设置“是”，当前属性显示在【小程序】产品详情，否则不显示',
        },
          testList: [
              {
                  value: '0',
                  label: '否'
              },
              {
                  value: '1',
                  label: '是'
              }
              ],
          ggList: [
              {
                  value: '0',
                  label: '否'
              },
              {
                  value: '1',
                  label: '是'
              }
          ],
          jlList: [
              {
                  value: '0',
                  label: '一级'
              },
              {
                  value: '1',
                  label: '二级'
              }
          ],
        operationTypeList:[

          {
            value:'1',
            label:'单值选择框'
          },
          {
            value:'2',
            label:'多值选择框'
          },
          {
            value:'3',
            label:'多级选择框'
          }
        ],
        relationAttrIdList:[],
          brandList: [],
        dataRule: {
          attrName: [
            { required: true, message: '属性名称不能为空', trigger: 'blur' }
          ],
          currentSort: [
            { required: true, message: '当前排序不能为空', trigger: 'blur' }
          ],
          isUniversal: [
            { required: true, message: '不能为空', trigger: 'blur' }
          ],
          brandId: [
            { required: true, message: '品牌不能为空', trigger: 'blur' }
          ],
          isListShow: [
            { required: true, message: '不能为空', trigger: 'blur' }
          ],
          isSupportSearch: [
            { required: true, message: '不能为空', trigger: 'blur' }
          ],
          appletShow: [
            { required: true, message: '不能为空', trigger: 'blur' }
          ],
          casLevel: [
            { required: true, message: '级联级别不能为空', trigger: 'blur' }
          ],
          isPrice: [
            { required: true, message: '是否计价不能为空', trigger: 'blur' }
          ],
          isShow: [
            { required: true, message: '是否展示不能为空', trigger: 'blur' }
          ],
          operationType: [
            { required: true, message: '操作类型不能为空', trigger: 'blur' }
          ],
          isEnable: [
            { required: true, message: '不能为空', trigger: 'blur' }
          ],
          isGloss: [
            { required: true, message: '不能为空', trigger: 'blur' }
          ],
        }
      }
    },
    methods: {

      setListShow(params){
          this.dataForm.isListShow = params
      },
      setSupportSearch(params){
          this.dataForm.isSupportSearch = params
      },
      setEnable(params){
          this.dataForm.isEnable = params
      },
      setIsGloss(params){
          this.dataForm.isGloss = params
      },

        cahn(){
            // console.log(this.dataForm.isPrice)
            // console.log(this.dataForm.isShow)
        },
        //获取品牌列表
        getBrandList() {
            let params = {}
            getBrand(params).then(({data}) => {
                if (data && data.code === 0) {
                    this.brandList = data.list
                } else {
                    this.brandList = []
                }
            })
        },
      init (id,brandId,role) {
        console.log(role)
        console.log(this.dataForm.brandId);
        this.role = role || ''
        this.dataForm.productAttrId = id || 0
        this.getBrandList();
        this.visible = true
        this.$nextTick(() => {
          this.dataForm.relationAttrId = ''
          this.dataForm.operationType = ''
          this.relationAttrIdList = []
          this.$refs['dataForm'].resetFields()
          this.dataForm.brandId = ''
          if (this.dataForm.productAttrId) {
            var params = {
              productAttrId:this.dataForm.productAttrId,
              brandId:brandId ? brandId : ''
            }
            info(params).then(({data}) => {
              if (data && data.code === 0) {
                this.dataForm.attrName = data.productAttr.attrName
                this.dataForm.currentSort = data.productAttr.currentSort
                this.dataForm.isUniversal = data.productAttr.isUniversal
                this.dataForm.brandId = data.productAttr.brandId
                this.getproductAttrInfo(this.dataForm.brandId);
                this.dataForm.casLevel = data.productAttr.casLevel
                this.dataForm.isPrice = data.productAttr.isPrice
                this.dataForm.isShow = data.productAttr.isShow
                this.dataForm.isEnable = data.productAttr.isEnable
                this.dataForm.isListShow = data.productAttr.isListShow
                this.dataForm.isSupportSearch = data.productAttr.isSupportSearch
                this.dataForm.appletShow = data.productAttr.appletShow
                this.dataForm.operationType = data.productAttr.operationType
                this.dataForm.relationAttrId = data.productAttr.relationAttrId
                this.dataForm.description = data.productAttr.description
                this.dataForm.isGloss = data.productAttr.isGloss
              }
            })
          }else{
            this.dataForm.isUniversal = '1'
          }
        })
      },
      getproductAttrInfo(param){
          this.dataForm.relationAttrId = null;
          this.relationAttrIdList = [];
            let params = {
              brandId : param,
              isUniversal:'1'
            };
            if (param) {
              getproductAttrInfo(params).then(({ data }) => {
                  if (data && data.code === 0) {
                      this.relationAttrIdList = data.list;
                  } else {
                      this.relationAttrIdList = [];
                  }
              });
            }
        },
      // 表单提交
      dataFormSubmit () {
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
            this.dataLoading = true;
            var params = {
              'productAttrId': this.dataForm.productAttrId || undefined,
              'attrName': this.dataForm.attrName,
              'currentSort': this.dataForm.currentSort,
              'isUniversal': this.dataForm.isUniversal,
              'brandId': this.dataForm.isUniversal == '0' ? this.dataForm.brandId : '',
              'casLevel': this.dataForm.casLevel,
              'isPrice': this.dataForm.isPrice,
              'isShow': this.dataForm.isShow,
              'isDelete': this.dataForm.isDelete,
              'createBy': this.dataForm.createBy,
              'createTime': this.dataForm.createTime,
              'updateBy': this.dataForm.updateBy,
              'updateTime': this.dataForm.updateTime,
              'version': this.dataForm.version,
              'isEnable': this.dataForm.isEnable,
              'isListShow': this.dataForm.isListShow,
              'isSupportSearch': this.dataForm.isSupportSearch,
              'appletShow': this.dataForm.appletShow,
              'operationType': this.dataForm.operationType,
              'relationAttrId': this.dataForm.isUniversal == '0' ? this.dataForm.relationAttrId : '',
              'isGloss': this.dataForm.isGloss,
              'description': this.dataForm.description,
            }
            var tick = !this.dataForm.productAttrId ? add(params) : update(params)
            tick.then(({data}) => {
              if (data && data.code === 0) {
                this.$message({
                  message: '操作成功',
                  type: 'success',
                  duration: 1500,
                  onClose: () => {
                    this.visible = false;
	              	  this.dataLoading = false;
                    this.$emit('refreshDataList')
                  }
                })
              } else {
                this.$message.error(data.msg)
                this.dataLoading = false;
              }
            })
          }
        })
      }
    }
  }
</script>
<style lang="scss" >
.productattr{
  .el-form-item__label{
    width: 150px !important;
  }
}
</style>
