<template>
    <el-dialog
        title="产品文件上传"
        :close-on-click-modal="false"
        @close="closeHandle"
        style="text-align: center;"
        :visible.sync="visible"
        :append-to-body='true'>
        <el-form  size="small" label-width="120px" v-loading="dataLoading" element-loading-spinner="el-icon-loading">
        <div style="text-align: center;width:100%;margin-bottom:30px;">
            <span style="margin-right:10px;">工厂 </span>
            <el-select v-model="factoryId" size="small" placeholder="">
                <el-option
                    v-for="item in factoryList"
                    :key="item.id"
                    :label="item.factoryName"
                    :value="item.id"
                    size="small"
                ></el-option>
            </el-select>
        </div>
        <el-upload
                drag
                action="13"
                :http-request="uploadExcel"
                :before-upload="beforeUploadHandle"
                multiple
                :file-list="fileList"
                :show-file-list="false"
                style="text-align: center;">
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
            <div class="el-upload__tip" slot="tip">只支持excel！</div>
            <div style="margin-top:20px;color:#606266;" v-if="isshow">{{fileName}}</div>
        </el-upload>
        </el-form>
        <div style="text-align: center;width:100%;margin:30px auto;">文件上传后将在后台进行解析操作，请进入产品导入记录中查看</div>
        <el-button size="small" @click="jumpPage()">产品导入记录</el-button>
    </el-dialog>
</template>

<script>
    import { getFactory} from '@/api/modules/product'
    import {importProductExcel } from '@/api/modules/colorbook'
    export default {
        data() {
            return {
                visible: false,
                successNum: 0,
                isshow:false,
                dataLoading:false,
                fileList: [],
                fileName:'',
                factoryList: [],
                factoryId:'',
            };
        },
        methods: {
            init(id,) {
                this.visible = true;
                this.getFactoryList();
                this.fileName = ''
            },
            getFactoryList() {
                let params = {
                    brandId: sessionStorage.getItem('BrandInfo'),
                };
                getFactory(params).then(({data}) => {
                    if (data && data.code === 0) {
                        this.factoryList = data.list;
                        this.factoryId = data.factoryId;
                    } else {
                        this.factoryList = [];
                    }
                });
            },

            jumpPage(){
                this.visible = false;
                this.$router.push("/productImportRecord");
            },
            // 上传之前
            beforeUploadHandle(file) {
                if(this.factoryId=='' || this.factoryId==null || this.factoryId==undefined){
                    this.$message.error("请先选择工厂!");
                    return;
                }
                this.isshow = false,
                this.fileName = file.name
                const isExcel = file.name.toString().indexOf(".xls") >0 || file.name.toString().indexOf(".xlsx") >0 ;
                if (!isExcel) {
                    this.$message.error("请上传文件格式不正确!");
                }
                return isExcel
            },
            // 弹窗关闭时
            closeHandle() {
                this.fileList = [];
                this.$emit("refreshDataList");
            },
            //upload Excel
            uploadExcel(item){
                if(this.factoryId=='' || this.factoryId==null || this.factoryId==undefined){
                    this.$message.error("请先选择工厂!");
                    return;
                }
                this.dataLoading = true
                let importData = new FormData();
                importData.append("file", item.file);
                importData.append("factoryId", this.factoryId);
                importProductExcel(importData).then(({ data }) => {
                    if (data && data.code === 0) {
                        this.isshow = true,
                        this.$message({
                            message: "数据导入成功",
                            type: "success",
                            duration: 1500,
                        });
                        this.dataLoading = false
                    } else {
                        this.dataLoading = false
                        return this.$alert(data.msg, "", {
                            confirmButtonText: "确定",
                            callback: action => {
                            }
                        });
                    }
                });
            }

        }
    };
</script>
