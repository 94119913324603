<template>
    <el-dialog
            class="proDialog"
            :title="!dataForm.id ? '产品详情' : '产品详情'"
            :close-on-click-modal="false"
            :visible.sync="visible"
            width="800px"
            @click="close()">
        <el-form :model="dataForm" :rules="dataRule" size="small" ref="dataForm" @keyup.enter.native="dataFormSubmit()"
                 label-width="120px"
                 v-loading="dataLoading" style="width:100%;" element-loading-text=""
                 element-loading-spinner="el-icon-loading"
        >
            <div style="display: flex">
                <div style="width: 70%;">
            <el-form-item label="产品编号" prop="productNo">
                <el-input v-model="dataForm.productNo" placeholder="产品编号" size="small" disabled="disabled" style="width: 100%"></el-input>
            </el-form-item>
            <el-form-item label="产品名称" prop="productName">
                <el-input v-model="dataForm.productName" placeholder="产品名称" size="small" disabled="disabled" style="width: 100%"></el-input>
            </el-form-item>
        <el-form-item label="产品LAB值" >
            <div>
<!--                <span class="mr10">当前设备：</span>-->
<!--                <template>-->
<!--                    <el-select v-model="device.deviceId" placeholder="请选择">-->
<!--                        <el-option-->
<!--                                v-for="item in deviceList"-->
<!--                                :key="item.deviceId"-->
<!--                                :label="item.deviceNo"-->
<!--                                :value="item.deviceId">-->
<!--                        </el-option>-->
<!--                    </el-select>-->
<!--                </template>-->
<!--                <span class="mr10">当前设备：{{device.deviceNo}}</span>-->
<!--                &lt;!&ndash;<el-button size="small" type="primary" class="mr10">自动测量LAB值</el-button>&ndash;&gt;-->
<!--                &lt;!&ndash;<el-button size="small" type="primary" class="mr10">CSV读取</el-button>&ndash;&gt;-->
<!--                <el-dropdown @command="handleCommand" trigger="click">-->
<!--                    <el-button size="small" type="primary" class="mr10" @click="queryDevice()">查找授权设备</el-button>-->
<!--                    <el-dropdown-menu slot="dropdown">-->
<!--                        &lt;!&ndash;&ndash;&gt;-->
<!--                        <el-dropdown-item-->
<!--                                v-for="item in deviceList"-->
<!--                                :key="item.deviceId"-->
<!--                                :command="item"-->
<!--                                size="small">{{item.deviceNo}}-->
<!--                        </el-dropdown-item>-->
<!--                    </el-dropdown-menu>-->
<!--                </el-dropdown>-->
                <el-button size="small" type="primary" class="mr10" @click="syncDevice()" :loading="loading">数据同步
                </el-button>
                <!--<el-button size="small" type="primary" class="mr10" @click="isShowLAB()">查看LAB值-->
                <!--</el-button>-->
            </div>
            <el-collapse  @change="handleChange">
                <el-collapse-item >
                    <span class="collapse-title" slot="title">查看测量数据</span>
                <div>
                   <ul class="productLAB">
                       <li>
                           <span v-for="x in productLAB.title" class="title">{{x}}</span>
                       </li>
                       <li v-for="(x,index) in productLAB.data">
                           <span class="title">{{x}}</span>
                           <el-input v-model="dataForm[`l${index+1}`]" placeholder="L值" class="w100" disabled="disabled"></el-input>
                           <el-input v-model="dataForm[`a${index+1}`]" placeholder="A值" class="w100" disabled="disabled"></el-input>
                           <el-input v-model="dataForm[`b${index+1}`]" placeholder="B值" class="w100" disabled="disabled"></el-input>
                       </li>
                   </ul>
                </div>
                </el-collapse-item>
            </el-collapse>
            </el-form-item>
                <el-form-item label="LAB值">
                    <el-input v-model="dataForm.lAve" placeholder="L值" class="w150 mr10" size="small" clearable disabled="disabled"></el-input>
                    <el-input v-model="dataForm.aAve" placeholder="A值" class="w150 mr10" size="small" clearable disabled="disabled"></el-input>
                    <el-input v-model="dataForm.bAve" placeholder="B值" class="w150 mr10" size="small" clearable disabled="disabled"></el-input>
                </el-form-item>
                </div>

                <div style="width: 30%;">
                <el-form-item label="" prop="picUrl"  label-width="0px">
                    <el-upload
                            class="avatar-uploader"
                            :action="action"
                            :show-file-list="false"
                            :on-success="handleUploadSuccess"
                            :before-upload="beforeUpload">
                        <img v-if="dataForm.picUrl" :src="dataForm.picUrl" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon" title="点击上传"></i>
                    </el-upload>
                </el-form-item>
                </div>
            </div>

            <div style="display: flex">
                <div style="width: 70%;">


                    <!--二维码通过后台自动生成存库，前端修改产品编号保存，后台二维码会自动变化，不再前端做新增和编辑-->
                    <el-form-item label="产品标准色号" prop="ralColorNo">
                        <el-input v-model="dataForm.ralColorNo" placeholder="产品标准色号" size="small" disabled="disabled" style="width: 90%"></el-input>
                    </el-form-item>
                </div>


            </div>

            <div style="display: flex">
                <div style="width: 70%;">
            <el-form-item label="光泽测试值" prop="glossValue">
                <el-input v-model="dataForm.glossValue" placeholder="光泽测试值" size="small" @input="getSelectGloss()" disabled="disabled" style="width: 90%"></el-input>
            </el-form-item>
                </div>
                <div style="width: 30%;">
            <el-form-item label="" prop="gloss" label-width="10px">
                <el-select v-model="dataForm.gloss" size="small" placeholder=""
                           style="width: 100%" disabled="disabled">
                    <el-option
                            v-for="item in productGlossList"
                            :key="item.id"
                            :label="item.label"
                            :value="item.id"
                            size="small"
                    ></el-option>
                </el-select>
            </el-form-item>
                </div>
            </div>
            <el-form-item label="参考标准：">
                <el-collapse  @change="handleChange">
                    <el-collapse-item>
                        <span class="collapse-title" slot="title">查看光泽范围</span>
                <div style="display: flex">
                    <div style="width: 65%">
                        <p class="ziti">无光：无光泽范围0~15</p>
                        <p class="ziti">哑光：光泽范围16~25</p>
                        <p class="ziti">弱光：光泽范围26~35</p>
                        <p class="ziti">半哑光：光泽范围36~54</p>
                    </div>
                    <div style="width: 35%">
                        <p class="ziti">半光：光泽范围55~65</p>
                        <p class="ziti">弱高光：光泽范围66~79</p>
                        <p class="ziti">高光：光泽范围80~95</p>
                        <p class="ziti">超高光：光泽范围95+</p>
                    </div>
                </div>
                    </el-collapse-item>
                </el-collapse>
            </el-form-item>
            <div style="display: flex">
            <div style="width: 50%;" >

            <el-form-item label="产品表面" prop="surface">
                <el-select v-model="dataForm.surface" size="small" placeholder="请选择状态"
                           style="width: 60%" disabled="disabled">
                    <el-option
                            v-for="item in productSurfaceList"
                            :key="item.id"
                            :label="item.label"
                            :value="item.id"
                            size="small"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="色系" prop="colorSeries">
                <el-select v-model="dataForm.colorSeries" size="small" placeholder="请选择状态"
                           style="width: 60%" disabled="disabled">
                    <el-option
                            v-for="item in colorSeriesList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                            size="small"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="产品系列" prop="productSeries">
                <el-select v-model="dataForm.productSeries" size="small" placeholder="请选择状态"
                           style="width: 60%" disabled="disabled">
                    <el-option
                            v-for="item in productSeriesList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                            size="small"
                    ></el-option>
                </el-select>
            </el-form-item>
            <!--产品性能选择标签-->
            <el-form-item label="产品性能" prop="productPerformance">
                <el-select v-model="dataForm.productPerformance" size="small" placeholder="请选择状态"
                           style="width: 60%" disabled="disabled">
                    <el-option
                            v-for="item in performanceList"
                            :key="item.id"
                            :label="item.performanceName"
                            :value="item.id"
                            size="small"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="其他性能" prop="otherPerformance">
                <el-select v-model="dataForm.otherPerformance" size="small" placeholder="请选择状态"
                           style="width: 60%" disabled="disabled">
                    <el-option
                            v-for="item in otherPerformanceList"
                            :key="item.id"
                            :label="item.label"
                            :value="item.id"
                            size="small"
                    ></el-option>
                </el-select>
            </el-form-item>
            </div>
            <div style="width: 50%;">
            <el-form-item label="产品分类" prop="productCategory">
                <el-select v-model="dataForm.productCategory" size="small" placeholder="产品分类"
                           style="width: 60%" disabled="disabled">
                    <el-option
                            v-for="item in productCategoryList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                            size="small"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="产品主题" prop="productHierarchy">
                <el-select v-model="dataForm.productHierarchy" size="small" placeholder="请选择状态"
                           style="width: 60%" disabled="disabled">
                    <el-option
                            v-for="item in productHierarchyList"
                            :key="item.hierarchyId"
                            :label="item.hierarchyName"
                            :value="item.hierarchyId"
                            size="small"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="应用行业" prop="industryId">
                <el-select v-model="dataForm.industryId" size="small" placeholder="请选择状态"
                           style="width: 60%" disabled="disabled">
                    <el-option
                            v-for="item in industryList"
                            :key="item.id"
                            :label="item.industryName"
                            :value="item.id"
                            size="small"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="产品工厂" prop="factoryId">
                <el-select v-model="dataForm.factoryId" size="small" placeholder="请选择状态"
                           style="width: 60%" disabled="disabled">
                    <el-option
                            v-for="item in factoryList"
                            :key="item.id"
                            :label="item.factoryName"
                            :value="item.id"
                            size="small"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="产品品牌" prop="brandId">
                <el-select v-model="dataForm.brandId" size="small" placeholder="请选择状态"
                           style="width: 60%" disabled="disabled">
                    <el-option
                            v-for="item in  brandList"
                            :key="item.id"
                            :label="item.brandName"
                            :value="item.id"
                            size="small"
                    ></el-option>
                </el-select>
            </el-form-item>
            </div>
            </div>
            <el-form-item label="产品说明" prop="description">
                <div style="margin: 20px 0;"></div>
                <el-input
                        type="textarea"
                        :rows="5"
                        placeholder="产品说明"
                        v-model="dataForm.description"
                        maxlength="150"
                        show-word-limit
                        disabled="disabled"
                >
                </el-input>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="close()" size="small">返回</el-button>
            <!--<el-button type="primary" @click="dataFormSubmit()" size="small" :disabled="dataLoading">确定</el-button>-->
        </span>
    </el-dialog>
</template>

<script>
    import {
        selectGloss,
        syncDevice,
        syncDeviceData,
        info,
        add,
        update,
        getProductPerformance,
        getIndustryList,
        getDict,
        getColorSeriesList,
        getProductHierarchy,
        getProductSeries,
        getProductCategory,
        getFactory,
        getBrand,
        selectDevice
    } from '@/api/modules/product'
    import Tinymce from '@/components/Tinymce'
    import {getToken} from "@/utils/auth";
    import Cookies from 'js-cookie'

    export default {
        components: {Tinymce},
        data() {
            return {
                showLAB:false,
                loading: false,
                device:{
                    deviceId:''
                },
                action: `${process.env.VUE_APP_BASE_API}/sys/oss/upload?token=${getToken()}`,
                visible: false,
                dataLoading: false,
                timer: null, //首先我在data函数里面进行定义定时器名称：
				timerNum: 5, // 设置定时器时间
                productLAB:{
                    title:["角度","L值","A值","B值"],
                    data:["25度","45度","75度"]
                },
                dataForm: {
                    id: 0,
                    productNo: '',
                    productName: '',
                    picUrl: '',
                    qrUrl: '',
                    ralColorNo: '',
                    gloss: '',
                    glossValue: '',
                    surface: '',
                    colorSeries: '',
                    productSeries: '',
                    productPerformance: '',
                    otherPerformance: '',
                    productCategory: '',
                    productHierarchy: '',
                    industryId: '',
                    factoryId: '',
                    brandId: '',
                    description: '',
                    l1: '',
                    a1: '',
                    b1: '',
                    l2: '',
                    a2: '',
                    b2: '',
                    l3: '',
                    a3: '',
                    b3: '',
                    lAve: '',
                    aAve: '',
                    bAve: '',
                    isOpen: '',
                    delFlag: '',
                    createBy: '',
                    createDate: '',
                    updateBy: '',
                    updateDate: '',
                    remarks: ''
                },
                deviceList:[],
                performanceList: [],
                industryList: [],
                colorSeriesList: [],
                productCategoryList: [],
                otherPerformanceList: [],
                productSeriesList: [],
                productGlossList: [],
                productSurfaceList: [],
                productHierarchyList: [],
                factoryList: [],
                brandList: [],
                dataRule: {
                    /*productNo: [
                        {required: true, message: '产品编号不能为空', trigger: 'blur'}
                    ],
                    productName: [
                        {required: true, message: '产品名称不能为空', trigger: 'blur'}
                    ],
                    picUrl: [
                        {required: true, message: '产品样图不能为空', trigger: 'blur'}
                    ],
                    qrUrl: [
                        {required: true, message: '二维码链接不能为空', trigger: 'blur'}
                    ],
                    ralColorNo: [
                        {required: true, message: 'RAL色号不能为空', trigger: 'blur'}
                    ],
                    gloss: [
                        {required: true, message: '光泽不能为空', trigger: 'blur'}
                    ],
                    glossValue: [
                        {required: true, message: '光泽测试值不能为空', trigger: 'blur'}
                    ],
                    surface: [
                        {required: true, message: '产品表面不能为空', trigger: 'blur'}
                    ],
                    colorSeries: [
                        {required: true, message: '色系不能为空', trigger: 'blur'}
                    ],
                    productSeries: [
                        {required: true, message: '系列不能为空', trigger: 'blur'}
                    ],
                    productPerformance: [
                        {required: true, message: '产品性能不能为空', trigger: 'blur'}
                    ],
                    otherPerformance: [
                        {required: true, message: '其他性能不能为空', trigger: 'blur'}
                    ],
                    productCategory: [
                        {required: true, message: '分类不能为空', trigger: 'blur'}
                    ],
                    productHierarchy: [
                        {required: true, message: '产品主题不能为空', trigger: 'blur'}
                    ],
                    industryId: [
                        {required: true, message: '行业id不能为空', trigger: 'blur'}
                    ],
                    factoryId: [
                        {required: true, message: '工厂id不能为空', trigger: 'blur'}
                    ],
                    brandId: [
                        {required: true, message: '品牌id不能为空', trigger: 'blur'}
                    ],
                    l1: [
                        {required: true, message: '25度L值不能为空', trigger: 'blur'}
                    ],
                    a1: [
                        {required: true, message: '25度A值不能为空', trigger: 'blur'}
                    ],
                    b1: [
                        {required: true, message: '25度B值不能为空', trigger: 'blur'}
                    ],
                    l2: [
                        {required: true, message: '45度L值不能为空', trigger: 'blur'}
                    ],
                    a2: [
                        {required: true, message: '45度A值不能为空', trigger: 'blur'}
                    ],
                    b2: [
                        {required: true, message: '45度B值不能为空', trigger: 'blur'}
                    ],
                    l3: [
                        {required: true, message: '75度L值不能为空', trigger: 'blur'}
                    ],
                    a3: [
                        {required: true, message: '75度A值不能为空', trigger: 'blur'}
                    ],
                    b3: [
                        {required: true, message: '75度L值不能为空', trigger: 'blur'}
                    ],
                    lAve: [
                        {required: true, message: 'L平均值不能为空', trigger: 'blur'}
                    ],
                    aAve: [
                        {required: true, message: 'A平均值不能为空', trigger: 'blur'}
                    ],
                    bAve: [
                        {required: true, message: 'B平均值不能为空', trigger: 'blur'}
                    ],
                    isOpen: [
                        {required: true, message: '是否私有（0.私有，1.共享）不能为空', trigger: 'blur'}
                    ],
                    delFlag: [
                        {required: true, message: '是否删除（0.未删除，1.删除）不能为空', trigger: 'blur'}
                    ]*/
                }
            }
        },
        created() {
        },
        beforeDestroy() {
            clearInterval(this.timer)
            this.timer = null
		},
        methods: {
            handleChange(val) {
                console.log(val);
            },
            //LAB值按钮隐藏显示
            isShowLAB(){
                this.showLAB = !this.showLAB;
            },
            //根据光泽测试值显示光泽范围名称
            getSelectGloss(){
                let params = {
                    glossValue:this.dataForm.glossValue
                }
                selectGloss(params).then(({data}) => {
                    if (data && data.code === 0) {
                        this.dataForm.gloss = data.DictList.dictId;
                    } else {
                        this.dataForm.gloss = ''
                    }
                })
            },
        	//查找设备
            queryDevice(){
                this.getselectDevice();
            },
            //设备同步
            syncDevice(){
                this.loading = true;
                this.device.deviceId = Cookies.get('deviceId')
                let params ={
                    deviceId:this.device.deviceId
                }
                syncDevice(params).then(({data}) => {
                    if (data && data.code === 0) {
                        this.timer = setInterval(() => {  //创建定时器
                            if (this.timerNum === 0) { // 设置的定时器时间为0后执行的操作
                                this.clearTimer(); // 关闭定时器
                                this.loading = false;
                                this.timerNum = 5;
                            } else {
                                //查看设备状态
                                this.syncDeviceTime();
                            }
                        }, 1000);
                    } else {
                        this.$message.error(data.msg);
                        this.loading = false;
                    }
                })
            },

            //获取同步数据
            syncDeviceTime(){
                let params ={
                    deviceId:this.device.deviceId
                }
                syncDeviceData(params).then(({data}) => {
                    if (data && data.code === 0) {
                        //this.dataForm.productNo = data.product.productNo
                        this.dataForm.picUrl = data.product.picUrl
                        this.dataForm.l1 = data.product.l1
                        this.dataForm.a1 = data.product.a1
                        this.dataForm.b1 = data.product.b1
                        this.dataForm.l2 = data.product.l2
                        this.dataForm.a2 = data.product.a2
                        this.dataForm.b2 = data.product.b2
                        this.dataForm.l3 = data.product.l3
                        this.dataForm.a3 = data.product.a3
                        this.dataForm.b3 = data.product.b3
                        this.dataForm.lAve = data.product.lAve
                        this.dataForm.aAve = data.product.aAve
                        this.dataForm.bAve = data.product.bAve
                        this.timerNum = 0;
                    } else {
                        this.timerNum -= 1; // 定时器减1
                    }
                })
            },
			clearTimer() {//清除定时器
				clearInterval(this.timer);
				this.timer = null;
			},
            //选择设备显示在页面
            handleCommand(command) {
              //  console.log(command);
                //this.$message('click on item ' + command);
                this.device = command;
            },
            init(id) {
                //进来就调用获取所有下拉列表
              //  this.
                this.device ={};
                this.getselectDevice();
                this.getProductPerformanceList();
                this.getColorSeries();
                this.getIndustry();
                this.getProductCategoryList();
                this.getDictList();
                this.getProductSeriesList();
                this.getProductSurfaceList();
                this.getProductGlossList();
                this.getProductHierarchyList();
                this.getFactoryList();
                this.getBrandList();
                this.dataForm.id = id || 0
                this.visible = true
                this.$nextTick(() => {
                    //清除缓存数据
                    this.$refs['dataForm'].resetFields();
                     this.dataForm.lAve ='';
                    this.dataForm.aAve ='';
                    this.dataForm.bAve ='';
                    this.dataForm.l1 = '';
                    this.dataForm.a1 = '';
                    this.dataForm.b1 = '';
                    this.dataForm.l2 = '';
                    this.dataForm.a2 = '';
                    this.dataForm.b2 = '';
                    this.dataForm.l3 = '';
                    this.dataForm.a3 = '';
                    this.dataForm.b3 = '';
                    if (this.dataForm.id) {
                        info({id: this.dataForm.id}).then(({data}) => {
                            if (data && data.code === 0) {
                                this.dataForm.productNo = data.product.productNo
                                this.dataForm.productName = data.product.productName
                                this.dataForm.picUrl = data.product.picUrl
                                this.dataForm.qrUrl = data.product.qrUrl
                                this.dataForm.ralColorNo = data.product.ralColorNo
                                this.dataForm.gloss = data.product.gloss
                                this.dataForm.glossValue = data.product.glossValue
                                this.dataForm.surface = data.product.surface
                                this.dataForm.colorSeries = data.product.colorSeries
                                this.dataForm.productSeries = data.product.productSeries
                                this.dataForm.productPerformance = data.product.productPerformance
                                this.dataForm.otherPerformance = data.product.otherPerformance
                                this.dataForm.productCategory = data.product.productCategory
                                this.dataForm.productHierarchy = data.product.productHierarchy
                                this.dataForm.industryId = data.product.industryId
                                this.dataForm.factoryId = data.product.factoryId
                                this.dataForm.brandId = data.product.brandId
                                this.dataForm.description = data.product.description
                                this.dataForm.l1 = data.product.l1
                                this.dataForm.a1 = data.product.a1
                                this.dataForm.b1 = data.product.b1
                                this.dataForm.l2 = data.product.l2
                                this.dataForm.a2 = data.product.a2
                                this.dataForm.b2 = data.product.b2
                                this.dataForm.l3 = data.product.l3
                                this.dataForm.a3 = data.product.a3
                                this.dataForm.b3 = data.product.b3
                                this.dataForm.lAve = data.product.lAve
                                this.dataForm.aAve = data.product.aAve
                                this.dataForm.bAve = data.product.bAve
                            }
                        })
                    }
                })
            },
            close(){
                this.device ={};
                this.visible = false;
                this.performanceList = [];
                // this.dataForm.lAve ='';
                // this.dataForm.aAve ='';
                // this.dataForm.bAve ='';
                this.$refs['dataForm'].resetFields();
            },
            // 表单提交
            dataFormSubmit() {
                this.$refs['dataForm'].validate((valid) => {
                    if (valid) {
                        this.dataLoading = true;
                        var params = {
                            'id': this.dataForm.id || undefined,
                            'productNo': this.dataForm.productNo,
                            'productName': this.dataForm.productName,
                            'picUrl': this.dataForm.picUrl,
                            'qrUrl': this.dataForm.qrUrl,
                            'ralColorNo': this.dataForm.ralColorNo,
                            'gloss': this.dataForm.gloss,
                            'glossValue': this.dataForm.glossValue,
                            'surface': this.dataForm.surface,
                            'colorSeries': this.dataForm.colorSeries,
                            'productSeries': this.dataForm.productSeries,
                            'poductPerformance': this.dataForm.productPerformance,
                            'otherPerformance': this.dataForm.otherPerformance,
                            'productCategory': this.dataForm.productCategory,
                            'productHierarchy': this.dataForm.productHierarchy,
                            'industryId': this.dataForm.industryId,
                            'factoryId': this.dataForm.factoryId,
                            'brandId': this.dataForm.brandId,
                            'description': this.dataForm.description,
                            'l1': this.dataForm.l1,
                            'a1': this.dataForm.a1,
                            'b1': this.dataForm.b1,
                            'l2': this.dataForm.l2,
                            'a2': this.dataForm.a2,
                            'b2': this.dataForm.b2,
                            'l3': this.dataForm.l3,
                            'a3': this.dataForm.a3,
                            'b3': this.dataForm.b3,
                            'lAve': this.dataForm.lAve,
                            'aAve': this.dataForm.aAve,
                            'bAve': this.dataForm.bAve,
                        }
                        var tick = !this.dataForm.id ? add(params) : update(params)
                        tick.then(({data}) => {
                            if (data && data.code === 0) {
                                this.$message({
                                    message: '操作成功',
                                    type: 'success',
                                    duration: 1500,
                                    onClose: () => {
                                       this.device.deviceNo = '';
                                        this.visible = false;
                                        this.dataLoading = false;
                                        this.$emit('refreshDataList')
                                    }
                                })
                            } else {
                                this.device.deviceNo = '';
                                this.$message.error(data.msg)
                                this.dataLoading = false;
                            }
                        })
                    }
                })
            },
            //上传产品图处理
            handleUploadSuccess(res, file, type) {
                this.dataForm.picUrl = res.data.fileUrl;
            },
            //上传前对图片大小及格式进行判断
            beforeUpload(file) {
                const isJPG = (file.type === "image/jpeg" || file.type === "image/bmp" || file.type === "image/png");
                const isLt2M = file.size / 1024 / 1024 < 8;

                if (!isJPG) {
                    this.$message.error("上传图片格式不支持!");
                }
                if (!isLt2M) {
                    this.$message.error("上传图片大小不能超过 8MB!");
                }
                return isJPG && isLt2M;
            },
            //获取已授权设备
            getselectDevice(){
                let params = {}
                selectDevice(params).then(({data}) => {
                    if (data && data.code === 0) {
                        this.deviceList = data.deviceList;
                        this.device.deviceId = data.deviceList.length > 0 ? data.deviceList[0].deviceId : [];
                    } else {
                        this.deviceList = []
                    }
                })
            },
            //获取产品性能选项
            getProductPerformanceList() {
                let params = {}
                getProductPerformance(params).then(({data}) => {
                    if (data && data.code === 0) {
                        this.performanceList = data.performanceList
                    } else {
                        this.performanceList = []
                    }
                })
            },
            //获取行业选择表
            getIndustry() {
                let params = {}
                getIndustryList(params).then(({data}) => {
                    if (data && data.code === 0) {
                        this.industryList = data.list
                    } else {
                        this.industryList = []
                    }
                })
            },
            //获取色系选择表
            getColorSeries() {
                let params = {}
                getColorSeriesList(params).then(({data}) => {
                    if (data && data.code === 0) {
                        this.colorSeriesList = data.list
                    } else {
                        this.colorSeriesList = []
                    }
                })
            },
            //获取产品分类列表
            getProductCategoryList() {
                let params = {}
                getProductCategory(params).then(({data}) => {
                    if (data && data.code === 0) {
                        this.productCategoryList = data.list
                    } else {
                        this.productCategoryList = []
                    }
                })
            },
            //获取其他性能
            getDictList() {
                let params = {
                    type: '1'
                }
                getDict(params).then(({data}) => {
                    if (data && data.code === 0) {
                        this.otherPerformanceList = data.list
                    } else {
                        this.otherPerformanceList = []
                    }
                })
            },
            //获取产品系列表
            getProductSeriesList() {
                let params = {}
                getProductSeries(params).then(({data}) => {
                    if (data && data.code === 0) {
                        this.productSeriesList = data.list
                    } else {
                        this.productSeriesList = []
                    }
                })
            },

            //获取产品表面选项
            getProductSurfaceList() {
                let params = {
                    type: '2'
                }
                getDict(params).then(({data}) => {
                    if (data && data.code === 0) {
                        this.productSurfaceList = data.list
                    } else {
                        this.productSurfaceList = []
                    }
                })
            },
            //获取产品光泽选项
            getProductGlossList() {
                let params = {
                    type: '3'
                }
                getDict(params).then(({data}) => {
                    if (data && data.code === 0) {
                        this.productGlossList = data.list
                    } else {
                        this.productGlossList = []
                    }
                })
            },
            //获取产品体系
            getProductHierarchyList() {
                let params = {}
                getProductHierarchy(params).then(({data}) => {
                    if (data && data.code === 0) {
                        this.productHierarchyList = data.list
                    } else {
                        this.productHierarchyList = []
                    }
                })
            },
            //获取工厂列表
            getFactoryList() {
                let params = {}
                getFactory(params).then(({data}) => {
                    if (data && data.code === 0) {
                        this.factoryList = data.list
                    } else {
                        this.factoryList = []
                    }
                })
            },
            //获取品牌列表
            getBrandList() {
                let params = {}
                getBrand(params).then(({data}) => {
                    if (data && data.code === 0) {
                        this.brandList = data.list
                    } else {
                        this.brandList = []
                    }
                })
            },

        }
    }

</script>
<style lang="scss">
    .proDialog{
        .el-select .el-input .el-select__caret{
            display: none;
        }
    }
 .avatar-uploader {
     .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }
    .el-upload:hover {
        border-color: #409EFF;
    }
 }
</style>
<style lang="scss" scoped>
    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 150px;
        height: 150px;
        line-height: 178px;
        text-align: center;
    }
    .ziti{
        font-size: 12px;
        margin: 0px;
        line-height: 25px;
    }
    .avatar {
        width: 178px;
        max-height: 178px;
        display: block;
    }
    .productLAB{
        margin: 0;
        padding: 0;
        margin-top: 5px;
        //margin-left: -50px;
        li{
            list-style: none;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            margin-bottom: 10px;
            .title{
                display: inline-block;
                width: 100px;
                text-align: center;
                color: #606266;
                font-size: 700;
            }
        }

    }
    .w100{
        width: 100px;
    }
    .w150 {
        width: 125px;
    }
    .mr10{
        margin-right: 10px;
    }
    .collapse-title {
        flex: 1 0 90%;
        color: #4AB7BD;
        font-weight: bolder;
        text-align: center;
    }
</style>
