<template> 
  <div class="app-container">
    <el-form :inline="true" :model="dataForm" ref="dataForm" @keyup.enter.native="restPage()" size="small">
      <el-form-item prop="userName">
        <el-input v-model="dataForm.userName" placeholder="用户名" clearable maxlength="30"></el-input>
      </el-form-item>
      <el-form-item prop="roleId">
        <el-select v-model="dataForm.roleId" size="small" placeholder="请选择角色"
        >
          <el-option
                  v-for="item in  roleList"
                  :key="item.roleId"
                  :label="item.roleName"
                  :value="item.roleId"
                  size="small">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button @click="restPage()">查询</el-button>
        <el-button @click="reset('dataForm')" >清空</el-button>
        <el-button v-if="isAuth('sys:user:save')" type="primary" @click="addOrUpdateHandle()">新增</el-button>
<!--
        <el-button v-if="isAuth('sys:user:delete')" type="danger" @click="deleteHandle()" :disabled="dataListSelections.length <= 0">批量删除</el-button>
-->
      </el-form-item>
    </el-form>
    <el-table
      :data="dataList"
      border
      v-loading="dataListLoading"
      tooltip-effect="dark"
      @selection-change="selectionChangeHandle"
      size="small"
      style="width: 100%;">
      <el-table-column
        type="selection"
        header-align="center"
        align="center"
        width="50">
      </el-table-column>
      <el-table-column
              prop="brandName"
              header-align="center"
              align="center"
              label="所属品牌">
      </el-table-column>
      <el-table-column
              prop="factoryName"
              header-align="center"
              align="center"
              label="所在工厂">
      </el-table-column>
      <el-table-column
        prop="username"
        header-align="center"
        align="center"
        label="用户名">
      </el-table-column>
      <el-table-column
        prop="email"
        header-align="center"
        align="center"
        label="邮箱">
      </el-table-column>
      <el-table-column
        prop="mobile"
        header-align="center"
        align="center"
        label="手机号">
      </el-table-column>
      <el-table-column
              prop="roleName"
              header-align="center"
              align="center"
              label="角色">
      </el-table-column>
      <el-table-column
        prop="isFreeze"
        header-align="center"
        align="center"
        label="状态">
        <template slot-scope="scope">
          {{scope.row.isFreeze == '1'?"禁用":"正常"}}
        </template>
      </el-table-column>
      <el-table-column
        prop="createTime"
        header-align="center"
        align="center"
        width="180"
        label="创建时间">
      </el-table-column>
      <el-table-column
        fixed="right"
        header-align="center"
        align="center"
        width="150"
        label="操作">
        <template slot-scope="scope">
          <el-button v-if="isAuth('sys:user:update')" type="text" size="small" @click="addOrUpdateHandle(scope.row.userId)">修改</el-button>
          <el-button v-if="isAuth('sys:user:delete')" type="text" size="small" @click="deleteHandle(scope.row.userId)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper">
    </el-pagination>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="restPage"></add-or-update>
  </div>
</template>

<script>
import { userList, userDelete, queryRole } from "@/api/sys";
import AddOrUpdate from "./components/user-add-or-update";

export default {
  data() {
    return {
      dataForm: {
        loginname: "",
      },
      dataList: [],
      roleList:[],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      dataListLoading: false,
      dataListSelections: [],
      addOrUpdateVisible: true
    };
  },
  components: {
    AddOrUpdate
  },
  // activated() {
  //   this.getDataList();
  // },
  created() {
    this.getRole();
  },
  methods: {
    //角色名称下拉
    getRole(){
      let params = {
        roleType:1
      }
      queryRole(params).then(({data}) => {
        if (data && data.code === 0) {
          this.roleList = data.RoleList
        } else {
          this.roleList = []
        }
      })
    },
    // 查询清空 修改  by葛海军
    reset(dataForm) {
      this.$refs[dataForm].resetFields();
      this.restPage();
    },
    // 跳到第一页进行查询
    restPage() {
      this.pageIndex = 1;
      this.getDataList();
    },

    // 获取数据列表 查询清空 修改  by葛海军
    getDataList() {
      this.dataListLoading = true;
      var params = {
        page: this.pageIndex,
        limit: this.pageSize,
        username: this.dataForm.userName,
        roleId:this.dataForm.roleId,
        userType: 'manager'
      };
      userList(params).then(({ data }) => {
        if (data && data.code === 0) {
          this.dataList = data.page.list;
          this.totalPage = data.page.totalCount;
        } else {
          this.dataList = [];
          this.totalPage = 0;
        }
        this.dataListLoading = false;
      });
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageIndex = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val;
      this.getDataList();
    },
    // 多选
    selectionChangeHandle(val) {
      this.dataListSelections = val;
    },
    // 新增 / 修改
    addOrUpdateHandle(id) {
      this.addOrUpdateVisible = true;
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(id);
      });
    },
    // 删除
    deleteHandle(id) {
      var userIds = id
        ? [id]
        : this.dataListSelections.map(item => {
            return item.userId;
          });
      this.$confirm(
        `确定进行${id ? "删除" : "批量删除"}操作?`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }
      )
        .then(async () => {
          // const params = {
          //   userIds: userIds
          // };
          const { data } = await userDelete(userIds);
          if (data && data.code === 0) {
            this.$message({
              message: "操作成功",
              type: "success",
              duration: 1500,
              onClose: () => {
                this.restPage();
              }
            });
          } else {
            this.$message.error(data.msg);
          }
          // this.$http({
          //   url: this.$http.adornUrl("/sys/user/delete"),
          //   method: "post",
          //   data: this.$http.adornData(userIds, false)
          // }).then(({ data }) => {
          //   if (data && data.code === 0) {
          //     this.$message({
          //       message: "操作成功",
          //       type: "success",
          //       duration: 1500,
          //       onClose: () => {
          //         this.getDataList();
          //       }
          //     });
          //   } else {
          //     this.$message.error(data.msg);
          //   }
          // });
        })
        .catch(() => {});
    }
  },
  mounted() {
    this.getDataList();
  }
};
</script>
