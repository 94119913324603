<template>
  <el-dialog
    :title="!dataForm.id ? '处理' : '处理'"
    :close-on-click-modal="false"
    :visible.sync="visible">
    <el-form :model="dataForm" :rules="dataRule"  size="small" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="120px"
     v-loading="dataLoading" style="width:100%;"  element-loading-text=""  element-loading-spinner="el-icon-loading"
    >
      <el-form-item label="处理操作" prop="operation">
        <el-radio-group v-model="dataForm.operation">
          <el-radio v-model="dataForm.operation" label="2">完全匹配</el-radio>
          <el-radio v-model="dataForm.operation" label="4">配色可参考</el-radio>
          <el-radio v-model="dataForm.operation" label="3">不匹配</el-radio>
        </el-radio-group>
      </el-form-item>
      <!-- <div class="ces-main">
            <search-form 
              ref="form"
              size='mini'
              labelWidth = '120px'
              :searchData = "searchData"
              :searchForm = "searchForm"
            ></search-form>
      </div> -->
      <!-- <el-form-item prop="productCategory" label="搜索分类">
        <el-select
                v-model="dataForm.productCategory"
                size="small"
                placeholder="请选择分类"
                style="width: 100%"
        >
          <el-option
                  v-for="item in ProductCategoryList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                  size="small"
          ></el-option>
        </el-select>
      </el-form-item> -->

      <!-- <el-form-item prop="colorSeries" v-show="dataForm.productCategory!=this.dataForm.valueId" label="搜索色系">
        <el-select
                v-model="dataForm.colorSeries"
                size="small"
                placeholder="请选择色系"
                style="width: 100%"
        >
          <el-option
                  v-for="item in colorSeriesList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                  size="small"
          ></el-option>
        </el-select>
      </el-form-item> -->

      <el-form-item prop="remarks" label="搜索备注">
        <el-input
                size="small"
                placeholder="请输入搜索备注"
                v-model="dataForm.remarks">
        </el-input>
      </el-form-item>
      <el-form-item prop="sampleOrderNo" label="打样单号">
        <el-input
                size="small"
                placeholder="请输入打样单号"
                v-model="dataForm.sampleOrderNo">
        </el-input>
      </el-form-item>

      <el-form-item v-if="dataForm.operation =='3'" prop="noMatch" label="不匹配理由">
        <el-select
                v-model="dataForm.noMatch"
                size="small"
                placeholder="不匹配理由"
                style="width: 100%"
        >
          <el-option
                  v-for="item in testList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                  size="small"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item prop="reason" label="理由">
        <el-input
                type="textarea"
                :rows="2"
                placeholder="请输入理由"
                v-model="dataForm.reason">
        </el-input>
      </el-form-item>


      <!--<div style="display: flex;margin-top: 15px;">-->
        <!--<div >-->
              <!--<el-form-item prop="productCategory" style="margin-left: 0px">-->
                <!--<el-select-->
                        <!--v-model="dataForm.productCategory"-->
                        <!--size="small"-->
                        <!--placeholder="请选择分类"-->
                        <!--style="width: 100%;margin-left: -80px"-->
                <!--&gt;-->
                  <!--<el-option-->
                          <!--v-for="item in ProductCategoryList"-->
                          <!--:key="item.id"-->
                          <!--:label="item.name"-->
                          <!--:value="item.id"-->
                          <!--size="small"-->
                  <!--&gt;</el-option>-->
                <!--</el-select>-->
              <!--</el-form-item>-->
          <!--请输入打样单号-->
          <!--<el-input-->
                  <!--size="small"-->
                  <!--placeholder="请输入打样单号"-->
                  <!--v-model="dataForm.sampleOrderNo">-->
          <!--</el-input>-->
        <!--</div>-->
        <!--<div>-->
            <!--<el-form-item prop="colorSeries" v-if="dataForm.productCategory!=this.dataForm.valueId">-->
              <!--<el-select-->
                      <!--v-model="dataForm.colorSeries"-->
                      <!--size="small"-->
                      <!--placeholder="请选择色系"-->
                      <!--style="width: 100%"-->
              <!--&gt;-->
                <!--<el-option-->
                        <!--v-for="item in colorSeriesList"-->
                        <!--:key="item.id"-->
                        <!--:label="item.name"-->
                        <!--:value="item.id"-->
                        <!--size="small"-->
                <!--&gt;</el-option>-->
              <!--</el-select>-->
            <!--</el-form-item>-->
        <!--</div>-->
        <!--<div>-->
            <!--<el-form-item v-if="dataForm.operation =='3'">-->
              <!--<el-select-->
                      <!--v-model="dataForm.noMatch"-->
                      <!--size="small"-->
                      <!--placeholder="不匹配理由"-->
                      <!--style="width: 100%"-->
              <!--&gt;-->
                <!--<el-option-->
                        <!--v-for="item in testList"-->
                        <!--:key="item.value"-->
                        <!--:label="item.label"-->
                        <!--:value="item.value"-->
                        <!--size="small"-->
                <!--&gt;</el-option>-->
              <!--</el-select>-->
            <!--</el-form-item>-->
          <!--</div>-->
      <!--</div>-->
      <!--请输入理由-->
      <!--<el-input-->
              <!--type="textarea"-->
              <!--:rows="2"-->
              <!--placeholder="请输入理由"-->
              <!--v-model="dataForm.reason">-->
      <!--</el-input>-->
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false" size="small">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()" size="small"  :disabled="dataLoading">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import {info,add,update,getValueId} from '@/api/modules/searchhistoryList'
  import {getProductAttrValue} from '@/api/modules/productattrvalue';
import SearchForm from '@/components/form.vue';
import {
  getAttrAndValueListInfo
}from '@/api/modules/productattr'
  import ElFormItem from "../../../node_modules/element-ui/packages/form/src/form-item.vue";
  export default {
      components: {ElFormItem,SearchForm},
      data () {
      return {
          searchForm:[ //这里是渲染查询表单的表头和类型的数据
            // {type:'Input',label:'姓名',prop:'name', width:'180px',placeholder:'请输入姓名...'},
            // {type:'Select',label:'性别',prop:'sex',width:'180px',options:sexs,props:sexProps,change:row=>'',placeholder:'请选择性别...'},
          ],
          searchData:{ //查询表单的对应的值
            // name:'',
            // sex:null,
          },
          testList: [
              {
                  value: "目视差异",
                  label: "目视差异",
              },
              {
                  value: "光泽不匹配",
                  label: "光泽不匹配",
              },
              { value: "表面状态不匹配", label: "表面状态不匹配" },
              { value: "系列不匹配", label: "系列不匹配" },
          ],
        visible: false,
        dataLoading: false,
          colorSeriesList:[],
          ProductCategoryList:[],
        dataForm: {
          id: 0,
          productId: '',
          l: '',
          a: '',
          b: '',
          ralColorNo: '',
          gloss: '',
          searchDate: '',
          diviceId: '',
          brandId: '',
          factoryId: '',
          surface: '',
          productNo: '',
          productCategory: '',
          state: '',
          operation: '',
          userId: '',
          searchType: '',
          de: '',
          searchProductNo: '',
            reason:'',
            powderCategory:'',
            noMatch:'',
            valueId:'',
            colorSeries:'',
            sampleOrderNo:'',
            remarks:''
        },
        dataRule: {
            productCategory: [
            { required: true, message: '产品分类不能为空', trigger: 'blur' }
          ],
//            colorSeries: [
//            { required: true, message: '色系不能为空', trigger: 'blur' }
//          ],
            operation: [
            { required: true, message: '处理操作不能为空', trigger: 'change' }
          ],
        }
      }
    },
    methods: {
        //产品属性下拉数据
        getProductAttrValue(){
            let params = {};
            getProductAttrValue(params).then(({data}) => {
                if (data && data.code === 0) {
                    for(var i=0;i<data.list.length;i++){
                         if(data.list[i].productAttrType==='2'){//色系
                            this.colorSeriesList=data.list[i].productAttrValue;
                        }  else if(data.list[i].productAttrType==='5'){//分类
                            this.ProductCategoryList=data.list[i].productAttrValue;
                        }
                    }
                } else {

                }
            })
        },
        //获取金属粉id
        getValueId(){
            let params = {};
            getValueId(params).then(({data}) => {
                if (data && data.code === 0) {
                   this.dataForm.valueId = data.ValueId;
                } else {

                }
            })
        },
      init (id) {
            this.getAttrAndValueListInfo(),
                //this.getValueId(),
        this.dataForm.id = id || 0
        this.visible = true
        this.$nextTick(() => {
          this.$refs['dataForm'].resetFields()
          if (this.dataForm.id) {
            info({id:this.dataForm.id}).then(({data}) => {
              if (data && data.code === 0) {
                this.dataForm.productId = data.searchHistory.productId
                this.dataForm.l = data.searchHistory.l
                this.dataForm.a = data.searchHistory.a
                this.dataForm.b = data.searchHistory.b
                this.dataForm.ralColorNo = data.searchHistory.ralColorNo
                this.dataForm.gloss = data.searchHistory.gloss
                this.dataForm.searchDate = data.searchHistory.searchDate
                this.dataForm.diviceId = data.searchHistory.diviceId
                this.dataForm.brandId = data.searchHistory.brandId
                this.dataForm.factoryId = data.searchHistory.factoryId
                this.dataForm.surface = data.searchHistory.surface
                this.dataForm.productNo = data.searchHistory.productNo
                this.dataForm.productCategory = data.searchHistory.productCategory
                this.dataForm.state = data.searchHistory.state
                  if (data.searchHistory.operation=='1'){
                      this.dataForm.operation =''
                  } else {
                      this.dataForm.operation = data.searchHistory.operation
                  }
                this.dataForm.userId = data.searchHistory.userId
                this.dataForm.searchType = data.searchHistory.searchType
                this.dataForm.de = data.searchHistory.de
                this.dataForm.searchProductNo = data.searchHistory.searchProductNo
                this.dataForm.colorSeries = data.searchHistory.colorSeries
                this.dataForm.reason = data.searchHistory.reason
                this.dataForm.noMatch = data.searchHistory.noMatch
                this.dataForm.sampleOrderNo = data.searchHistory.sampleOrderNo
                this.dataForm.remarks = data.searchHistory.remarks

                // 获取属性
                this.searchData = data.resultMap
              }
            })
          }
        })
      },
          getAttrAndValueListInfo: function () {
            this.searchData = {}
            this.searchForm = []
            this.tableCols = []
            getAttrAndValueListInfo({isUniversal:'0'}).then(({data}) => {
              if (data && data.code === 0) {
                data.data.forEach(e => {
                  var obj = {type:'',label:'', width:'100%'}
                  if(e.type == "1"){
                    // 如果是单选
                    obj.type = "Select"
                    obj.options = e.options
                    obj.prop = e.type+"-"+e.productAttrId
                    obj.label = e.value
                  }
                  if(e.type == "2"){
                    obj.type = "SelectMultiple"
                    obj.options = e.options
                    obj.prop = e.type+"-"+e.productAttrId
                    obj.value = e.value
                  }
                  if(e.type == "3"){
                    obj.type = "Cascader"
                    obj.options = e.options
                    obj.prop = e.type+"-"+e.productAttrId
                    obj.value = e.value
                  }
                  obj.label = e.label
                  this.$set(this.searchData,e.productAttrId,e.value)
                  this.searchForm.push(obj);
                })
              } else {
                this.$message.error(data.msg);
                this.loading2 = false;
              }
            })
          },
      // 表单提交
      dataFormSubmit () {
          /*  console.log("操作结果："+this.dataForm.operation)
          if (this.dataForm.operation==""||this.dataForm.operation==null) {
              this.$message.error("请选择操作处理");
          }*/
          console.log("11")
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
            this.dataLoading = true;
            var params = {
              searchData: this.searchData,
              'id': this.dataForm.id || undefined,
              'operation': this.dataForm.operation,
              // 'productCategory':this.dataForm.productCategory,
              // 'colorSeries':this.dataForm.colorSeries,
              'noMatch':this.dataForm.noMatch,
              'sampleOrderNo':this.dataForm.sampleOrderNo,
              'reason':this.dataForm.reason,
              'remarks':this.dataForm.remarks
            }
            var tick = !this.dataForm.id ? update(params) : update(params)
            tick.then(({data}) => {
              if (data && data.code === 0) {
                this.$message({
                  message: '操作成功',
                  type: 'success',
                  duration: 1500,
                  onClose: () => {
                    this.visible = false;
                    this.dataLoading = false;
                    this.$emit('refreshDataList')
                  }
                })
              } else {
                this.$message.error(data.msg)
                this.dataLoading = false;
              }
            })
          }
        })
      }
    }
  }
</script>
