import request from '@/utils/request'

//产品数据一致性
export function selectUpdateInfoList(params) {
    return request({
        url: '/member/productuploadinfo/selectUpdateInfoList',
        method: 'get',
        params: params
    })
}

export function info(params) {
    return request({
        url: '/member/productuploadinfo/info',
        method: 'get',
        params: params
    })
}

export function update(params) {
    return request({
        url: '/member/productuploadinfo/update',
        method: 'post',
        data: params
    })
}

export function saveOrUpdateProductAndValue(params) {
    return request({
        url: '/member/productupload/saveOrUpdateProductAndValue',
        method: 'post',
        data: params
    })
}